import { Grid } from "@mui/material";
import GlobalText from "../../typography";
import CommonFilters from "../../filters/common-filters";
import { ICommonFilters } from "../../../@types/global";

interface IFilterHeader extends ICommonFilters {
  headingTxt: string;
  subHeadingTxt: string;
}

const FilterHeader = ({
  onDutySpendChange,
  dutySpendFilter,
  handleDateFilter,
  handleFilterReset,
  handleFilterLabel,
  headingTxt,
  subHeadingTxt,
}: IFilterHeader) => {
  return (
    <Grid container spacing={2} alignItems={"center"} mb={3}>
      <Grid item xs={12} sm={6}>
        <GlobalText
          name={headingTxt}
          fontWeight={500}
          fontSize="1.6rem"
          color="#000"
        />
        <GlobalText color={"#84818A"} name={`Last Updated ${subHeadingTxt}`} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonFilters
          onDutySpendChange={onDutySpendChange}
          dutySpendFilter={dutySpendFilter}
          handleDateFilter={handleDateFilter}
          handleFilterReset={handleFilterReset}
          handleFilterLabel={handleFilterLabel}
        />
      </Grid>
    </Grid>
  );
};

export default FilterHeader;
