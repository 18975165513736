import { Card, Typography } from "@mui/material";
import ReactEcharts from "echarts-for-react";

const styles = () => ({
  card: {
    py: 2.5,
    px: 1.5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: { width: "100%", marginTop: 2 },
});
interface IDashboardDonutChart {
  title: string;
}
const DashboardDonutChart = ({ title }: IDashboardDonutChart) => {
  const classes = styles();
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["70%", "82%"],
        data: [{ value: 0, name: "", itemStyle: { color: "#EEF0FA" } }],
      },
      {
        name: "Access From",
        type: "pie",
        radius: ["50%", "70%"],
        data: [
          { value: 1048, name: "MFN Duty", itemStyle: { color: "#0B494B" } },
          { value: 735, name: "MTB Duty", itemStyle: { color: "#97A5EB" } },
          { value: 580, name: "201 Duty", itemStyle: { color: "#FFCC91" } },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 200,
            shadowOffsetX: 0,
            shadowColor: "#000",
          },
        },
      },
      {
        name: "",
        type: "pie",
        radius: ["40%", "55%"],
        data: [{ value: 0, name: "", itemStyle: { color: "#EEF0FA" } }],
        itemStyle: {
          color: "red",
          background: "red",
          backgroundColor: "red",
        },
      },
    ],
  };

  return (
    <Card className="product-donut-chart" sx={classes.card}>
      <Typography
        color={"##4E535A"}
        fontSize={26}
        mb={{ xs: 2, md: 1 }}
        fontWeight={500}
        width={"100%"}
        pl={2}
      >
        {title}
      </Typography>
      <ReactEcharts style={{ width: "100%" }} option={option} />
    </Card>
  );
};

export default DashboardDonutChart;
