import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import DTS from "../assets/images/dts.png";

const styles = () => ({
  card: {
    padding: "20px 20px",
    height: 300,
    display: "flex",
    alignItems: "center",
    marginBottom:"2rem"
  },
  loader: { width: "100%", marginTop: 2 },
});

const ProductHeaderCard = () => {
  const classes = styles();

  return (
    <Card sx={classes.card}>
      <Grid container spacing={5}>
        <Grid item container xs={12} alignItems={"center"}>
          <Grid item xs={6}>
            <Box>
              <Typography color="#4E535A" fontWeight={400} fontSize={16} mb={1}>
                Duty Spend
              </Typography>
              <Typography color="#4E535A" fontWeight={700} fontSize={36}>
                51.8M
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
            <Box component={"img"} src={DTS} alt="" width={"65px"} />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
            <Grid xs={12} md={6} marginBottom={"2rem"}>
            <Box>
              <Typography color="#4E535A" fontWeight={400} fontSize={16} mb={1}>
                Cutoms Value
              </Typography>
              <Typography color="#4E535A" fontWeight={700} fontSize={36}>
                385.40M
              </Typography>
            </Box>                
            </Grid>            
            <Grid xs={12} md={6}>
            <Box borderLeft={{md:"1px solid #0B494B"}} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={{md:"end"}} >
              <Typography color="#4E535A" fontWeight={400} fontSize={16} mb={1}>
                Dutv Saved
              </Typography>
              <Typography color="#4E535A" fontWeight={700} fontSize={36}>
                9.59M
              </Typography>
            </Box>

            </Grid>                        
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductHeaderCard;
