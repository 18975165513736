import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IStripeTable {
  maxHeight?: string | number;
  tableCols: string[];
  TableChildren: any;
  totalRowsData: string[];
}

const StripeTable = ({
  maxHeight = 478,
  tableCols,
  TableChildren,
  totalRowsData,
}: IStripeTable) => {
  return (
    <TableContainer
      component={Paper}
      style={{
        maxHeight,
        borderRadius: 0,
        boxShadow: "none",
      }}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {tableCols?.map((col, ind) => (
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "6px 16px",
                  fontSize: "14px",
                }}
                align={ind === totalRowsData?.length - 1 ? "right" : "left"}
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {TableChildren}

          <StyledTableRow>
            {totalRowsData?.map((row, ind) => (
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "500",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "-1px",
                  zIndex: "2",
                }}
                align={ind === totalRowsData?.length - 1 ? "right" : "left"}
              >
                {row}
              </TableCell>
            ))}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StripeTable;
