import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Paper,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import Pagination from "../../common/Pagination";
import { useTheme, Theme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  UserAddOrUpdateParams,
  UserParams,
  UserTableProps,
} from "../../@types/User";
import { ErrorLoadingType } from "../../utils/Error";
import UpdateUser from "./UpdateUser";
import { useState } from "react";
import UserDelete from "./UserDelete";
import NoDataImage from "../../assets/images/no-data.png";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const styles = (theme: Theme) => ({
  tableCell: {
    color: theme.palette.primary.main,
    fontWeight: "700",
    fontSize: 16,
  },
  tableIconCell: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    width: "20px",
    padding: 0,
  },
  tableRowBg: {
    background: theme.custom.whiteGrey,
    cursor: "pointer",
  },
  tableRow: {
    cursor: "pointer",
  },
});

const UserTable: React.FC<UserTableProps> = ({
  users,
  fetchUsers,
  totalUsers,
  page,
  userPerPage,
}) => {
  const classes = styles(useTheme());

  const [user, setUser] = useState<UserAddOrUpdateParams>({
    id: "",
    username: "",
    password: "",
  });

  const [open, setOpen] = useState(false);

  const [errorLoading, setErrorLoading] = useState<ErrorLoadingType>({
    isError: false,
    isLoading: false,
    errorMsg: null,
  });

  const errorLoadingHandler = ({
    isError,
    isLoading,
    errorMsg,
  }: ErrorLoadingType) => setErrorLoading({ isError, isLoading, errorMsg });

  const handleOpen = (id: string, username: string) => {
    setUser({ id, username, password: "" });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  // const onPageChange = (event: any, newPage: number) => {
  //   fetchUsers(newPage);
  // };

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography textTransform={"capitalize"} fontSize={14}>{row.role}</Typography>
      }
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: ({ row }) => {
        return (
          <Stack direction={"row"} alignItems={"center"} spacing={0}>
            <UpdateUser
              username={row.username}
              password={row.password}
              userId={row.userId}
              fetchUsers={fetchUsers}
              errorLoadingHandler={errorLoadingHandler}
              isLoading={errorLoading.isLoading}
            />
            <UserDelete
              userId={row.userId}
              username={row.username}
              fetchUsers={fetchUsers}
            />
          </Stack>
        );
      },
    },
  ];

  const rows =
    users?.map((row) => {
      return {
        id: row.userId,
        ...row,
      };
    }) ?? [];

  return (
    <>
      {/* {errorLoading.isLoading ? <LinearProgress /> : <></>} */}
      {rows?.length > 0 ? (
        <Box sx={{ height: 111 + 52 * (rows?.length + 1), width: "100%" }}>
          <DataGrid
            rows={rows}
            rowCount={totalUsers}
            loading={errorLoading.isLoading}
            rowsPerPageOptions={[userPerPage]}
            pagination
            page={page}
            pageSize={userPerPage}
            paginationMode="server"
            disableSelectionOnClick
            onPageChange={(newPage) => fetchUsers(newPage)}
            columns={columns}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={NoDataImage} alt="" width="200px" />
                  <Typography
                    color="#4E535A"
                    fontSize={32}
                    fontWeight={500}
                    mt={2}
                  >
                    No Rows Found!
                  </Typography>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={NoDataImage} alt="" width="200px" />
                  <Typography
                    color="#4E535A"
                    fontSize={32}
                    fontWeight={500}
                    mt={2}
                  >
                    No Result Found!
                  </Typography>
                </Stack>
              ),
            }}
            sx={{
              border: "1px solid #F4F4F4",
              borderRadius: "10px 10px 0px 0px",
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              ".MuiDataGrid-columnHeader": {
                background: "rgba(246, 246, 248, 0.4)",
                fontWeight: 500,
                color: "#4E535A",
                fontSize: 14,
                px: 2.5,
              },
              ".MuiDataGrid-cell": {
                borderBottom: "1px solid #F4F4F4",
                fontSize: 14,
                color: "#4E535A",
                fontWeight: 400,
                px: 2.5,
              },
              ".MuiDataGrid-columnHeaders": {
                borderBottom: "1px solid #F4F4F4",
              },
              ".MuiDataGrid-footerContainer": {
                borderTop: "none",
              },
              ".MuiTablePagination-displayedRows": {
                fontSize: 14,
                color: "#4E535A",
                fontWeight: 400,
              },
            }}
          />
        </Box>
      ) : (
        <Stack
          height="100%"
          alignItems="center"
          justifyContent="center"
          sx={{ border: "1px solid #F4F4F4", py: 3, borderRadius: "10px" }}
        >
          <img src={NoDataImage} alt="" width="200px" />
          <Typography color="#4E535A" fontSize={32} fontWeight={500} mt={2}>
            No Rows Found!
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default UserTable;
