import { Grid } from '@mui/material'
import HeaderCard from '../../common/HeaderCard'
import DTV from '../../assets/images/dtv.png';
import DTDP from '../../assets/images/dtdp.png';
import DNOB from '../../assets/images/dnob.png';
import DashboardMainHeaderCard from '../../common/DashboardMainHeaderCard';

const DashboardHeader = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <DashboardMainHeaderCard/>
      </Grid>
      <Grid item container spacing={5} xs={12} md={6}>
        <Grid item xs={12} md={6}>
        <HeaderCard
          title="MFN"
          value={"46500000"}
          isLoading={false}
          icon={DTV}
        />
        </Grid>
        <Grid item xs={12} md={6}>
        <HeaderCard
          title="MTB"
          value={"0.0"}
          isLoading={false}
          icon={DTDP}
        />
        </Grid>
        <Grid item xs={12} md={6}>
        <HeaderCard
          title="301"
          value={"5300000"}
          isLoading={false}
          icon={DNOB}
        />
        </Grid>
        <Grid item xs={12} md={6} mb={{xs: "2.5rem", md: 0}}>
        <HeaderCard
          title="Exculsive"
          value={"567300"}
          isLoading={false}
          icon={DTV}
        />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DashboardHeader