import CustomModal from "../../common/CustomDialog";
import { useTheme, Theme } from "@mui/material/styles";
import {
  Button,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
// import { Edit as EditIcon } from "@mui/icons-material";
import { UpdateUserProps, UserAddOrUpdateParams } from "../../@types/User";
import { useContext, useEffect, useState } from "react";
import { ENDPOINT } from "../../config/config";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import axios from "axios";
import UserContext from "../../context/user/UserContext";
import { UserContextType } from "../../@types/UserContext";
import { Modal } from "../../components";
import EditIcon from "../../assets/images/edit.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const styles = (theme: Theme) => ({
  inputField: {
    margin: "12px 0 12px 0",
  },
  title: { padding: "0" },
});

const UpdateUser: React.FC<UpdateUserProps> = ({
  userId,
  username,
  fetchUsers,
  errorLoadingHandler,
  isLoading,
  password,
}) => {
  const classes = styles(useTheme());
  const userContext = useContext(UserContext) as UserContextType;
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;
  const [openDialog, setOpenDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState<UserAddOrUpdateParams>({
    id: userId,
    username: username,
    password: password,
  });

  const handleClose = () => setOpenDialog(false);
  const handleOpen = () => setOpenDialog(true);

  const isDisable = () => !user.username || !user.password;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const updateHandler = (event: any, label: string) => {
    const value = event.target.value;
    if (label === "username") setUser({ ...user, username: value });
    else setUser({ ...user, password: value });
  };
  const updateUser = async () => {
    try {
      errorLoadingHandler({ isLoading: true, isError: false });
      const userToUpdate: UserAddOrUpdateParams = {};
      if (user.username) userToUpdate.username = user.username;
      if (user.password) userToUpdate.password = user.password;

      errorLoadingHandler({ isLoading: true, isError: false });

      await axios.put(`${ENDPOINT}/users/${user.id}`, userToUpdate, {
        headers: {
          authorization: `Bearer ${userContext.user.token}`,
        },
      });
      errorLoadingHandler({ isLoading: false, isError: false });
      handleClose();
      fetchUsers(0);
    } catch (err: any) {
      errorLoadingHandler({ isLoading: false, isError: true });
      updateSnackBar({
        show: true,
        variant: "error",
        message: err?.response?.data?.error ?? "Error while updating user",
      });
    }
  };

  useEffect(() => {
    if (openDialog) {
      setUser({
        ...user,
        username,
        password,
      });
    }
  }, [openDialog]);

  return (
    <>
      <Tooltip title="Edit User">
        <IconButton onClick={handleOpen} aria-label="edit" size="small">
          <img alt="" width={30} src={EditIcon} />
        </IconButton>
      </Tooltip>
      <Modal
        open={openDialog}
        handleClose={handleClose}
        title="Update User"
        actions={
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <Button
              onClick={handleClose}
              disableElevation
              variant="contained"
              color="error"
              sx={{
                background: "#FF4560",
                color: "#fff",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => updateUser()}
              disabled={isDisable() || isLoading}
              disableElevation
              variant="contained"
              color="success"
              sx={{
                background: "#5EDA81",
                color: "#fff",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
            >
              Update
            </Button>
          </Stack>
        }
      >
        <>
          <Stack sx={{ mt: 1 }} spacing={4}>
            <TextField
              fullWidth
              value={user.username}
              label="Username"
              onChange={(e: any) => updateHandler(e, "username")}
              placeholder="Username"
              required={true}
              type={"text"}
              variant="standard"
              InputLabelProps={{
                shrink: true,
                sx: { color: "#ACACAC", fontWeight: 500, fontSize: 16 },
              }}
              sx={{
                pt: 1,
                "& label.Mui-focused": {
                  color: "#ACACAC",
                },
                "& input::placeholder": {
                  color: "rgba(172, 172, 172)",
                },
              }}
            />
            <TextField
              fullWidth
              value={user.password}
              label="Password"
              required={true}
              onChange={(e: any) => updateHandler(e, "password")}
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { color: "#4E535A" },
              }}
              InputLabelProps={{
                shrink: true,
                sx: { color: "#ACACAC", fontWeight: 500, fontSize: 16 },
              }}
              sx={{
                pt: 1,
                "& label.Mui-focused": {
                  color: "#ACACAC",
                },
                "& input::placeholder": {
                  color: "rgba(172, 172, 172)",
                },
              }}
            />
          </Stack>
        </>
      </Modal>
    </>
  );
};

export default UpdateUser;
