import { Box, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const StripeColorChart = () => {
  const chartData: any = {
    series: [
      {
        data: [100, 90, 80, 70, 60],
      },
    ],
    options: {
        chart: {
            type: "bar",
            height: 350,
            toolbar: {
                show: false,
            },
            id: 'gradient-bar',
            events: {
              // Redraw the bars with correct gradients after initial render
              mounted: (chartContext:any, config:any) => {
                const bars = config.globals.dom.baseEl?.querySelectorAll('.apexcharts-bar-area');
                bars?.forEach((bar:any, index:any) => {
                  const path = bar.querySelector('path');
                  if (path) {
                    // Applying different gradients based on odd/even index
                    const gradientId = index % 2 === 0 ? '#evenGradient' : '#oddGradient';
                    path.setAttribute('fill', `url(${gradientId})`);
                  }
                });
              }
            }

        },
        
        // colors: ["#F8B654", "#46B2F3"],
      plotOptions: {
        bar: {
          borderRadius: 6,
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      colors: ["#46B2F3","#F8B654"],
      legend: { show: true,position:"top", horizontalAlign: 'left'},
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -20,
        position: "bottom",
        style: {
          fontSize: "16px",
          colors: ["#000"],
        },
      },
    xaxis: {
        categories: ["List 30","List 4A"],
            labels: {
          show: false,
        },
                axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },

      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderColor: '#fff',
            borderWidth: 1,
            opacity: 1
          }
        ],
        xaxis: [
          {
            x: 0,
            strokeDashArray: 0,
            borderColor: '#fff',
            borderWidth: 1,
            opacity: 1
          }
        ]
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: undefined, // This can be used to set alternate gradient colors
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [0,100]
        }
      }
},
  };

  return (
    <>
      <Typography
        color={"##4E535A"}
        fontSize={26}
        mb={{ xs: 2, md: 3 }}
        fontWeight={500}
        width={"100%"}
      >
        Opportunities
      </Typography>
      <Box display={"flex"} justifyContent={"space-around"}>
      <Typography
        color={"##4E535A"}
        fontSize={26}
        mb={{ xs: 2, md: 3 }}
        fontWeight={500}
      >
        1114
      </Typography>
      <Typography
        color={"##4E535A"}
        fontSize={26}
        mb={{ xs: 2, md: 3 }}
        fontWeight={500}
      >
        0.00
      </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        color={"##4E535A"}
        fontSize={16}
        mb={{ xs: 2, md: 3 }}
        fontWeight={500}
        pl={2}
      >
        Sec. 301 Litigation Entries Eligible
      </Typography>
      <Typography
        color={"##4E535A"}
        fontSize={16}
        mb={{ xs: 2, md: 3 }}
        fontWeight={500}
      >
        Sec. 301 Entries Avg. Liq. Days
      </Typography>
      </Box>
      <Typography
        color={"##4E535A"}
        fontSize={17}
        fontWeight={600}
        pl={2}
      >
        Sec. 30 List
      </Typography>

        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
    </>
  );
};

export default StripeColorChart;
