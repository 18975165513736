import DashboardIcon from "../../assets/images/dashboard.png";
import ActiveDashboard from "../../assets/images/activeDashboard.svg";
import DashboardIconActive from "../../assets/images/dashboard-white.png";
import TradeDataIcon from "../../assets/images/tradeData.svg";
import TradeDataIconActive from "../../assets/images/trade-data-white.png";
import DocumentLinkIcon from "../../assets/images/document.svg";
import DocumentLinkIconActive from "../../assets/images/document-link-white.png";
import ValueAdjustmentIcon from "../../assets/images/valueAdjustment.svg";
import ValueAdusmtentIconActive from "../../assets/images/vallue-adjustment-white.png";
import UsersIcon from "../../assets/images/users.svg";
import UsersIconActive from "../../assets/images/users-white.png";
import PreImportIcon from "../../assets/images/preImportServices.svg";

export const Items = [
  {
    href: "/",
    icon: DashboardIcon,
    startsWith: "/dashboard",
    activeIcon: ActiveDashboard,
    title: "Dashboard",
    isAdminOnly: false,
    isExpand: true,
    childs: [
      {
        href: "/dashboard/analytics",
        title: "Analytics",
        isChildExpand: false,
      },
      {
        href: "/dashboard/savings",
        title: "Savings",
        isChildExpand: true,
        subChilds: [
          {
            href: "/dashboard/savings/potential",
            title: "Potential",
          },
          {
            href: "/dashboard/savings/historical",
            title: "Historical",
          },
          // {
          //   href: "/dashboard/savings/scenario-modeling",
          //   title: "Scenario Modeling",
          // },
        ],
      },
      {
        href: "/dashboard/compliance",
        title: "Compliance",
        isChildExpand: true,
        subChilds: [
          {
            href: "/dashboard/compliance/risk-trends",
            title: "Risk &	Trends",
          },
          {
            href: "dashboard/compliance/scenario-modeling",
            title: "Scenario Modeling",
          },
        ],
      },
    ],
  },
  {
    href: `/data`,
    icon: TradeDataIcon,
    activeIcon: TradeDataIconActive,
    title: "Trade Data",
    isAdminOnly: false,
    isExpand: false,
  },
  {
    href: `/cbp`,
    icon: DocumentLinkIcon,
    activeIcon: DocumentLinkIconActive,
    title: "Documents",
    isAdminOnly: false,
    isExpand: false,
  },
  {
    href: `/pis`,
    icon: PreImportIcon,
    activeIcon: PreImportIcon,
    title: "Pre Import Services",
    isAdminOnly: false,
    isExpand: false,
  },
  {
    href: `/questionaire`,
    icon: ValueAdjustmentIcon,
    activeIcon: ValueAdusmtentIconActive,
    title: "Value Adjustment",
    isAdminOnly: false,
    isExpand: false,
  },
  {
    href: `/users`,
    icon: UsersIcon,
    activeIcon: UsersIconActive,
    title: "Users",
    isAdminOnly: true,
    isExpand: false,
  },
];
