import { Card, Typography } from "@mui/material";
import InfoIcon from "../../../assets/images/info.svg";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import { abbreviateNumber } from "../../../utils/abbreviateNumber";
import { useEffect, useState } from "react";

const AllDuties = ({ data }: IKeyValue) => {
  const obj =
    data || data?.length
      ? { ...data }
      : {
          duty_sum: 100,
          total_301_duty: 75,
          total_exclusions_saving: 50,
          total_line_tariff_good_value_amount: 25,
          total_mfn_duty: 10,
          total_mtb_duty: 5,
        };
  // delete obj?.total_exclusions_saving;
  delete obj?.total_line_tariff_good_value_amount;
  delete obj?.duty_sum;
  delete obj?.total_duty_saving;
  const total_duties: number | any =
    data || data?.length
      ? Object.values(obj)?.reduce((acc: any, curr: any) => {
          acc = curr + acc;
          return acc;
        }, 0)
      : 0;

  const numbers: number[] = Object.values(obj);
  const totalSum = numbers?.reduce(
    (partialSum: number, a: number) => partialSum + a,
    0
  );
  const [percentages, setPercentages] = useState<IKeyValue>({});

  useEffect(() => {
    const calc_percentages: IKeyValue = Object.keys(obj)?.reduce(
      (acc: IKeyValue, el: string) => {
        acc = { ...acc, [el]: `${Math.round((obj[el] / totalSum) * 100)}%` };
        return acc;
      },
      {}
    );
    setPercentages(calc_percentages);
  }, [data]);

  return (
    <Card sx={{ px: 3, pt: 2, pb: 3.2, height: "358px" }}>
      {data || data?.length ? (
        <>
          <Typography
            color="#8E9494"
            fontSize={"1rem"}
            fontWeight={500}
            style={{ display: "flex", alignItems: "center", gap: 7 }}
          >
            Overall Duties Paid <img src={InfoIcon} alt="InfoIcon" />
          </Typography>
          <Typography
            color="#000"
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"2rem"}
            fontWeight={700}
            mb={1}
          >
            ${abbreviateNumber(total_duties) ?? 0}
          </Typography>
          {/* <Box display="flex" gap={1.5} marginBottom={3}>
        <Typography
          color="#8E9494"
          fontSize={"13px"}
          fontWeight={500}
          sx={{
            background: "#E8F5E9",
            color: "#388E3C",
            padding: "4px 12px",
            borderRadius: "20px",
          }}
        >
          <img src={GraphIcon} alt="GraphIcon" /> 0.31%
        </Typography>
        <Typography color="#8E9494" fontSize={"1rem"}>
          Last Year
        </Typography>
      </Box> */}
          <div className="chart-container" style={{ marginBottom: "1rem" }}>
            <div
              style={{
                width: "100%",
                height: 50,
                display: "flex",
              }}
            >
              <div
                style={{
                  width: percentages?.total_mfn_duty,
                  height: "50%",
                  background: "#0B494B",
                  borderRadius: "4px 0px 0px 4px",
                  padding: "4px 4px",
                }}
              ></div>
              <div
                style={{
                  width: percentages?.total_mtb_duty,
                  height: "50%",
                  padding: "4px 4px",
                  background: "#33DAE0",
                  // background: "#1F6532",
                }}
              ></div>
              <div
                style={{
                  width: percentages?.total_301_duty,
                  height: "50%",
                  padding: "4px 4px",
                  background: "#1CB4BA",
                  // background: "#C6FF29",
                }}
              ></div>
              <div
                style={{
                  width: percentages?.total_line_tariff_good_value_amount,
                  // width: "10%",
                  height: "50%",
                  padding: "4px 4px",

                  background: "#E3E3E8",
                  borderRadius: "0px 4px 4px 0px",
                }}
              ></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", gap: 7, alignItems: "center" }}>
                <div
                  style={{ width: "8px", height: "8px", background: "#0B494B" }}
                ></div>
                <Typography color={"#717184"} fontSize={13}>
                  MFN
                </Typography>
              </div>
              <Typography fontWeight={550} color={"#000"}>
                {abbreviateNumber(data?.total_mfn_duty) ?? 0}
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", gap: 7, alignItems: "center" }}>
                <div
                  style={{ width: "8px", height: "8px", background: "#33DAE0" }}
                ></div>
                <Typography color={"#717184"} fontSize={13}>
                  MTB
                </Typography>
              </div>
              <Typography fontWeight={550} color={"#000"}>
                {abbreviateNumber(data?.total_mtb_duty) ?? 0}
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", gap: 7, alignItems: "center" }}>
                <div
                  style={{ width: "8px", height: "8px", background: "#1CB4BA" }}
                ></div>
                <Typography color={"#717184"} fontSize={13}>
                  301
                </Typography>
              </div>
              <Typography fontWeight={550} color={"#000"}>
                {abbreviateNumber(data?.total_301_duty) ?? 0}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", gap: 7, alignItems: "center" }}>
                <div
                  style={{ width: "8px", height: "8px", background: "#E3E3E8" }}
                ></div>
                <Typography color={"#717184"} fontSize={13}>
                  Others
                </Typography>
              </div>
              <Typography fontWeight={550} color={"#000"}>
                {abbreviateNumber(data?.total_exclusions_saving) ?? 0}
              </Typography>
            </div>
          </div>

          <div
            style={{
              width: "97%",
              height: 75,
              // background: "rgba(212, 242, 220, .8)",
              background: "#E7EDED",
              borderRadius: "6px",
              paddingLeft: "1rem ",
              paddingTop: "10px",
            }}
          >
            <Typography
              color="#8E9494"
              fontSize={14}
              sx={{ fontFamily: "Manrope" }}
            >
              Potential Duty Savings
            </Typography>
            <Typography
              color="#000"
              fontSize={"1.5rem"}
              fontWeight={700}
              fontFamily={"Plus Jakarta Sans"}
            >
              ${abbreviateNumber(~~(total_duties * 0.1)) ?? 0}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <Typography
            color="#8E9494"
            fontSize={"1rem"}
            fontWeight={500}
            style={{ display: "flex", alignItems: "center", gap: 7 }}
          >
            Overall Duties Paid <img src={InfoIcon} alt="InfoIcon" />
          </Typography>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            color="#000"
            fontSize={"1rem"}
            fontWeight={500}
          >
            No data to display
          </Typography>
        </>
      )}
    </Card>
  );
};

export default AllDuties;
