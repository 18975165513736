import { Button, ListItem, Typography, useMediaQuery } from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { ISidebarItem } from "./sidebar";
import { Theme as MuiTheme } from "@mui/material/styles";

export const NavItem = (props: ISidebarItem): JSX.Element => {
  const navigate = useNavigate();
  const { href, icon, activeIcon, title, collapsed } = props;
  const router = useLocation();
  const { id } = useParams();
  const active = href
    ? router.pathname === href || router.pathname === `${href}/${id}`
    : false;

  const lgUp = useMediaQuery((theme: MuiTheme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        margin: 0,
        padding: 0,
        py: 0.8,
      }}
    >
      <Button
        fullWidth
        onClick={() => {
          navigate(href);
        }}
        sx={{
          px: 3,
          fontWeight: 500,
          textTransform: "initial",
          py: 1.5,
          justifyContent: collapsed ? "center" : "flex-start",
          background: (theme) => (active ? "#08383a" : "none"),
        }}
        disabled={active}
      >
        <img
          src={active ? activeIcon : icon}
          alt=""
          width={collapsed ? "25px" : "20px"}
        />
        {!collapsed && (
          <Typography
            color={active ? "#1CB4BA" : "#8E9494"}
            fontWeight={500}
            fontSize={lgUp ? 17 : "14"}
            pl={1.8}
          >
            {title}
          </Typography>
        )}
      </Button>
    </ListItem>
  );
};
