import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import InfoIcon from "../../../assets/images/info.svg";
import { IKeyValue } from "../../../@types/GlobalMessageContext";

function createData(
  ManufacturerDetails: IKeyValue,
  customValue: string,
  customDuty: string,
  customValuePercent: string
) {
  return {
    ManufacturerDetails,
    customValue,
    customDuty,
    customValuePercent,
  };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IVendors {
  data: IKeyValue;
}

const Vendors = () => {
  const rows = [
    createData(
      { mainText: "CRS Denim Garments Eg..", subText: "EGCRSDENPOR" },
      "34,679,033.00",
      "81,865.92",
      "9.00%"
    ),
    createData(
      { mainText: "Suzhou Flying Fashions Co", subText: "CNSUZFLY283SUZ" },
      "9,863,385.00",
      "1,891, 746.30",
      "2.56%"
    ),
    createData(
      { mainText: "CRS Denim Garments Eg..", subText: "EGCRSDENPOR" },
      "34,679,033.00",
      "81,865.92",
      "9.00%"
    ),
    createData(
      { mainText: "CRS Denim Garments Eg..", subText: "EGCRSDENPOR" },
      "34,679,033.00",
      "81,865.92",
      "9.00%"
    ),
    createData(
      { mainText: "Suzhou Flying Fashions Co", subText: "CNSUZFLY283SUZ" },
      "9,863,385.00",
      "1,891, 746.30",
      "2.56%"
    ),
  ];

  return (
    <Card>
      <Typography
        color="#000"
        fontSize={"1.2rem"}
        fontWeight={500}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 7,
          padding: "1.2rem",
        }}
      >
        Vendors <img src={InfoIcon} alt="InfoIcon" />
      </Typography>

      <TableContainer
        className="product-table"
        component={Paper}
        style={{
          maxHeight: 440,
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "6px 16px",
                  fontSize: "14px",
                }}
              >
                Manufacturer Details
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "6px 16px",
                  fontSize: "14px",
                }}
                align="right"
              >
                Custom Value
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "6px 16px",
                  fontSize: "14px",
                }}
                align="right"
              >
                Custom Duty
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "6px 16px",
                  fontSize: "14px",
                }}
                align="right"
              >
                % Custom Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: IKeyValue) => (
              <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell component="th" scope="row">
                  <Typography fontWeight={500} color="#000" mb={0} pb={0}>
                    {row.ManufacturerDetails.mainText}
                  </Typography>
                  <span
                    style={{
                      color: "#8E9494",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {row.ManufacturerDetails.subText}
                  </span>
                </TableCell>
                <TableCell
                  style={{ fontWeight: 500, color: "#000" }}
                  align="right"
                >
                  {row.customValue}
                </TableCell>
                <TableCell
                  style={{ fontWeight: 500, color: "#000" }}
                  align="right"
                >
                  {row.customDuty}
                </TableCell>
                <TableCell
                  style={{ fontWeight: 500, color: "#000" }}
                  align="right"
                >
                  {row.customValuePercent}
                </TableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "500",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "-1px",
                  zIndex: "2",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "-1px",
                  zIndex: "2",
                }}
                align="right"
              >
                385,401,272.00{" "}
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "-1px",
                  zIndex: "2",
                }}
                align="right"
              >
                51,774,674.82{" "}
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "-1px",
                  zIndex: "2",
                }}
                align="right"
              >
                100.00%{" "}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Vendors;
