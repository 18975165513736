import { Typography } from "@mui/material";
import { IKeyValue } from "../../@types/GlobalMessageContext";

interface IGlobalText {
  name: string;
  fontWeight?: number;
  color: string;
  fontSize?: string | number;
  width?: string;
  style?: IKeyValue;
  onClick?: () => void;
  isImg?: boolean;
  imgSrc?: string;
}

const GlobalText = ({
  name,
  fontWeight,
  color,
  fontSize,
  width,
  onClick,
  style,
  isImg,
  imgSrc,
}: IGlobalText) => {
  return (
    <Typography
      fontWeight={fontWeight}
      color={color}
      fontSize={fontSize}
      width={width}
      onClick={onClick}
      sx={style}
    >
      {name} {isImg && <img src={imgSrc} alt="image" />}
    </Typography>
  );
};

export default GlobalText;
