import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Navbar } from "./navbar";
import { Sidebar } from "./sidebar";
import GlobalSnackBarMessage from "../../common/GlobalSnackBarMessage";
import { Stack, useMediaQuery } from "@mui/material";

const LoggedinLayout = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const theme = useTheme();
  const { breakpoints } = theme;
  const IsLgUp = useMediaQuery(breakpoints.up("lg"));
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isPermanentSidebarOpen, setPermanentSidebarOpen] = useState(true);

  return (
    <div style={{background:"#f8f8f8"}}>
      <GlobalSnackBarMessage />
      <Stack
        sx={{
          display: "flex",
          flex: "1 1 auto",
          paddingTop: IsLgUp ? "96px" : "76px",
          paddingLeft: isPermanentSidebarOpen
            ? IsLgUp
              ? "240px"
              : "0px"
            : "100px",
          transition: "0.3s",
        }}
      >
        <Stack
          sx={{
            py: 1,
            px: {
              md: 5,
              xs: 2,
            },
          }}
        >
          {children}
        </Stack>
      </Stack>
      <Navbar
        onSidebarOpen={() => {
          setSidebarOpen(true);
        }}
        isPermanentSidebarOpen={isPermanentSidebarOpen}
      />
      <Sidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
        isPermanentSidebarOpen={isPermanentSidebarOpen}
        onTogglePermanentSidebar={() =>
          setPermanentSidebarOpen(!isPermanentSidebarOpen)
        }
      />
    </div>
  );
};

export default LoggedinLayout;
