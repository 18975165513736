import {
  Grid,
  Typography,
  TextField,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  Stack,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { ENDPOINT } from "../../config/config";
import { UserContextType } from "../../@types/UserContext";
import UserContext from "../../context/user/UserContext";
import { AnswerType } from "./Questionnaire";

const styles = () => ({
  tableCell: {
    borderBottom: "none",
  },
});

const Question5 = ({
  answers,
  updateAnswers,
}: {
  answers: AnswerType[];
  updateAnswers: any;
}) => {
  const classes = styles();
  const [values, setValues] = useState<any>(["", 0, ""]);
  const [currency, setCurrency] = useState("USD");

  const userContext = useContext(UserContext) as UserContextType;

  const getPercentage = (val1: number, val2: number) => {
    if (!val1 || !val2) return 0;
    const percent = (val1 / val2) * 100;
    return percent;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const temp = [...values];
    temp[0] = event.target.value;
    if (temp[1]) temp[2] = getPercentage(temp[0], temp[1]);

    updateAnswers({ key: "q5", value: `${temp[2]}%` });
    temp[2] = `${temp[2].toFixed(2)}%`;
    setValues(temp);
  };

  const updateValues = (totalTariff: number) => {
    const temp = [...values];
    temp[1] = totalTariff;
    if (temp[0] && temp[1]) temp[2] = getPercentage(temp[0], temp[1]);

    setValues(temp);
    updateAnswers({ key: "q5", value: temp[2] });
  };
  const getFlaggedTransactionsValue = async (
    selectedCurrency: string = "USD"
  ) => {
    try {
      const answer3 = answers.find((answer) => answer.key === "q3");
      if (!answer3) return;
      const dateFrom = answer3.value; // DateHelpers.formatDate(answer3.value)
      const dateTo = answer3.value2; //DateHelpers.formatDate(answer3.value2)

      let query: string = `reconciliationIndicator=${"Y"}&dateFrom=${dateFrom}&dateTo=${dateTo}&currency=${selectedCurrency}`;

      const resp = await axios.get(`${ENDPOINT}/total-tariff-amount?${query}`, {
        headers: {
          authorization: `Bearer ${userContext.user.token}`,
        },
      });
      updateValues(resp?.data?.totalAmount ?? 0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFlaggedTransactionsValue();
  }, []);

  const data = [
    {
      name: "Transfer Price Adjustment Figure",
      id: "val1",
      func: handleChange,
      type: "number",
      disabled: false,
    },
    {
      name: "Entered Value of Flagged Transactions",
      id: "val2",
      func: () => {},
      type: "number",
      disabled: true,
    },
    {
      name: "Adjustment Factor",
      id: "val3",
      func: () => {},
      type: "text",
      disabled: true,
    },
  ];

  const handleCurrencyChange = (event: any) => {
    setCurrency(event.target.value);
    getFlaggedTransactionsValue(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.05)",
          width: "80%",
        }}
      >
        {data?.map((row, idx) => {
          return (
            <Stack
              direction="row"
              key={row.id}
              sx={{
                borderBottom:
                  idx === data?.length - 1 ? "none" : "1px solid #D9D9D9",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={14} color="#4E535A" fontWeight={500}>
                  {row.name}
                </Typography>
              </Box>
              <Box sx={{ background: "#D9D9D9", width: "1px" }}></Box>
              <Box
                sx={{
                  width: "50%",
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {row?.disabled ? (
                  <Typography fontSize={14} color="#4E535A" fontWeight={500}>
                    {row.id === "val2"
                      ? `${currency} ${values[idx]?.toFixed(2) || 0}`
                      : values[idx] || 0}
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    value={values[idx]}
                    label=""
                    onChange={row.func ?? (() => {})}
                    placeholder="0"
                    type={row.type}
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ width: "100px" }}
                        >
                          {/* Adjust width as needed */}
                          <Select
                            value={currency}
                            onChange={handleCurrencyChange}
                            variant="standard"
                            disableUnderline
                            sx={{
                              color: "rgba(172, 172, 172)",
                              ".MuiSelect-icon": {
                                color: "rgba(172, 172, 172)",
                              },
                            }}
                          >
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="MXN">MXN</MenuItem>
                            <MenuItem value="CAD">CAD</MenuItem>
                            <MenuItem value="GBP">GBP</MenuItem>
                            <MenuItem value="EUR">EUR</MenuItem>
                            <MenuItem value="CNY">CNY</MenuItem>
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#ACACAC",
                      },
                      "& input::placeholder": {
                        color: "rgba(172, 172, 172)",
                      },
                    }}
                  />
                )}
              </Box>
            </Stack>
          );
        })}
      </Box>
    </>
  );
};

export default Question5;
