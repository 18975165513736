import { Card } from "@mui/material";
import { IKeyValue } from "../../@types/GlobalMessageContext";
import { ReactNode } from "react";

interface IGlobalCard {
  style?: IKeyValue;
  children?: ReactNode;
}

const GlobalCard = ({ style, children }: IGlobalCard) => {
  return <Card sx={style}>{children}</Card>;
};

export default GlobalCard;
