import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import ReactApexChart from "react-apexcharts";
import { abbreviateNumber } from "../../../utils/abbreviateNumber";
import { useCallback } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

interface ITariffCodes {
  data: any;
  setPage: (value: number | any) => void;
  loading: boolean;
}

function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

const SavingPotential = ({ data, setPage, loading }: ITariffCodes) => {
  const options: any = {
    series: [
      {
        name: "Saving Potential",
        data: data?.map((el: any) => Math.round(el.potential_saving)),
      },
    ],
    options: {
      colors: ["#004c4c"],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          barHeight: "50%",
          distributed: true, // Allows different colors for each bar
        },
      },
      xaxis: {
        categories: data?.map((el: any) => String(el.hts_number)),
        labels: {
          style: {
            fontSize: "14px",
            colors: "#8E9494",
            fontFamily: "Inter",
          },
          formatter: function (val: number) {
            return `${abbreviateNumber(val.toFixed(2))}`;
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            colors: "#8E9494",
            fontFamily: "Inter",
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: number) {
          return `${abbreviateNumber(val.toFixed(2))}`; // Display in 'K' format
        },
        style: {
          colors: ["#FFF"],
        },
        offsetX: 5,
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return `${abbreviateNumber(val.toFixed(2))}`; // Display in 'K' format
          },
        },
      },
      grid: {
        borderColor: "#e7e7e7",
        strokeDashArray: 4,
      },
    },
  };

  // const handleScroll = useCallback(
  //   debounce((event: React.UIEvent<HTMLDivElement>) => {
  //     const target = event.target as HTMLDivElement;
  //     const bottom =
  //       target.scrollHeight === target.scrollTop + target.clientHeight ||
  //       target.scrollHeight - ~~(target.scrollTop + target.clientHeight) === 1;
  //     if (bottom) {
  //       setPage((prevPage: number) => prevPage + 1);
  //     }
  //   }, 1000),
  //   []
  // );

  return (
    <Card
      component={"div"}
      // onScroll={handleScroll}
      sx={{ px: 3, pt: 4, pb: 4, overflow: "scroll !important", height: 400 }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : !data || !data.length ? (
        <>
          <Typography color="#000" fontSize={"1.2rem"} fontWeight={500}>
            Saving Potential Per Classification
          </Typography>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            color="#000"
            fontSize={"1rem"}
            fontWeight={500}
          >
            No data to display
          </Typography>
        </>
      ) : (
        <>
          <Typography color="#000" fontSize={"1.2rem"} fontWeight={500}>
            Saving Potential Per Classification
            <span>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  float: "right !important",
                }}
              >
                <Tooltip title="Next Page">
                  <IconButton>
                    <FaArrowDown
                      onClick={() =>
                        setPage((prevPage: number) => prevPage + 1)
                      }
                      size="20"
                      color="#0B494B"
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Prev Page">
                  <IconButton
                    onClick={() => {
                      setPage((prevPage: number) => Math.max(prevPage - 1, 1));
                    }}
                  >
                    <FaArrowUp size="20" color="#0B494B" />
                  </IconButton>
                </Tooltip>
              </Box>
            </span>
          </Typography>

          <ReactApexChart
            options={options.options}
            series={options.series}
            type="bar"
            height={400}
          />
        </>
      )}
    </Card>
  );
};

export default SavingPotential;
