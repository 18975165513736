import  { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { IoIosArrowDown } from "react-icons/io";
import {  FormControl, InputLabel } from "@mui/material";
import { Control, Controller } from "react-hook-form";

interface IAppDateRangePicker {
  label: string;
  placeholder: string;
  name: string;
  control: Control<any>;
}

const AppDateRangePicker = ({
  label,
  name,
  control,
  placeholder,
}: IAppDateRangePicker) => {
  const [focusedInput, setFocusedInput] = useState(null);

  // const renderInput = ({ onFocus, ...props }:any, openCalendar:any, { focused }:any) => {
  //     const handleFocus = () => {
  //         onFocus();  // Call the original onFocus handler
  //         openCalendar();
  //     };

  //     return (
  //         <div style={{ display: 'flex', alignItems: 'center', border: focused ? '1px solid blue' : 'none' }}>
  //             <input
  //                 {...props}
  //                 onFocus={handleFocus}
  //                 readOnly  // Makes the input itself non-editable
  //                 placeholder="Select date range"  // Unified placeholder text
  //                 style={{ width: '100%' }}
  //             />
  //             <IoIosArrowDown
  //                 onClick={openCalendar}
  //                 style={{ paddingRight: "10px", fontSize: "1.7rem", color: "#4E535A", opacity: 0.5, cursor: 'pointer', marginLeft: 8 }}
  //             />
  //         </div>
  //     );
  // };

  return (
    <>
      <InputLabel htmlFor="hey" sx={{ mb: 1 }}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl sx={{ width: { xs: "100%", md: 300 } }}>
            <DateRangePicker
              startDate={value?.startDate} // momentPropTypes.momentObj or null,
              startDateId="start_date_id" // PropTypes.string.isRequired,
              endDate={value?.endDate} // momentPropTypes.momentObj or null,
              startDatePlaceholderText={placeholder}
              endDatePlaceholderText=""
              endDateId="end_date_id" // PropTypes.string.isRequired,
              // onDatesChange={({ startDate, endDate }: any) => {
              //   setStartDate(startDate);
              //   setEndDate(endDate);
              // }}
              onDatesChange={({ startDate, endDate }:any) => onChange({ startDate, endDate })}
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={setFocusedInput} // PropTypes.func.isRequired,
              numberOfMonths={1}
              isOutsideRange={() => false}
              customInputIcon={<IoIosArrowDown />}
              inputIconPosition="after"
              // renderInput={renderInput} // Use the custom input component
            />
          </FormControl>
        )}
      />
    </>
  );
};

export default AppDateRangePicker;
