import { Tab, TableCell, TableRow, Tabs, styled } from "@mui/material";
import { useState } from "react";
import {
  VendorsDataType,
  VendorsType,
} from "../../../@types/PotentialDashboard";
import InfoIcon from "../../../assets/images/info.svg";
import GlobalCard from "../../../components/card";
import StripeTable from "../../../components/tables/stripe-table";
import GlobalText from "../../../components/typography";
import { numberWithCommas } from "../../../utils/abbreviateNumber";

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps & { active: boolean }) => (
  <Tab disableRipple {...props} />
))(({ theme, active }) => ({
  textTransform: "none",
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  background: active ? "#0B494B" : "#fff",
  borderRadius: "32px",
  border: "1px solid #E6E6E8",
  color: active ? "#fff !important" : "#000 !important",
}));

const colsData = [
  "Manufacturer Details",
  "Custom Value",
  "Custom Duty",
  "% Custom Value",
];
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableChildren = ({ vendorsRows }: { vendorsRows?: VendorsType[] }) => {
  const data = [
    {
      ManufacturerDetails: {
        mainText: "CRS Denim Garments Eg..",
        subText: "ILSTRLTD2REH",
      },
      customValue: 34679033.0,
      customDuty: 81865.92,
      customValuePercent: "9.00%",
    },
  ];

  return (
    <>
      {vendorsRows?.map((row) => (
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            <GlobalText
              name={row.manufacturer}
              fontWeight={500}
              color="#000"
              style={{ mb: 0, pb: 0 }}
            />
          </TableCell>
          <TableCell style={{ fontWeight: 500, color: "#000" }}>
            {numberWithCommas(~~row.tariff_value)}
          </TableCell>
          <TableCell style={{ fontWeight: 500, color: "#000" }}>
            {numberWithCommas(~~row.tariff_duty)}
          </TableCell>
          <TableCell style={{ fontWeight: 500, color: "#000" }} align="right">
            {`${(row.custom_value * 100).toFixed(2)}%`}
          </TableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

let totalRowsData = ["Total", "0", "0", "0%"];

const Vendors = ({
  headingTxt,
  vendorsData,
  relatedVendors,
  unrelatedVendors,
}: {
  headingTxt: string;
  vendorsData?: VendorsDataType;
  relatedVendors?: VendorsDataType;
  unrelatedVendors?: VendorsDataType;
}) => {
  const [tabValue, setTabValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (vendorsData?.total)
    totalRowsData = [
      "Total",
      numberWithCommas(~~vendorsData?.total.customs_value).toString(),
      numberWithCommas(~~vendorsData?.total.customs_duty).toString(),
      `${vendorsData?.total.percent_custom_value.toFixed(2)}%`,
    ];

  const generateData = (): VendorsType[] => {
    const dataSets = [vendorsData, relatedVendors, unrelatedVendors];
    return dataSets[tabValue]?.data || [];
  };

  return (
    <GlobalCard>
      <GlobalText
        name={headingTxt}
        color="#000"
        fontSize={"1.2rem"}
        fontWeight={500}
        isImg={true}
        imgSrc={InfoIcon}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          padding: "1.2rem",
        }}
      />
      <Tabs
        value={tabValue}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        sx={{ mb: 4, ml: 1 }}
      >
        <StyledTab label="All" active={tabValue === 0} />
        <StyledTab label="Related Vendors" active={tabValue === 1} />
        <StyledTab label="Unrelated Vendors" active={tabValue === 2} />
      </Tabs>
      <StripeTable
        maxHeight="620px"
        tableCols={colsData}
        TableChildren={<TableChildren vendorsRows={generateData()} />}
        totalRowsData={totalRowsData}
      />
    </GlobalCard>
  );
};

export default Vendors;
