import React from "react"
import Main from "./main/Main"
import { ThemeMaker } from "./main/styles/ThemeMaker"

const App: React.FC = () => {
  return (
    <div className="App">
      <ThemeMaker>
        <Main />
      </ThemeMaker>
    </div>
  )
}

export default App
