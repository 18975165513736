import { Box, Button, Card, Typography } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import ReactApexChart from "react-apexcharts";
import LeftArrowIcon from "../../../assets/images/leftArrow.svg";
import RightArrowIcon from "../../../assets/images/rightArrow.svg";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { useEffect, useState } from "react";
import {
  abbreviateNumber,
  numberWithCommas,
} from "../../../utils/abbreviateNumber";
dayjs.extend(quarterOfYear);

interface IDutySavings {
  data: IKeyValue | any;
}
const DutySavings = ({ data }: IDutySavings) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [displayedQuarters, setDisplayedQuarters] = useState<IKeyValue[]>([]);
  const [displayedDutySavings, setDisplayedDutySavings] = useState<IKeyValue[]>(
    []
  );
  const itemsPerPage = 8;

  const getQuarter = (month: number) => Math.ceil(month / 3);

  const transformData = (data: IKeyValue) => {
    const quarters: IKeyValue[] = [];
    const dutySavingsByQuarter: IKeyValue[] = [];

    if (data) {
      const groupedData = data?.reduce(
        (acc: IKeyValue, { year, month, totalDutySavings }: IKeyValue) => {
          if (!acc[year]) {
            acc[year] = { 1: 0, 2: 0, 3: 0, 4: 0 };
          }
          const quarter = getQuarter(month);
          acc[year][quarter] += totalDutySavings;
          return acc;
        },
        {}
      );

      Object.keys(groupedData).forEach((year) => {
        Object.keys(groupedData[year]).forEach((quarter) => {
          const totalDutySavings = groupedData[year][quarter];
          if (totalDutySavings > 0) {
            // Only include quarters with data
            quarters.push([year, `(Q${quarter})`]);
            dutySavingsByQuarter.push({
              totalDutySavings,
              year: parseInt(year),
              quarter: parseInt(quarter),
            });
          }
        });
      });
    }

    return { quarters, dutySavingsByQuarter };
  };

  const { quarters, dutySavingsByQuarter } = transformData(data?.data);

  useEffect(() => {
    // Example usage:
    if (quarters?.length && dutySavingsByQuarter?.length) {
      setDisplayedQuarters(quarters.slice(0, itemsPerPage));
      setDisplayedDutySavings(dutySavingsByQuarter.slice(0, itemsPerPage));
    }
  }, []);

  const totalDutySavings = data
    ? data?.data?.reduce((acc: number, curr: IKeyValue) => {
        acc = acc + curr?.totalDutySavings;
        return acc;
      }, 0)
    : 0;

  const state: any = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // This disables the toolbar entirely
      },
    },
    colors: ["#0B494B"], // Red for Series 1, Blue for Series 2
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 4,
        borderRadiusApplication: "end", // 'around', 'end'
        borderRadiusWhenStacked: "last", // 'all', 'last'
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: displayedQuarters,
      type: "category",
      labels: {
        style: {
          fontSize: "14px",
          colors: "#8E9494",
          fontFamily: "Inter",
        },
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "transparent",
        width: "0",
        dashArray: 5,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          if (!val) return val;
          return abbreviateNumber(val);
        },
        style: {
          fontSize: "14px",
          colors: "#8E9494",
          fontFamily: "Inter",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val + " units";
        },
      },
    },
    grid: {
      borderColor: "#D8DAE5",
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  const series = [
    {
      name: "Quarterly Data",
      data:
        displayedDutySavings && totalDutySavings !== 0
          ? displayedDutySavings?.map(
              (item: IKeyValue) => item?.totalDutySavings
            )
          : [],
    },
  ];

  const handleNext = () => {
    if ((currentPage + 1) * itemsPerPage >= quarters.length) return;
    const newPage = currentPage + 1;
    const start = newPage * itemsPerPage;
    const end = start + itemsPerPage;
    if (start < quarters.length) {
      setDisplayedQuarters(quarters.slice(start, end));
      setDisplayedDutySavings(dutySavingsByQuarter.slice(start, end));
      setCurrentPage(newPage);
    }
  };
  const handlePrev = () => {
    if (currentPage === 0) return;

    const newPage = currentPage - 1;
    if (newPage >= 0) {
      const start = newPage * itemsPerPage;
      const end = start + itemsPerPage;
      setDisplayedQuarters(quarters.slice(start, end));
      setDisplayedDutySavings(dutySavingsByQuarter.slice(start, end));
      setCurrentPage(newPage);
    }
  };

  const totalSubDutySavingsValue = totalDutySavings
    ?.toString()
    ?.split(".")[1]
    ?.slice(0, 2);

  return (
    <Card sx={{ px: 3, pt: 2, pb: 1, height: "485px" }}>
      {!data || !data?.data?.length ? (
        <>
          <Typography
            color="#000"
            fontSize={"1.2rem"}
            fontWeight={500}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 7,
            }}
          >
            Duty Savings Utilized
          </Typography>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            color="#000"
            fontSize={"1rem"}
            fontWeight={500}
          >
            No data to display
          </Typography>
        </>
      ) : (
        <>
          <Typography
            color="#000"
            fontSize={"1.2rem"}
            fontWeight={500}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 7,
            }}
          >
            Duty Savings Utilized
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              marginBottom: "1rem",
            }}
          >
            <Typography
              color="#000"
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"2rem"}
              fontWeight={700}
            >
              ${numberWithCommas(totalDutySavings?.toString()?.split(".")[0])}
              <span
                style={{
                  fontSize: "1.2rem",
                  color: "#8E9494",
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                .{totalSubDutySavingsValue}
              </span>
            </Typography>
            {/* <Button
        variant="text"
        sx={{
          borderRadius: "6px",
          border: "1px solid #E6E7E7 ",
          textTransform: "none",
          mt: 3,
          py: "10px",
          pl: "1.5rem",
          pr: "14px",
          gap: "7px",
        }}
      >
        View Utilization Details <MdKeyboardArrowRight size={20} />
      </Button> */}
          </Box>
          <Box position={"relative"}>
            <ReactApexChart
              options={state}
              series={series}
              type="bar"
              height={350}
            />{" "}
            <img
              style={{
                position: "absolute",
                cursor: "pointer",
                bottom: "2.2rem",
                left: "2.5rem",
              }}
              src={LeftArrowIcon}
              alt="LeftArrowIcon"
              onClick={handlePrev}
            />
            <img
              style={{
                position: "absolute",
                cursor: "pointer",
                bottom: "2.2rem",
                right: 0,
              }}
              src={RightArrowIcon}
              alt="RightArrowIcon"
              onClick={handleNext}
            />
          </Box>
        </>
      )}
    </Card>
  );
};

export default DutySavings;
