// material
import { Popover, SxProps, Theme } from "@mui/material";

import { ReactNode } from "react";

// ----------------------------------------------------------------------

interface IMenuPopover {
  open: boolean;
  onClose: () => void;
  children: ReactNode[] | ReactNode;
  sx: SxProps<Theme>;
  anchorEl: Element;
}

export default function MenuPopover({
  open,
  onClose,
  children,
  sx,
  anchorEl,
  ...rest
}: IMenuPopover): JSX.Element {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      anchorEl={anchorEl}
      // BackdropProps={{
      //   sx: {
      //     backgroundColor: "none",
      //   },
      // }}
      PaperProps={{
        sx: {
          ml: 0.5,
          overflow: "inherit",
          boxShadow: "0px 4px 19px 0px rgba(0, 0, 0, 0.13)",
          width: 200,
          borderRadius: "10px",
          ...sx,
        },
      }}
      {...rest}
    >
      {children}
    </Popover>
  );
}
