export const DATA_COLUMNS = Object.freeze({
  entrySummaryNumber: "Entry Summary Number",
  entryPlusLine: "Entry + Line",
  filerCode: "Filer Code",
  entryTypeCode: "Entry Type Code",
  modeOfTransportationCode: "Mode of Transportation Code",
  importerNumber: "Importer Number",
  portOfEntryCode: "Port of Entry Code",
  entryDate: "Entry Date",
  entryYear: "Entry Year",
  entrySummaryDate: "Entry Summary Date",
  entrySummaryCreateDate: "Entry Summary Create Date",
  entrySummaryLineNumber: "Entry Summary Line Number",
  reviewTeamNumber: "Review Team Number",
  countryOfOriginCode: "Country of Origin Code",
  countryOfExportCode: "Country of Export Code",
  manufacturerID: "Manufacturer ID",
  relatedPartyIndicator: "Related Party Indicator",
  foreignExporterID: "Foreign Exporter ID",
  lineSPICode: "Line SPI Code",
  standardVisaNumber: "Standard Visa Number",
  textileCategoryCode: "Textile Category Code",
  textileCategory: "Textile Category",
  tariffOrdinalNumber: "Tariff Ordinal Number",
  hTS8Digit: "HTS 8 Digit",
  hTSNumberFull: "HTS Number - Full",
  lineTariffQuantity1: "Line Tariff Quantity (1)",
  lineTariffUOM1: "Line Tariff UOM (1) Code",
  lineTariffQuantity2: "Line Tariff Quantity (2)",
  lineTariffUOM2: "Line Tariff UOM (2) Code",
  lineTariffQuantity3: "Line Tariff Quantity (3)",
  lineTariffUOM3: "Line Tariff UOM (3) Code",
  lineTariffGoodsValueAmount: "Line Tariff Goods Value Amount",
  lineTariffDutyAmount: "Line Tariff Duty Amount",
  ch98: "Ch 98",
  ch99: "Ch 99",
  ch98Code: "Ch 98 Code",
  ch99Code: "Ch 99 Code",
  ch99Code2: "Ch 99 Code 2",
  mTB: "MTB",
  sec201: "Sec 201",
  sec232: "Sec 232",
  sec301: "Sec 301",
  exclusions: "Exclusions",
  mFNDuty: "MFN Duty",
  mTBDuty: "MTB Duty",
  duty201: "201 Duty",
  duty232: "232 Duty",
  duty301: "301 Duty",
  exclusionSavings: "Exclusion Savings",
  sec301List: "Sec 301 List",
  rate: "Rate",
  totalEstimatedMPFAmount: "Total Estimated MPF Amount",
  totalEstimatedHMFAmount: "Total Estimated HMF Amount",
  totalEstimatedOtherFeeAmount: "Total Estimated Other Fee Amount",
  totalEstimatedFeeAmount: "Total Estimated Fee Amount",
  count: "Count",
  username: "username"
})

const DataHelpers = ({ isAdmin }: { isAdmin: boolean }) => {
  const createColumns = () => {
    const columns = []
    for (const [key, value] of Object.entries(DATA_COLUMNS)) {
      columns.push({ field: value, headerName: value, width: 150 })
    }
    if (!isAdmin) columns.pop()
    return columns
  }
  return { createColumns }
}
export default DataHelpers
