import { useContext, useEffect, useState } from "react";
import CustomCard from "../../common/CustomCard";
import UserTable from "./UserTable";
import UserHeader from "./UserHeader";
import UserContext from "../../context/user/UserContext";
import { UserContextType } from "../../@types/UserContext";
import { ENDPOINT } from "../../config/config";
import axios from "axios";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { UserParams } from "../../@types/User";

const USER_PER_PAGE: number = 10;

const Users = () => {
  const userContext = useContext(UserContext) as UserContextType;
  const [errorLoading, setErrorLoading] = useState<{
    isLoading: boolean;
    isError: boolean;
    errorMsg?: string | null;
  }>({ isError: false, isLoading: false, errorMsg: null });

  const [users, setUsers] = useState<UserParams[]>([]);

  useEffect(() => {
    getUsers(0);
  }, []);

  const getUsers = async (offset: number) => {
    try {
      setErrorLoading({ isLoading: true, isError: false });
      const resp = await axios.get(`${ENDPOINT}/users`, {
        headers: {
          authorization: `Bearer ${userContext.user.token}`,
        },
      });

      const res = resp?.data;
      setUsers(res);
      setErrorLoading({ isLoading: false, isError: false });
    } catch (err: any) {
      setErrorLoading({
        isLoading: false,
        isError: true,
        errorMsg: err?.response?.data?.error,
      });
    }
  };

  return (
    <Box sx={{ pb: 2, mt: { md: 2, lg: 0 } }}>
      <UserHeader fetchUsers={getUsers} />
      {errorLoading?.isLoading && (
        <Box sx={{ width: "100%", marginTop: 10, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {users && !errorLoading?.isLoading ? (
        <UserTable
          users={users}
          page={0}
          totalUsers={users?.length}
          userPerPage={USER_PER_PAGE}
          fetchUsers={getUsers}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};
export default Users;
