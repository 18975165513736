import { Box, Button, Divider, MenuItem } from "@mui/material";
import { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IKeyValue } from "../../../../@types/GlobalMessageContext";
import FilterIcon from "../../../../assets/images/filter.svg";
import FilterDarkIcon from "../../../../assets/images/filterDark.svg";
import AppSelect from "../../../select";
import GlobalText from "../../../typography";
import { dutySpendData } from "../../data";

interface IAdvanceFilter {
  StyledMenu: any;
  setOpenDateFilter: (value: null | HTMLElement) => void;
  handleFilterReset: () => void;
  onDutySpendChange: (value: IKeyValue) => void;
  handleSubmit: any;
  control: any;
  reset: (value: IKeyValue) => void;
  defaultFilterValues: IKeyValue;
}

const AdvanceFilter = ({
  StyledMenu,
  setOpenDateFilter,
  handleFilterReset,
  onDutySpendChange,
  handleSubmit,
  control,
  reset,
  defaultFilterValues,
}: IAdvanceFilter) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    reset(defaultFilterValues);
    handleFilterReset();
    handleDateFilterClick({ currentTarget: false });
  };
  const handleDateFilterClick = (
    event?: React.MouseEvent<HTMLElement> | any
  ) => {
    setOpenDateFilter(event!.currentTarget);
  };

  return (
    <>
      <Button
        variant="contained"
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        sx={{
          background: "#0B494B",
          textTransform: "none",
          padding: "12px",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="filter" />
        <GlobalText name="Advance Filter" color="#fff" fontSize={14} />
        {/* {anchorEl ? (
        <Avatar
          sx={{
            width: "22px",
            height: "22px",
            background: "rgba(255, 255, 255, .3)",
          }}
        >
          <Typography color="#fff" fontWeight={600} fontSize={11}>
            5{" "}
          </Typography>
        </Avatar>
      ) : (
        ""
      )} */}
        <MdKeyboardArrowDown color="#fff" size={"20px"} />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // PaperProps={{ sx: { width: "350px", height: "70vh" } }}
        PaperProps={{ sx: { width: "350px" } }}
      >
        <Box sx={{ background: "#fff" }}>
          <MenuItem disableRipple>
            <Box
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"space-between"}
              width="100%"
            >
              <Box display={"flex"} alignItems={"center"} width="70%" gap={1}>
                <img src={FilterDarkIcon} alt="filter" />
                <GlobalText
                  width="50%"
                  color="#000"
                  fontWeight={600}
                  name="Advance Filter"
                />

                {/* <Avatar
                sx={{
                  width: "21px",
                  height: "21px",
                  background: "#EA4334",
                }}
              >
                <Typography color="#fff" fontWeight={600} fontSize={11}>
                  5{" "}
                </Typography>
              </Avatar> */}
              </Box>
              <GlobalText
                name="Clear All"
                color="#116B6F"
                fontSize="12px"
                fontWeight={500}
                style={{ textDecoration: "underline" }}
                onClick={handleReset}
              />
            </Box>
          </MenuItem>
          <Divider />
          <Box sx={{ px: 2.5, pb: 2.5, pt: 1 }}>
            <Box marginBottom={2.5}>
              <AppSelect
                label="Duty Paid"
                placeholder="Select Duty Paid"
                data={dutySpendData}
                name="dutySpend"
                control={control}
                style={{
                  isBorder: true,
                  labelFontWeight: true,
                  labelFontSize: true,
                  labelColor: true,
                  iconOpacity: true,
                  iconFontSize: true,
                  iconMarginTop: true,
                  iconColor: true,
                  inputBorderColor: true,
                  inputFontSize: true,
                  txtFontWeight: true,
                  txtColor: true,
                  placeholderColor: true,
                  placeholderOpacity: true,
                }}
              />
            </Box>
            {/* <Box marginBottom={2.5}>
            <AppSelect
              label="Importer No."
              placeholder="Select Importer No"
              data={importerNo}
              name="importerNo"
              control={control}
              style={{
                isBorder: true,
                labelFontWeight: true,
                labelFontSize: true,
                labelColor: true,
                iconOpacity: true,
                iconFontSize: true,
                iconMarginTop: true,
                iconColor: true,
                inputBorderColor: true,
                inputFontSize: true,
                txtFontWeight: true,
                txtColor: true,
                placeholderColor: true,
                placeholderOpacity: true,
              }}
            />
          </Box>
          <Box marginBottom={2.5}>
            <AppSelect
              label="Classification"
              placeholder="Select Classification"
              data={classification}
              name="classification"
              control={control}
              style={{
                isBorder: true,
                labelFontWeight: true,
                labelFontSize: true,
                labelColor: true,
                iconOpacity: true,
                iconFontSize: true,
                iconMarginTop: true,
                iconColor: true,
                inputBorderColor: true,
                inputFontSize: true,
                txtFontWeight: true,
                txtColor: true,
                placeholderColor: true,
                placeholderOpacity: true,
              }}
            />
          </Box>
          <Box marginBottom={2.5}>
            <AppSelect
              label="Trade Flow"
              placeholder="Select Trade Flow"
              data={tradeFlow}
              name="tradeFlow"
              control={control}
              style={{
                isBorder: true,
                labelFontWeight: true,
                labelFontSize: true,
                labelColor: true,
                iconOpacity: true,
                iconFontSize: true,
                iconMarginTop: true,
                iconColor: true,
                inputBorderColor: true,
                inputFontSize: true,
                txtFontWeight: true,
                txtColor: true,
                placeholderColor: true,
                placeholderOpacity: true,
              }}
            />
          </Box>
          <Box marginBottom={2.5}>
            <AppSelect
              label="Entry No."
              placeholder="Select Entry No."
              data={entryNo}
              name="entryNo"
              control={control}
              style={{
                isBorder: true,
                labelFontWeight: true,
                labelFontSize: true,
                labelColor: true,
                iconOpacity: true,
                iconFontSize: true,
                iconMarginTop: true,
                iconColor: true,
                inputBorderColor: true,
                inputFontSize: true,
                txtFontWeight: true,
                txtColor: true,
                placeholderColor: true,
                placeholderOpacity: true,
              }}
            />
          </Box>
          <Box marginBottom={2.5}>
            <AppSelect
              label="Country of Origin"
              placeholder="Select Country of Origin"
              data={countryOfOrigin}
              name="countryOfOrigin"
              control={control}
              style={{
                isBorder: true,
                labelFontWeight: true,
                labelFontSize: true,
                labelColor: true,
                iconOpacity: true,
                iconFontSize: true,
                iconMarginTop: true,
                iconColor: true,
                inputBorderColor: true,
                inputFontSize: true,
                txtFontWeight: true,
                txtColor: true,
                placeholderColor: true,
                placeholderOpacity: true,
              }}
            />
          </Box>
          <Box marginBottom={2.5}>
            <AppSelect
              label="Country of Export"
              placeholder="Select Country of Export"
              data={countryOfExpert}
              name="countryOfExpert"
              control={control}
              style={{
                isBorder: true,
                labelFontWeight: true,
                labelFontSize: true,
                labelColor: true,
                iconOpacity: true,
                iconFontSize: true,
                iconMarginTop: true,
                iconColor: true,
                inputBorderColor: true,
                inputFontSize: true,
                txtFontWeight: true,
                txtColor: true,
                placeholderColor: true,
                placeholderOpacity: true,
              }}
            />
          </Box> */}
          </Box>
        </Box>

        <Divider />

        <Box
          width="100%"
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"center"}
          gap={1.5}
          sx={{ background: "#F7F8F8", paddingBottom: 2 }}
        >
          <Box width={"43%"}>
            <Button
              variant="text"
              sx={{
                borderRadius: "6px",
                border: "1px solid #E6E7E7 ",
                background: "#fff",
                color: "#000",
                textTransform: "none",
                mt: 3,
                py: "10px",
                px: "12px",
                width: "100%",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
          <Box width={"43%"}>
            <Button
              variant="text"
              onClick={handleSubmit(onDutySpendChange)}
              sx={{
                borderRadius: "6px",
                background: "#0B494B",
                color: "#fff",
                textTransform: "none",
                mt: 3,
                py: "10px",
                px: "12px",
                width: "100%",
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>

        {/* </MenuItem> */}
      </StyledMenu>
    </>
  );
};

export default AdvanceFilter;
