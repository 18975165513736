import {
  Avatar,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import InfoIcon from "../../../assets/images/info.svg";
import GlobalCard from "../../../components/card";
import GlobalText from "../../../components/typography";
import { numberWithCommas } from "../../../utils/abbreviateNumber";

// Define interfaces for data types
interface ManufacturerDetails {
  mainText: string;
}

interface ApiData {
  potential_saving: number;
  program: any;
}

interface MappedData {
  ManufacturerDetails: ManufacturerDetails;
  customValuePercent: any;
}

interface AgreementOpportunitiesTableProps {
  headingTxt: string;
  data: ApiData[];
  loading: boolean;
}

const colsData = ["Agreement Name", "Trade Agreement Opportunity  "];

const totalRowsData = ["Total", ""];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8F8",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableChildrens = ({ apiData }: { apiData: MappedData[] }) => {
  if (!apiData || apiData.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={2}>No data available</TableCell>
      </TableRow>
    );
  }
  return (
    <>
      {apiData?.map((row, index) => (
        <StyledTableRow key={index} sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            <Box display="flex">
              <GlobalText
                name={row.ManufacturerDetails.mainText}
                fontWeight={500}
                color="#000"
                style={{ mb: 0, pb: 0, p: "8px" }}
              />
            </Box>
          </TableCell>

          <TableCell style={{ fontWeight: 500, color: "#000" }} align="right">
            {row.customValuePercent}
          </TableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

const AgreementOpportunitiesTable = ({
  headingTxt,
  data,
  loading,
}: AgreementOpportunitiesTableProps) => {
  if (loading) {
    return (
      <GlobalCard style={{ height: "544px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </GlobalCard>
    );
  }

  const mappedData: MappedData[] = data?.map((item) => ({
    ManufacturerDetails: {
      mainText: item.program ?? "-",
    },
    customValuePercent: numberWithCommas(
      parseFloat(item.potential_saving.toFixed(2))
    ),
  }));

  const totalPotentialSavings =
    data?.reduce(
      (acc, item) => acc + parseFloat(item.potential_saving.toFixed(2)),
      0
    ) ?? 0;

  return (
    <GlobalCard style={{ height: "544px" }}>
      <GlobalText
        name={headingTxt}
        color="#000"
        fontSize={"1.2rem"}
        fontWeight={500}
        isImg={true}
        imgSrc={InfoIcon}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          padding: "1.2rem",
        }}
      />
      <TableContainer
        component={Paper}
        style={{
          maxHeight: 478,
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {colsData.map((col, ind) => (
                <TableCell
                  sx={{
                    background: "#CEDBDB",
                    color: "#116B6F",
                    padding: "6px 16px",
                    fontSize: "14px",
                  }}
                  align={ind === totalRowsData.length - 1 ? "right" : "left"}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableChildrens apiData={mappedData} />
            <StyledTableRow>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "500",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "-1px",
                  zIndex: "2",
                }}
                align="left"
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "500",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "-1px",
                  zIndex: "2",
                }}
                align="right"
              >
                {totalPotentialSavings}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GlobalCard>
  );
};

export default AgreementOpportunitiesTable;
