import Data from "../main/data/Data"
import Homepage from "../main/homepage/Homepage"
import PdfUpload from "../main/cbp-form/Cbp"
import Questionnaire from "../main/questionnaire/Questionnaire"
import Users from "../main/user/User"
import ProductView from "../main/dashboard/productview"
import Potential from "../main/dashboard/savings"
import Analytics from "../main/dashboard/analytics"
import MainDashboard from "../main/dashboard/main"

export const routes = [
  {
    key: "homepage",
    title: "Homepage",
    route: "/",
    auth_required: true,
    component: MainDashboard
    // component: Analytics
  },
  {
    key: "visibility",
    title: "Visibility",
    route: "/dashboard/visibility",
    auth_required: true,
    component: ProductView
  },
  {
    key: "analytics",
    title: "Analytics",
    route: "/dashboard/analytics",
    auth_required: true,
    component: Analytics
  },
  {
    key: "potential",
    title: "Potential",
    route: "/dashboard/savings/potential",
    auth_required: true,
    component: Potential
  },
  {
    key: "datas",
    title: "Data",
    route: "/data",
    auth_required: true,
    component: Data
  },
  {
    key: "cbp",
    title: "cbp",
    route: "/cbp",
    auth_required: true,
    component: PdfUpload
  },
  {
    key: "questionaire",
    title: "Questionaire",
    route: "/questionaire",
    auth_required: true,
    component: Questionnaire
  }
]

export const adminRoutes = [
  {
    key: "users",
    title: "User",
    route: "/users",
    auth_required: true,
    component: Users
  }
]
