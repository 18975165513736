import { Checkbox as MUICheckbox, Stack, Typography } from "@mui/material";
import { RiCheckboxBlankFill } from "react-icons/ri";
import { IoIosCheckbox } from "react-icons/io";

interface Props {
  color:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  label?: string;
  checked: boolean;
  onChange: () => void;
}

const Checkbox = ({ color, label, checked, onChange }: Props): JSX.Element => {
  return (
    <Stack direction="row" alignItems={"center"}>
      <MUICheckbox
        color={color}
        checked={checked}
        onChange={onChange}
        sx={{ padding: 0 }}
        icon={<RiCheckboxBlankFill color="#eeeeee" size={21} />}
        checkedIcon={<IoIosCheckbox size={21} />}
      />
      <Typography variant="body1" fontSize={"14px"} ml={1} color="#4E535A">
        {label}
      </Typography>
    </Stack>
  );
};

export default Checkbox;
