import React, { useState, useContext } from "react";
import axios from "axios";
import {
  Tooltip,
  Button,
  Typography,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import Uploader from "./Uploader";
import { ENDPOINT } from "../../config/config";
import { UserContextType } from "../../@types/UserContext";
import UserContext from "../../context/user/UserContext";
import { UploadProps } from "../../@types/Upload";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Modal } from "../../components";

const ALLOWED_EXTENSION = ["xls", "xlsx", "xlsb"];

const Files_Descriptions = [
  {
    name: "003 Original File",
    id: "file1",
  },
  {
    name: "001 Header Fee File",
    id: "file2",
  },
  {
    name: "003 Line Fee File",
    id: "file3",
  },
  {
    name: "MPF Table File",
    id: "file4",
  },
];
const Upload: React.FC<UploadProps> = ({
  loading = false,
  handleLoading,
  fetchData,
  ext = ALLOWED_EXTENSION,
  fileDescriptions = Files_Descriptions,
  api = "upload",
}) => {
  const userContext = useContext(UserContext) as UserContextType;
  const [openDialog, setOpenDialog] = useState(false);

  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;
  const { updateDataProgress, setIsShowDataProgress } = useContext(
    UserContext
  ) as UserContextType;
  function toggleDialog() {
    setOpenDialog(!openDialog);
  }
  const [files, setFiles] = useState<any>({});

  const handleClose = () => setOpenDialog(false);

  const updateFiles = (fileObj: any) => setFiles({ ...files, ...fileObj });

  const validateFiles = (): boolean => {
    let isValid = true;
    Object.keys(files).forEach((key: any) => {
      let fileExt = files[key].name.split(".").pop();
      if (!ext.includes(fileExt)) isValid = false;
    });
    return isValid;
  };
  const handleUpload = async () => {
    try {
      if (!validateFiles()) {
        updateSnackBar({
          show: true,
          variant: "error",
          message: "You have uploaded an invalid file type.",
        });
        return;
      } else if (!files["file1"]) {
        updateSnackBar({
          show: true,
          variant: "error",
          message: "Main file is required.",
        });
        return;
      } else {
        toggleDialog();
        handleLoading && handleLoading(true);

        const formData = new FormData();
        Object.keys(files).forEach((key: any) => {
          formData.append(key, files[key]);
        });

        const res = await axios.post(`${ENDPOINT}/${api}`, formData, {
          headers: {
            Authorization: `Bearer ${userContext.user.token}`,
            "Content-Type": "form-data",
          },
        });
        handleLoading && handleLoading(false);
        if (res?.status === 200) {
          updateSnackBar({
            show: true,
            variant: "success",
            message: res.data?.msg,
          });
          const taskId = res.data?.task_id;
          if (taskId) pollProgress(res.data?.task_id);
          fetchData({});
        }
      }
    } catch (err) {
      handleLoading && handleLoading(false);
      updateSnackBar({
        show: true,
        variant: "error",
        message: "You have uploaded an invalid file type.",
      });
    }
  };

  const pollProgress = async (taskId: string) => {
    const interval = setInterval(async () => {
      try {
        const response = await axios.get(`${ENDPOINT}/get_task/${taskId}`, {
          headers: {
            Authorization: `Bearer ${userContext.user.token}`,
            "Content-Type": "form-data",
          },
        });

        console.log(response.data.progress, "my progress");
        const progressData = Math.round(response.data.progress);
        if (
          response?.data?.error_msg !== "" &&
          response?.data?.status === "Error"
        ) {
          throw response?.data?.error_msg;
        }
        updateDataProgress(progressData);

        if (progressData >= 100) {
          setIsShowDataProgress(false);
          clearInterval(interval);
        }
      } catch (error: any) {
        console.error("Error fetching progress:", error);
        setIsShowDataProgress(false);
        clearInterval(interval);
        updateSnackBar({
          show: true,
          variant: "error",
          message: typeof error === "string" ? error : error.message,
        });
      }
    }, 1000); // Poll every second
  };

  return (
    <>
      <Tooltip title="Upload File" disableFocusListener>
        {loading ? (
          <Box sx={{ minWidth: { xs: "100%", sm: 170 }, textAlign: "center" }}>
            <CircularProgress color="primary" size={25} />
          </Box>
        ) : (
          <Button
            onClick={toggleDialog}
            color="primary"
            variant="contained"
            size="medium"
            sx={{
              textTransform: "initial",
              borderRadius: "50px",
              p: 1.8,
              minWidth: { xs: "100%", sm: 150 },
              borderColor: "#0B494B",
            }}
            disableElevation
          >
            <AddCircleIcon sx={{ fontSize: 28, pr: 2 }} />
            <Typography fontSize={"16px"} fontWeight={500} color={"#FFF"}>
              Upload
            </Typography>
          </Button>
        )}
      </Tooltip>
      <Modal
        open={openDialog}
        handleClose={handleClose}
        title="Upload File"
        actions={
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <Button
              onClick={handleClose}
              disableElevation
              variant="contained"
              color="error"
              sx={{
                background: "#FF4560",
                color: "#fff",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpload}
              disableElevation
              variant="contained"
              color="success"
              sx={{
                background: "#5EDA81",
                color: "#fff",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
            >
              Upload
            </Button>
          </Stack>
        }
      >
        <>
          <Box sx={{ mt: 3 }}>
            <Uploader
              updateFiles={updateFiles}
              ext={ext}
              fileDescriptions={fileDescriptions}
              files={files}
            />
          </Box>
        </>
      </Modal>
    </>
  );
};

export default Upload;
