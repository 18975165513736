export const abbreviateNumber = (number: any) => {
  if (isNaN(number)) {
    return number;
  }

  if (number < 1000) {
    return number.toString();
  }

  const suffixes = ["", "K", "M", "B", "T"];
  const magnitude = Math.floor(Math.log10(number) / 3);

  const scaledNumber = number / Math.pow(10, magnitude * 3);
  const formattedNumber = scaledNumber.toFixed(2);

  return formattedNumber + suffixes[magnitude];
};

export const numberWithCommas = (x: number) => {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return x;
};
