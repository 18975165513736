import { CircularProgress, LinearProgress, Typography } from "@mui/material";
import { useContext } from "react";
import { UserContextType } from "../../@types/UserContext";
import UserContext from "../../context/user/UserContext";

const FileProgressBar = () => {
  const { dataProgress } = useContext(UserContext) as UserContextType;

  return (
    <div
      style={{
        background: "#fff",
        marginBottom: "1.5rem",
        padding: "10px",
        borderRadius: 8,
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <CircularProgress size={30} />
        <Typography>Uploading File</Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: 15,
          width: "100%",
          gap: "1rem",
        }}
      >
        <LinearProgress
          sx={{ width: "80%" }}
          variant="determinate"
          value={dataProgress}
        />
        <div style={{ width: "20%" }}>{`${dataProgress}%`}</div>
      </div>
    </div>
  );
};

export default FileProgressBar;
