import React, { useContext, useState, useEffect } from "react";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import DateHelpers from "../dashboard/DateHelpers";
import { ENDPOINT } from "../../config/config";
import axios from "axios";
import { UserContextType } from "../../@types/UserContext";
import UserContext from "../../context/user/UserContext";
import { error } from "console";
import { UserFilterProps } from "../../@types/UserFilter";

const UserFilter: React.FC<UserFilterProps> = ({ fetchData }) => {
  const userContext = useContext(UserContext) as UserContextType;
  const [selectedOption, setSelectedOption] = useState("allUsers");
  const [users, setUsers] = useState<any[]>([]);
  const [errorLoading, setErrorLoading] = useState<{
    isLoading: boolean;
    isError: boolean;
    errorMsg?: string | null;
  }>({ isError: false, isLoading: false, errorMsg: null });

  const handleSelectChange = (event: any) => {
    let filterUserValue = event.target.value;
    setSelectedOption(filterUserValue);
    if (filterUserValue === "allTime") filterUserValue = "";
    fetchData({ offset: 0, userId: filterUserValue });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      setErrorLoading({ isLoading: true, isError: false });
      const resp = await axios.get(`${ENDPOINT}/users`, {
        headers: {
          authorization: `Bearer ${userContext.user.token}`,
        },
      });

      const res = resp?.data;
      const usersWithoutSuperAdmin = res.filter(
        (user: any) => user.role !== "superAdmin"
      );

      setUsers(usersWithoutSuperAdmin);
      setErrorLoading({ isLoading: false, isError: false });
    } catch (err: any) {
      setErrorLoading({
        isLoading: false,
        isError: true,
        errorMsg: err?.response?.data?.error,
      });
    }
  };

  return (
    <>
      {errorLoading.isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Select
          size={"medium"}
          sx={{
            minWidth: {xs: '100%', sm: 150},
            pl: 1.5,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: (theme) => theme.palette.primary.main,
            },
            ".MuiSvgIcon-root ": {
              fill: "#0B494B !important",
              fontSize: 40,
              marginRight: 1.5
            },
          }}
          value={selectedOption}
          onChange={handleSelectChange}
          color="primary"
        >
          <MenuItem key="allUsers" value="allUsers">
            All Users
          </MenuItem>
          {users.map((user) => (
            <MenuItem key={user.userId} value={user.userId}>
              {user.username}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

export default UserFilter;
