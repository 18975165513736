import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputLabel, ListItemIcon, Typography } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { Control, Controller } from "react-hook-form";
import { IKeyValue } from "../../@types/GlobalMessageContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface IAppSelect {
  label: string;
  placeholder: string;
  data: { label: string; value: string; icon?: any }[];
  name: string;
  control: Control<any>;
  style?: IKeyValue;
}

const AppSelect = ({
  label,
  data,
  placeholder,
  name,
  control,
  style,
}: IAppSelect) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        border: style?.isBorder ? "none" : "",
      },
    },
  };

  return (
    <>
      <InputLabel
        htmlFor="hey"
        sx={{
          fontWeight: style?.labelFontWeight ? 550 : "",
          fontSize: style?.labelFontSize ? "14px" : "",
          color: style?.labelColor ? "#000" : "",
        }}
      >
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl sx={{ mt: 1, width: { xs: "100%" } }}>
            <Select
              displayEmpty
              onChange={onChange}
              value={value}
              input={<OutlinedInput />}
              IconComponent={() => (
                <IoIosArrowDown
                  style={{
                    paddingRight: "10px",
                    opacity: style?.iconOpacity ? 1 : 0.5,
                    fontSize: style?.iconFontSize ? "1.1rem" : "1.7rem",
                    color: style?.iconColor ? "#8E9494" : "#4E535A",
                    marginTop: style?.iconMarginTop ? "4px" : "",
                  }}
                />
              )}
              sx={{
                width: "100%",
                fontWeight: style?.txtFontWeight ? 550 : "",
                color: style?.txtColor ? "#000" : "",

                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: style?.inputBorderColor ? "#D4D7D7" : "#0B494B",
                  borderRadius: style?.inputFontSize ? "8px" : "",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: style?.inputBorderColor ? "#D4D7D7" : "#0B494B",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: style?.inputBorderColor ? "#D4D7D7" : "#0B494B",
                },
              }}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return (
                    <span
                      style={{
                        color: style?.placeholderColor ? "#8E9494" : "#4E535A",
                        fontWeight: "400",
                        opacity: style?.placeholderOpacity ? 1 : 0.5,
                      }}
                    >
                      {placeholder}
                    </span>
                  );
                }
                const selectedEntry = data.find(
                  (entry) => entry?.value === selected
                );

                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {selectedEntry?.icon && (
                      <img
                        src={selectedEntry.icon}
                        alt="icon"
                        style={{ marginRight: 8 }}
                      />
                    )}
                    {selectedEntry?.label}
                  </div>
                );
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="" sx={{ fontSize: "1rem" }}>
                <Typography fontSize="1rem">{placeholder}</Typography>
              </MenuItem>
              {data.map((dt) => (
                <MenuItem key={dt.label} value={dt.value}>
                  {dt?.icon ? (
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <img src={dt?.icon} alt="icon" />
                    </ListItemIcon>
                  ) : (
                    ""
                  )}
                  <Typography fontSize="1rem">{dt.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </>
  );
};

export default AppSelect;
