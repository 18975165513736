import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import InfoIcon from "../../../assets/images/info.svg";
import GlobalCard from "../../../components/card";
import GlobalText from "../../../components/typography";
import { numberWithCommas } from "../../../utils/abbreviateNumber";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import { countries } from "../../../constants";
import { FlagIcon } from "react-flag-kit";

// Define interfaces for data types
interface ManufacturerDetails {
  mainText: string;
  image: string;
}

interface ApiData {
  country_of_origin_code: string;
  potential_saving: any;
}

interface MappedData {
  ManufacturerDetails: ManufacturerDetails;
  customValuePercent: any;
}

interface AgreementByCountriesTableProps {
  headingTxt: string;
  data: ApiData[];
  loading: boolean;
}

const colsData = ["Country Name", "Trade Agreement Opportunity  "];

const totalRowsData = ["", ""];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8F8",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableChildrens = ({ apiData }: { apiData: MappedData[] }) => {
  if (!apiData || apiData.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={2}>No data available</TableCell>
      </TableRow>
    );
  }
  return (
    <>
      {apiData?.map((row, index) => (
        <StyledTableRow key={index} sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            component="th"
            scope="row"
            sx={{
              borderBottom: "1px solid #E8E8E8",
              display: "flex",
              gap: 2,
              alignItems: "center",
              pl: 1,
            }}
          >
            <Box display="flex" gap={0.5} alignItems={"center"}>
              <FlagIcon
                style={{ borderRadius: "50px" }}
                code={row?.ManufacturerDetails.image as any}
              />
            </Box>
            <Typography fontWeight={500} color="#020202" mb={0} pb={0}>
              {row.ManufacturerDetails.mainText}
            </Typography>
          </TableCell>

          <TableCell style={{ fontWeight: 500, color: "#000" }} align="right">
            {row.customValuePercent}
          </TableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

const AgreementByCountriesTable = ({
  headingTxt,
  data,
  loading,
}: AgreementByCountriesTableProps) => {
  if (loading) {
    return (
      <GlobalCard style={{ height: "544px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </GlobalCard>
    );
  }

  const mappedData: MappedData[] = data?.map((item) => {
    const country = countries.find(
      (el: IKeyValue) => el?.shortForm === item?.country_of_origin_code
    );
    return {
      ManufacturerDetails: {
        mainText: country?.name || (item.country_of_origin_code as string),
        image: country?.shortForm || "",
      },
      customValuePercent: numberWithCommas(item.potential_saving),
    };
  });

  return (
    <GlobalCard style={{ height: "544px" }}>
      <GlobalText
        name={headingTxt}
        color="#000"
        fontSize={"1.2rem"}
        fontWeight={500}
        isImg={true}
        imgSrc={InfoIcon}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          padding: "1.2rem",
        }}
      />
      <TableContainer
        component={Paper}
        style={{
          maxHeight: 478,
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {colsData.map((col, ind) => (
                <TableCell
                  sx={{
                    background: "#CEDBDB",
                    color: "#116B6F",
                    padding: "6px 16px",
                    fontSize: "14px",
                  }}
                  align={ind === totalRowsData.length - 1 ? "right" : "left"}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableChildrens apiData={mappedData} />
          </TableBody>
        </Table>
      </TableContainer>
    </GlobalCard>
  );
};

export default AgreementByCountriesTable;
