import { Box, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import { UserContextType } from "../../../@types/UserContext";
import FilterHeader from "../../../components/header/filter-header";
import { ENDPOINT } from "../../../config/config";
import UserContext from "../../../context/user/UserContext";
import AllDuties from "./AllDuties";
import AllImport from "./AllImport";
import DutySavings from "./DutySavings";
import EnteredValue from "./EnteredValue";
import Metrics from "./Metrics";
import TariffCodes from "./TariffCodes";
import Vendors from "./Vendors";

const Analytics = () => {
  const userContext = useContext(UserContext) as UserContextType;
  const [overallImport, setOverAllImport] = useState<IKeyValue[]>([]);
  const [overallDuties, setOverallDuties] = useState<IKeyValue[]>([]);
  const [importMetrics, setImportMetrics] = useState<IKeyValue[]>([]);
  const [tariffCodes, setTariffCodes] = useState<IKeyValue[]>([]);
  const [enteredValue, setEnteredValue] = useState<IKeyValue[]>([]);
  const [dutySavings, setDutySavings] = useState<IKeyValue[]>([]);
  const [vendors, setVendors] = useState<IKeyValue[]>([]);
  const [lastUpdatedDate, setLastUpdatedDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [dutySpendFilter, setDutySpendFilter] = useState<string>("");
  const [filterLabel, setFilterLabel] = useState<string>("Today");
  const [dates, setDates] = useState<null | IKeyValue>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [tariffCodesLoading, setTariffCodesLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, [dutySpendFilter, dates]);

  useEffect(() => {
    console.log("working");
    console.log(page);
    fetchTariffCodesData();
  }, [page, dutySpendFilter, dates]);

  const fetchTariffCodesData = async () => {
    try {
      const startDate = dates?.startDate ?? "";
      const endDate = dates?.endDate ?? "";

      setTariffCodesLoading(true);

      const result = await axios.get(
        `${ENDPOINT}/dashboard-visibility/line-tariff-value-hts?dateFrom=${startDate}&dateTo=${endDate}&attribute=${dutySpendFilter}&pageNo=${page}&pageSize=${pageSize}&minThreshold=50000`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );


      if (result?.data?.data?.length < 5 && page > 1) {
        setTariffCodesLoading(false);
        return;
      }

      setTariffCodes(result?.data);
      setTariffCodesLoading(false);
    } catch (err) {
      setTariffCodesLoading(false);
      console.log(err, "error in fetchTariffCodesData");
    }
  };

  const fetchData = async () => {
    const startDate = dates?.startDate ?? "";
    const endDate = dates?.endDate ?? "";
    try {
      setLoading(true);
      const promise1 = axios.get(
        `${ENDPOINT}/dashboard-visibility/overall-import-value?dateFrom=${startDate}&dateTo=${endDate}&attribute=${dutySpendFilter}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise2 = axios.get(
        `${ENDPOINT}/dashboard-visibility/dashboard-duty-spend?dateFrom=${startDate}&dateTo=${endDate}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise3 = axios.get(
        `${ENDPOINT}/dashboard-visibility/import-metrics?dateFrom=${startDate}&dateTo=${endDate}&attribute=${dutySpendFilter}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise5 = axios.get(
        `${ENDPOINT}/dashboard-visibility/import-export-data?attribute=${dutySpendFilter}&dateFrom=${startDate}&dateTo=${endDate}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise6 = axios.get(
        `${ENDPOINT}/dashboard-visibility/duty-saving-utilized?attribute=${dutySpendFilter}&dateFrom=${startDate}&dateTo=${endDate}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise7 = axios.get(
        `${ENDPOINT}/dashboard-visibility/vendors?attribute=${dutySpendFilter}&dateFrom=${startDate}&dateTo=${endDate}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise8 = axios.get(`${ENDPOINT}/data/last-update`, {
        headers: {
          authorization: `Bearer ${userContext?.user?.token}`,
        },
      });
      const [
        result1,
        result2,
        result3,
        result5,
        result6,
        result7,
        result8,
      ]: any = await Promise.allSettled([
        promise1,
        promise2,
        promise3,
        promise5,
        promise6,
        promise7,
        promise8,
      ]);

      setOverAllImport(result1?.value?.data);
      setOverallDuties(result2?.value?.data);
      setImportMetrics(result3?.value?.data);
      setEnteredValue(result5?.value?.data);
      setDutySavings(result6?.value?.data);
      setVendors(result7?.value?.data);
      setLastUpdatedDate(result8?.value?.data?.lastUpdatedAt);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleFilter = (value: IKeyValue) => {
    setDutySpendFilter(value?.dutySpend);
  };
  const handleFilterReset = () => {
    setDutySpendFilter("");
    setDates(null);
    setFilterLabel("Today");
  };
  const handleDateFilter = (value: IKeyValue) => {
    setDates(value);
  };
  const handleFilterLabel = (value: string) => {
    setFilterLabel(value);
  };

  return (
    <>
      <FilterHeader
        onDutySpendChange={handleFilter}
        dutySpendFilter={dutySpendFilter}
        handleDateFilter={handleDateFilter}
        handleFilterReset={handleFilterReset}
        handleFilterLabel={handleFilterLabel}
        headingTxt={"Analytics"}
        subHeadingTxt={lastUpdatedDate}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
            height: "74vh",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Grid container spacing={4} mb={3.5}>
            <Grid item xs={12} sm={4}>
              <AllImport data={overallImport} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AllDuties data={overallDuties} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Metrics data={importMetrics} />
            </Grid>
          </Grid>
          <Grid container spacing={4} mb={3.5}>
            <Grid item xs={12} sm={6}>
              <DutySavings data={dutySavings} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Vendors data={vendors} />
            </Grid>
          </Grid>
          <Grid container spacing={4} mb={2}>
            <Grid item xs={12}>
              <TariffCodes
                data={tariffCodes}
                setPage={setPage}
                loading={tariffCodesLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <EnteredValue data={enteredValue} totalData={importMetrics} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Analytics;
