import { Card, Divider, Typography } from "@mui/material";
const TrafficCodes = () => {
  
  return (
    <Card sx={{ px: 3, pt: 4, pb: 10 }}>
      <Typography color="#000" fontSize={"1.2rem"} fontWeight={500} mb={2}>
        Tariff Codes
      </Typography>

      <div
        className="stack-chart-container"
        style={{ position: "relative", zIndex: 1 }}
      >
        <div
          style={{
            width: "100%",
            height: 50,
            display: "flex",
            flexDirection: "column",
            marginBottom: "1rem",
            gap: "7px",
          }}
        >
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 600,
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              7117199000
            </Typography>
            <Divider
              sx={{ width: "1px", height: "13px", background: "#E6E7E7" }}
            />
            <div style={{ display: "flex", gap: 3 }}>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 600,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                5.4 M{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#8E9494",
                  fontWeight: 500,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                (10.34%)
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              height: "40%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "10%",
                height: "100%",
                padding: "4px 4px",
                borderRadius: "4px 0px 0px 4px",
                background: "#33DAE0",
              }}
            ></div>
            <div
              style={{
                width: "80%",
                height: "100%",
                background: "#0B494B",
                borderRadius: "0px 4px 4px 0px",
                padding: "4px 4px",
              }}
            ></div>
            <Typography
              sx={{
                color: "#0B494B",
                marginLeft: "1rem",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              51 M
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: 50,
            display: "flex",
            flexDirection: "column",
            marginBottom: "1rem",
            gap: "7px",
          }}
        >
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 600,
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              6204628011
            </Typography>
            <Divider
              sx={{
                width: "1px",
                height: "13px",
                background: "#E6E7E7",
              }}
            />
            <div style={{ display: "flex", gap: 3 }}>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 600,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                3.6 M{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#8E9494",
                  fontWeight: 500,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                (8.23%)
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              height: "40%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "6%",
                height: "100%",
                padding: "4px 4px",
                borderRadius: "4px 0px 0px 4px",
                background: "#33DAE0",
              }}
            ></div>
            <div
              style={{
                width: "50%",
                height: "100%",
                background: "#0B494B",
                borderRadius: "0px 4px 4px 0px",
                padding: "4px 4px",
              }}
            ></div>

            <Typography
              sx={{
                color: "#0B494B",
                marginLeft: "1rem",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              30 M
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: 50,
            display: "flex",
            flexDirection: "column",
            marginBottom: "1rem",
            gap: "7px",
          }}
        >
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 600,
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              7117199000
            </Typography>
            <Divider
              sx={{ width: "1px", height: "13px", background: "#E6E7E7" }}
            />
            <div style={{ display: "flex", gap: 3 }}>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 600,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                1.87 M{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#8E9494",
                  fontWeight: 500,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                (6.87%)
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              height: "40%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "4%",
                height: "100%",
                padding: "4px 4px",
                borderRadius: "4px 0px 0px 4px",
                background: "#33DAE0",
              }}
            ></div>
            <div
              style={{
                width: "38%",
                height: "100%",
                background: "#0B494B",
                borderRadius: "0px 4px 4px 0px",
                padding: "4px 4px",
              }}
            ></div>
            <Typography
              sx={{
                color: "#0B494B",
                marginLeft: "1rem",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              12 M
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: 50,
            display: "flex",
            flexDirection: "column",
            marginBottom: "1rem",
            gap: "7px",
          }}
        >
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 600,
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              6402993165
            </Typography>
            <Divider
              sx={{ width: "1px", height: "13px", background: "#E6E7E7" }}
            />
            <div style={{ display: "flex", gap: 3 }}>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 600,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                1.67 M{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#8E9494",
                  fontWeight: 500,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                (5.23%)
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              height: "40%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2%",
                height: "100%",
                padding: "4px 4px",
                borderRadius: "4px 0px 0px 4px",
                background: "#33DAE0",
              }}
            ></div>
            <div
              style={{
                width: "39%",
                height: "100%",
                background: "#0B494B",
                borderRadius: "0px 4px 4px 0px",
                padding: "4px 4px",
              }}
            ></div>
            <Typography
              sx={{
                color: "#0B494B",
                marginLeft: "1rem",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              11 M
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: 50,
            display: "flex",
            flexDirection: "column",
            marginBottom: "1rem",
            gap: "7px",
          }}
        >
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 600,
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              6405209060
            </Typography>
            <Divider
              sx={{ width: "1px", height: "13px", background: "#E6E7E7" }}
            />
            <div style={{ display: "flex", gap: 3 }}>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 600,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                1.23 M{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#8E9494",
                  fontWeight: 500,
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                (3.87%)
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              height: "40%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "1%",
                height: "100%",
                padding: "4px 4px",
                borderRadius: "4px 0px 0px 4px",
                background: "#33DAE0",
              }}
            ></div>
            <div
              style={{
                width: "37%",
                height: "100%",
                background: "#0B494B",
                borderRadius: "0px 4px 4px 0px",
                padding: "4px 4px",
              }}
            ></div>
            <Typography
              sx={{
                color: "#0B494B",
                marginLeft: "1rem",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              10 M
            </Typography>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            top: "3.25rem",
            zIndex: -1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "347px",
                height: "300px",
                borderLeft: "2px dashed rgba(216, 218, 229, .5)",
              }}
            ></div>
            <Typography
              sx={{
                color: "#8E9494",
                fontSize: 12,
                marginTop: 1,
                marginLeft: "-10px",
              }}
            >
              0M
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "347px",
                height: "300px",
                borderLeft: "2px dashed rgba(216, 218, 229, .5)",
              }}
            ></div>
            <Typography
              sx={{
                color: "#8E9494",
                fontSize: 12,
                marginTop: 1,
                marginLeft: "-10px",
              }}
            >
              5M
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "347px",
                height: "300px",
                borderLeft: "2px dashed rgba(216, 218, 229, .5)",
              }}
            ></div>
            <Typography
              sx={{
                color: "#8E9494",
                fontSize: 12,
                marginTop: 1,
                marginLeft: "-10px",
              }}
            >
              15M
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "347px",
                height: "300px",
                borderLeft: "2px dashed rgba(216, 218, 229, .5)",
              }}
            ></div>
            <Typography
              sx={{
                color: "#8E9494",
                fontSize: 12,
                marginTop: 1,
                marginLeft: "-10px",
              }}
            >
              40M
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "347px",
                height: "300px",
                borderLeft: "2px dashed rgba(216, 218, 229, .5)",
              }}
            ></div>
            <Typography
              sx={{
                color: "#8E9494",
                fontSize: 12,
                marginTop: 1,
                marginLeft: "-10px",
              }}
            >
              50M
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default TrafficCodes;
