import { Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ReactApexChart from "react-apexcharts";

const SimpleChart = () => {
  const chartData: any = {
    series: [
      {
        data: [100, 90, 80, 70, 60],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 6,
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      legend: { show: false },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -20,
        position: "bottom",
        style: {
          fontSize: "16px",
          colors: ["#000"],
        },
      },
      xaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderColor: '#fff',
            borderWidth: 1,
            opacity: 1
          }
        ],
        xaxis: [
          {
            x: 0,
            strokeDashArray: 0,
            borderColor: '#fff',
            borderWidth: 1,
            opacity: 1
          }
        ]
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "horizontal",
          colorStops: [
            {
              offset: 10,
              color: "#0B494B",
              opacity: 0.9,
            },
            {
              offset: 100,
              color: "#0076BD",
              opacity: 0.3,
            },
          ],
        },
      },
    },
  };

  return (
    <>
      <Typography
        color={"##4E535A"}
        fontSize={26}
        fontWeight={500}
        width={"100%"}
        pl={2}
        display={"flex"}
        alignItems={"center"}
        gap={"10px"}
      >
        Traffic Codes by Value <InfoIcon sx={{ color: "#4E535A" }} />
      </Typography>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
          width={"100%"}
        />
    </>
  );
};

export default SimpleChart;
