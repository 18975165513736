import React from "react";

import { Box, Grid, Stack, Typography } from "@mui/material";
import AuthBg from "../assets/images/background-login.jpg";

const AuthContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid item xs={12} sm={12} md={5}>
        {children}
      </Grid>
      <Grid
        item
        xs={0}
        sm={0}
        md={7}
        sx={{ display: { md: "block", xs: "none" } }}
      >
        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          height={"100%"}
        >
          <Box
            sx={{
              backgroundImage: `url(${AuthBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center center",
              width: "100%",
              flex: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="h1" color="#F4F4F4" fontWeight={700} fontSize={'60px'} pt={8} pl={10}>
              Welcome to <br /> Tradedive
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AuthContainer;
