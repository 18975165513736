import React from "react";
import { Stack } from "@mui/material";
import { UploaderProps } from "../../@types/Upload";
import { FileUploader } from "../../components";

const Uploader: React.FC<UploaderProps> = ({
  files,
  ext,
  updateFiles,
  fileDescriptions,
}) => {
  return (
    <Stack spacing={6}>
      {fileDescriptions.map((fileDescription) => (
        <FileUploader
          label={fileDescription.name}
          id="fileUpload"
          accept={ext.join(", ")}
          selectedFile={files[fileDescription.id]}
          handleFileChange={(event: any) => {
            updateFiles({ [fileDescription.id]: event.target.files[0] });
          }}
        />
      ))}
    </Stack>
  );
};

export default Uploader;

{
  /* <Grid container item direction="row">
        <Grid item xs={5}>
          <Typography>Main File</Typography>
        </Grid>
        <Grid item xs={6}>
          <input
            id="fileUpload"
            type="file"
            accept={FILE_EXTENSIONS}
            onChange={(event: any) =>
              updateFiles({ file1: event.target.files[0] })
            }
          />
        </Grid>
      </Grid>
      <Grid container item direction="row">
        <Grid item xs={5}>
          <Typography>Header File</Typography>
        </Grid>
        <Grid item xs={6}>
          <input
            id="fileUpload"
            type="file"
            accept={FILE_EXTENSIONS}
            onChange={(event: any) =>
              updateFiles({ file2: event.target.files[0] })
            }
          />
        </Grid>
      </Grid>
      <Grid container item direction="row">
        <Grid item xs={5}>
          <Typography>File 3</Typography>
        </Grid>
        <Grid item xs={6}>
          <input
            id="fileUpload"
            type="file"
            accept={FILE_EXTENSIONS}
            onChange={(event: any) =>
              updateFiles({ file3: event.target.files[0] })
            }
          />
        </Grid>
      </Grid>
      <Grid container item direction="row">
        <Grid item xs={5}>
          <Typography>MPF Table File</Typography>
        </Grid>
        <Grid item xs={6}>
          <input
            id="fileUpload"
            type="file"
            accept={FILE_EXTENSIONS}
            onChange={(event: any) =>
              updateFiles({ file4: event.target.files[0] })
            }
          />
        </Grid>
      </Grid> */
}
