import { createTheme } from "@mui/material/styles";
import customBlack from "./color/customBlack";
import { grey } from "@mui/material/colors";

export const MobileView = "(max-width:1024px)";
export const createMaterialTheme = (color = customBlack[500]) => {
  return createTheme({
    palette: {
      primary: {
        main: "#0B494B",
        light: "#fff",
        dark: color,
        contrastText: "#fff",
      },
      secondary: {
        main: "#0B494B",
        light: grey[600],
        contrastText: "#fff",
      },
      captionText: {
        color: customBlack[400],
      },
      errorBar: {
        border: "#FF4D4E",
        color: "#FF4D4E",
      },
      border: {
        color: grey[100],
      },
      common: {
        white: "white",
      },
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '5px', 
            boxShadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.13)',
            padding: '40px'
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
            border: "1px solid #F4F4F4",
            background: "#FFF",
            boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.05)",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: "50px",
            color: "#0B494B",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 500,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '5px',
            boxShadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.13);',
            marginTop: 8
          },
        },
      },
    },
    snackBar: {
      color: "#FFFFFF",
      error: {
        background: "#FF4D4E",
      },
    },
    primaryButton: {
      color: "white",
      borderRadius: 20,
    },
    typography: {
      fontFamily: "'Inter'",
      allVariants: {
        color: customBlack[500],
      },
      h2: {
        fontWeight: 700,
        fontSize: 32,
      },
      h5: {
        fontWeight: 700,
        fontSize: 18,
      },
      subtitle1: {
        fontSize: 18,
        fontWeight: 600,
      },
      h3: {
        fontSize: 24,
        fontWeight: 600,
      },
      h4: {
        fontWeight: 700,
      },
    },
    links: {
      textDecoration: "none",
      color: color,
    },
    bg: {
      main: "#fff",
      light: "#F4F5F7",
    },
    custom: {
      white: "#FFFFFF",
      lightgrey: "#969696",
      whiteGrey: "#EFEFEF",
      blueGrey: "#353c53",
      grey: "#DDDDDD",
      darkGrey: "#F4F4F4",
      blue: "#385ab5",
      bgGrey: "#F6F9FE",
      inactiveGrey: "#808080",
      red: "#f44336",
      lightGreen: "#7bdcb5",
      vividGreen: "#00d084",
    },
  });
};
