import React, { useState, useContext } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ENDPOINT } from "../../config/config";
import UserContext from "../../context/user/UserContext";
import { UserContextType } from "../../@types/UserContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  LinearProgress,
  DialogActions,
  Button,
  Stack,
  Tooltip,
} from "@mui/material";
import { ErrorLoadingType } from "../../utils/Error";
import { DeleteUserProps } from "../../@types/User";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import { Modal } from "../../components";
import TrashIcon from "../../assets/images/trash.png";

const UserDelete: React.FC<DeleteUserProps> = ({
  userId,
  username,
  fetchUsers,
}) => {
  const userContext = useContext(UserContext) as UserContextType;
  const [openDialog, setOpenDialog] = useState(false);
  const [errorLoading, setErrorLoading] = useState<ErrorLoadingType>({
    isError: false,
    isLoading: false,
    errorMsg: null,
  });
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;

  const handleClose = () => setOpenDialog(false);

  const handleOpen = () => setOpenDialog(true);

  const handleDelete = async () => {
    try {
      setErrorLoading({ isLoading: true, isError: false });
      await axios.delete(`${ENDPOINT}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${userContext.user.token}`,
        },
      });
      setErrorLoading({ isLoading: false, isError: false });
      setOpenDialog(false);
      fetchUsers(0);
    } catch (err) {
      setErrorLoading({
        isLoading: false,
        isError: true,
        errorMsg: "Error while deleting user",
      });
      updateSnackBar({
        show: true,
        variant: "error",
        message: "Error while deleting user",
      });
    }
  };

  return (
    <>
      <Tooltip title="Delete User">
        <IconButton onClick={handleOpen} color="warning" size="small">
          <img alt="" width={30} src={TrashIcon} />
        </IconButton>
      </Tooltip>
      <Modal
        open={openDialog}
        handleClose={handleClose}
        title="Delete User"
        actions={
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <Button
              onClick={handleClose}
              disableElevation
              variant="outlined"
              color="error"
              sx={{
                borderColor: "#4E535A",
                color: "#4E535A",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              disableElevation
              variant="contained"
              color="error"
              sx={{
                background: "#FF4560",
                color: "#fff",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
              disabled={errorLoading.isLoading}
            >
              Delete
            </Button>
          </Stack>
        }
      >
        <>
          <Typography color="#4E535A" fontWeight={400} fontSize={26}>
            Are you sure you want to delete user {username} ?
          </Typography>
        </>
      </Modal>
    </>
  );
};

export default UserDelete;
