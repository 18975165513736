import { Button, ListItem, Stack, Typography } from "@mui/material";
import LogoutImage from "../../assets/images/logout.png";
import { useContext, useState } from "react";
import UserContext from "../../context/user/UserContext";
import { UserContextType } from "../../@types/UserContext";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import { Modal } from "../../components";

export const LogoutItem = ({
  isSidebar,
  collapsed,
}: {
  isSidebar?: boolean;
  collapsed?: boolean;
}): JSX.Element => {
  const [openPopConfirm, setOpenPopConfirm] = useState<boolean>(false);
  const userContext = useContext(UserContext) as UserContextType;
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;

  const handleLogout = async (): Promise<void> => {
    try {
      userContext.logout();
    } catch (error) {
      updateSnackBar({
        show: true,
        variant: "error",
        message: "Error !! unable to logout ....",
      });
    }
  };

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        margin: 0,
        padding: 0,
        py: 1,
      }}
    >
      <Button
        fullWidth
        onClick={() => {
          setOpenPopConfirm(true);
        }}
        sx={{
          color: "#4E535A",
          fontWeight: 500,
          fontSize: isSidebar ? 17 : 14,
          textTransform: "initial",
          py: 1,
          justifyContent: isSidebar
            ? collapsed
              ? "center"
              : "flex-start"
            : "center",
        }}
      >
        <img
          src={LogoutImage}
          alt=""
          width={isSidebar ? (collapsed ? "25px" : "20px") : "16px"}
        />
        {!collapsed && (
          <Typography
            color="#4E535A"
            fontWeight={500}
            fontSize={isSidebar ? 17 : 14}
            px={1}
            pl={1.8}
          >
            Logout
          </Typography>
        )}
      </Button>
      <Modal
        open={openPopConfirm}
        handleClose={() => setOpenPopConfirm(false)}
        title="Logout"
        actions={
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <Button
              onClick={() => setOpenPopConfirm(false)}
              disableElevation
              variant="outlined"
              color="error"
              sx={{
                borderColor: "#4E535A",
                color: "#4E535A",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleLogout()}
              disableElevation
              variant="contained"
              color="success"
              sx={{
                background: "#5EDA81",
                color: "#fff",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
            >
              Yes
            </Button>
          </Stack>
        }
      >
        <>
          <Typography color="#4E535A" fontWeight={400} fontSize={26}>
            Are you sure you want to logout ?
          </Typography>
        </>
      </Modal>
    </ListItem>
  );
};
