import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import InfoIcon from "../../../assets/images/info.svg";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import { numberWithCommas } from "../../../utils/abbreviateNumber";

function createData(
  ManufacturerDetails: IKeyValue,
  customValue: string,
  customDuty: string,
  customValuePercent: string
) {
  return {
    ManufacturerDetails,
    customValue,
    customDuty,
    customValuePercent,
  };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IVendors {
  data: IKeyValue;
}

const Vendors = ({ data }: IVendors) => {
  const rows = data?.data?.map((item: IKeyValue) => {
    return createData(
      { mainText: "CRS Denim Garments Eg..", subText: item?.manufacturer },
      item?.tariff_value?.toFixed(2),
      item?.tariff_duty?.toFixed(2),
      `${(item?.custom_value * 100)?.toFixed(2)}%`
    );
  });

  const totalRowsData: {
    customValue: number;
    customDuty: number;
    customValuePercent?: string;
  } = {
    customValue: data?.data?.reduce((x: number, y: IKeyValue) => {
      return x + y?.tariff_value;
    }, 0),
    customDuty: data?.data?.reduce((x: number, y: IKeyValue) => {
      return x + y?.tariff_duty;
    }, 0),
  };

  totalRowsData.customValuePercent = (
    (totalRowsData?.customDuty / totalRowsData?.customValue) *
    100
  ).toFixed(2);

  return (
    <>
      {!data || !data?.data?.length ? (
        <Card sx={{ pb: 1, height: "500px" }}>
          <Typography
            color="#000"
            fontSize={"1.2rem"}
            fontWeight={500}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 7,
              padding: "1.2rem",
            }}
          >
            Manufacturers <img src={InfoIcon} alt="InfoIcon" />
          </Typography>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            color="#000"
            fontSize={"1rem"}
            fontWeight={500}
          >
            No data to display
          </Typography>
        </Card>
      ) : (
        <Card>
          <Typography
            color="#000"
            fontSize={"1.2rem"}
            fontWeight={500}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 7,
              padding: "1.2rem",
            }}
          >
            Manufacturers <img src={InfoIcon} alt="InfoIcon" />
          </Typography>

          <TableContainer
            className="product-table"
            component={Paper}
            style={{
              maxHeight: 440,
              borderRadius: 0,
              boxShadow: "none",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "6px 16px",
                      fontSize: "14px",
                      width: "25%",
                    }}
                  >
                    Manufacturer Details
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "6px 16px",
                      fontSize: "14px",
                      width: "25%",
                    }}
                    align="right"
                  >
                    Customs Value
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "6px 16px",
                      fontSize: "14px",
                      width: "25%",
                    }}
                    align="right"
                  >
                    Customs Duty
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "6px 16px",
                      fontSize: "14px",
                      width: "25%",
                    }}
                    align="right"
                  >
                    % No. of Entries
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row: IKeyValue) => (
                  <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell component="th" scope="row">
                      {/* <Typography fontWeight={500} color="#000" mb={0} pb={0}>
                    {row.ManufacturerDetails.mainText}
                  </Typography> */}
                      <span
                        style={{
                          // color: "#8E9494",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        {row.ManufacturerDetails.subText}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: 500, color: "#000" }}
                      align="right"
                    >
                      $ {numberWithCommas(~~row.customValue)}
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: 500, color: "#000" }}
                      align="right"
                    >
                      $ {numberWithCommas(~~row.customDuty)}
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: 500, color: "#000" }}
                      align="right"
                    >
                      {row.customValuePercent}
                    </TableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "500",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "-1px",
                      zIndex: "2",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "-1px",
                      zIndex: "2",
                    }}
                    align="right"
                  >
                    $ {numberWithCommas(~~totalRowsData?.customValue)}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "-1px",
                      zIndex: "2",
                    }}
                    align="right"
                  >
                    $ {numberWithCommas(~~totalRowsData?.customDuty)}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "-1px",
                      zIndex: "2",
                    }}
                    align="right"
                  >
                    {totalRowsData?.customValuePercent ?? "0"}%
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </>
  );
};

export default Vendors;
