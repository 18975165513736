import { useContext, useRef, useState } from "react";
import { Badge, Box, Button, IconButton, Typography } from "@mui/material";
import { Avatar, MenuPopover } from "../../components";
import { LogoutItem } from "./logout-item";
import EmptyAvatar from "../../assets/images/empty-avatar.png";
import UserContext from "../../context/user/UserContext";
import { UserContextType } from "../../@types/UserContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { TiArrowSortedDown } from "react-icons/ti";


function AccountPopover(): JSX.Element {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext) as UserContextType;
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  return (
    <Box display={"flex"} gap={1.5}>
      <IconButton
        size="large"
        aria-label="show 17 new notifications"
        color="inherit"
      >
        <Badge badgeContent={5} color="error">
          <NotificationsIcon sx={{ color: "#AEB3B3" }} />
        </Badge>
      </IconButton>
      <Button
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          py: 1,
          textTransform: "initial",
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              position: "absolute",
            },
          }),
        }}
      >
        <Avatar
          url={user?.user?.profilePicURL || EmptyAvatar}
          width={40}
          height={40}
        />
        <Box sx={{ display: "flex",flexDirection:"column",alignItems:'flex-start' }}>
          <Box sx={{ display: "flex",alignItems:"center" }}>
            <Typography color="#000" fontWeight={500} fontSize={14} px={1}>
              {/* {user?.user?.username} */}
              Scott Johnston
            </Typography>
            <TiArrowSortedDown  color="#8F92A1" opacity={.5} />

          </Box>
        <Typography color="#116B6F" fontWeight={500}  fontSize={12} marginLeft={"10px"}  >Super Admin</Typography>
        </Box>
      </Button>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current as Element}
        sx={{ width: 150, px: 1, mt: 0.5 }}
      >
        <LogoutItem />
      </MenuPopover>
    </Box>
  );
}

export default AccountPopover;
