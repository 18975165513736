import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { DashboardHeaderCardProps } from "../@types/Dashboard";
import { numify } from "numify";
import Avatar from "@mui/material/Avatar";
import { CircularProgress, Grid, LinearProgress, Stack } from "@mui/material";

const styles = () => ({
  card: {
    padding: "20px 20px",
    height: 100,
    display: 'flex',
    alignItems: 'center',
  },
  loader: { width: "100%", marginTop: 2 },
});

const HeaderCard: React.FC<DashboardHeaderCardProps> = ({
  title,
  value,
  isAmount,
  isLoading,
  icon,
}) => {
  const classes = styles();

  return (
    <Card sx={classes.card}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Box>
          <Typography color="#4E535A" fontWeight={400} fontSize={16} mb={1}>
            {title}
          </Typography>
          {isLoading ? (
            <Box sx={classes.loader}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Typography color="#4E535A" fontWeight={700} fontSize={36}>
              {isAmount ? "$" : ""}
              {value ? numify(+value) : ""}
            </Typography>
          )}
        </Box>
        <Box component={"img"} src={icon} alt="" width={"65px"} />
      </Stack>
    </Card>
  );
};

export default HeaderCard;
