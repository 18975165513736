import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import React from "react";
import { IKeyValue } from "../../../@types/GlobalMessageContext";

function createData(
  countryOfOrigin: string,
  customValue: string,
  customDuty: string,
  distinctImports: number,
  distinctSuppliers: number,
  effectiveDutyRate: string,
  avgShipmentRate: string
) {
  return {
    countryOfOrigin,
    customValue,
    customDuty,
    distinctImports,
    distinctSuppliers,
    effectiveDutyRate,
    avgShipmentRate,
    history: [
      {
        countryOfOrigin: "China",
        customValue: "214,034,447.00",
        customDuty: "214,034,447.00",
        distinctImports: 4805,
        distinctSuppliers: 4805,
        effectiveDutyRate: "14.71%",
        avgShipmentRate: "44,544.11",
        subRowsData: [
          {
            countryOfOrigin: "Pakistan",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
          {
            countryOfOrigin: "India",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
        ],
      },
      {
        countryOfOrigin: "France",
        customValue: "214,034,447.00",
        customDuty: "214,034,447.00",
        distinctImports: 4805,
        distinctSuppliers: 4805,
        effectiveDutyRate: "14.71%",
        avgShipmentRate: "44,544.11",
        subRowsData: [
          {
            countryOfOrigin: "America",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
          {
            countryOfOrigin: "Italy",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
        ],
      },
    ],
  };
}

const Row = (props: { row: ReturnType<typeof createData> }) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <CiSquareMinus fontSize={25} color="#E1E1E1" />
            ) : (
              <CiSquarePlus fontSize={25} color="#E1E1E1" />
            )}
          </IconButton>

          {row.countryOfOrigin}
        </TableCell>
        <TableCell align="right">{row.customValue}</TableCell>
        <TableCell align="right">{row.customDuty}</TableCell>
        <TableCell align="right">{row.distinctImports}</TableCell>
        <TableCell align="right">{row.distinctSuppliers}</TableCell>
        <TableCell align="right">{row.effectiveDutyRate}</TableCell>
        <TableCell align="right">{row.avgShipmentRate}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse
            in={open}
            key={Math.floor(1000 + Math.random() * 9000)}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ margin: 1 }}>
              <Table aria-label="purchases">
                <TableBody>
                  {row.history.map((historyRow: IKeyValue, ind: number) => (
                    <NestedRows nestedRow={historyRow} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const NestedRows = ({ nestedRow }: IKeyValue) => {
  const [subRowOpen, setSubRowOpen] = React.useState(false);

  return (
    <>
      <TableRow key={nestedRow.countryOfOrigin}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setSubRowOpen(!subRowOpen)}
          >
            {subRowOpen ? (
              <CiSquareMinus fontSize={25} color="#E1E1E1" />
            ) : (
              <CiSquarePlus fontSize={25} color="#E1E1E1" />
            )}
          </IconButton>

          {nestedRow.countryOfOrigin}
        </TableCell>
        <TableCell align="right">{nestedRow.customValue}</TableCell>
        <TableCell align="right">{nestedRow.customDuty}</TableCell>
        <TableCell align="right">{nestedRow.distinctImports}</TableCell>
        <TableCell align="right">{nestedRow.distinctSuppliers}</TableCell>
        <TableCell align="right">{nestedRow.effectiveDutyRate}</TableCell>
        <TableCell align="right">{nestedRow.avgShipmentRate}</TableCell>
      </TableRow>
      <SubNestedRow data={nestedRow} open={subRowOpen} />
    </>
  );
};

const SubNestedRow = ({ data, open }: { data: IKeyValue; open: boolean }) => {
  return (
    <>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            in={open}
            key={Math.floor(1000 + Math.random() * 8000)}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <Table>
                <TableBody>
                  {data?.subRowsData.map(
                    (subRows: IKeyValue, index: number) => {
                      return (
                        <TableRow key={index * 6427}>
                          <TableCell>{subRows.countryOfOrigin}</TableCell>
                          <TableCell align="right">
                            {subRows.customValue}
                          </TableCell>
                          <TableCell align="right">
                            {subRows.customDuty}
                          </TableCell>
                          <TableCell align="right">
                            {subRows.distinctImports}
                          </TableCell>
                          <TableCell align="right">
                            {subRows.distinctSuppliers}
                          </TableCell>
                          <TableCell align="right">
                            {subRows.effectiveDutyRate}
                          </TableCell>
                          <TableCell align="right">
                            {subRows.avgShipmentRate}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ProductTable = () => {
  const rows = [
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
    createData(
      "China",
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11"
    ),
  ];

  return (
    <TableContainer
      className="product-table"
      component={Paper}
      style={{
        marginBottom: "3rem",
        maxHeight: 440,
        borderRadius: "10px 10px 2rem 2rem",
      }}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ background: "#F6F6F8" }}>
              Country of origin
            </TableCell>
            <TableCell sx={{ background: "#F6F6F8" }} align="right">
              Custom Value
            </TableCell>
            <TableCell sx={{ background: "#F6F6F8" }} align="right">
              Custom Duty
            </TableCell>
            <TableCell sx={{ background: "#F6F6F8" }} align="right">
              Distinct Imports
            </TableCell>
            <TableCell sx={{ background: "#F6F6F8" }} align="right">
              Distinct Suppliers
            </TableCell>
            <TableCell sx={{ background: "#F6F6F8" }} align="right">
              Effective Duty Rate
            </TableCell>
            <TableCell sx={{ background: "#F6F6F8" }} align="right">
              Avg Shipment Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.countryOfOrigin} row={row} />
          ))}
          <TableRow>
            <TableCell
              sx={{
                border: "none",
                background: "#0B494B",
                borderBottom:"none",
                fontWeight: "bold",
                fontSize: "1rem",
                position:"sticky",
                bottom:"0",
                zIndex:"2"
              }}
            >
              Total
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                background: "#0B494B",
                borderBottom:"none",
                fontWeight: "bold",
                fontSize: "1rem",
                position:"sticky",
                bottom:"0",
                zIndex:"2"
              }}
              align="right"
            >
              385,401,272.00
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                background: "#0B494B",
                borderBottom:"none",
                fontWeight: "bold",
                fontSize: "1rem",
                position:"sticky",
                bottom:"0",
                zIndex:"2"
              }}
              align="right"
            >
              51,774,674.82
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                background: "#0B494B",
                borderBottom:"none",
                fontWeight: "bold",
                fontSize: "1rem",
                position:"sticky",
                bottom:"0",
                zIndex:"2"
              }}
              align="right"
            >
              7220
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                background: "#0B494B",
                borderBottom:"none",
                fontWeight: "bold",
                fontSize: "1rem",
                position:"sticky",
                bottom:"0",
                zIndex:"2"
              }}
              align="right"
            >
              2136
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                background: "#0B494B",
                borderBottom:"none",
                fontWeight: "bold",
                fontSize: "1rem",
                position:"sticky",
                bottom:"0",
                zIndex:"2"
              }}
              align="right"
            >
              13.43%
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                background: "#0B494B",
                borderBottom:"none",
                fontWeight: "bold",
                fontSize: "1rem",
                position:"sticky",
                bottom:"0",
                zIndex:"2"
              }}
              align="right"
            >
              53,379.68
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
