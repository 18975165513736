import { useState, useContext, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Typography,
  Stack,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/user/UserContext";
import AuthContainer from "../../main/AuthContainer";
import { UserContextType } from "../../@types/UserContext";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import { ENDPOINT } from "../../config/config";
import axios from "axios";
import { ErrorLoadingType } from "../../utils/Error";
import { useForm } from "react-hook-form";
import { FormInputText } from "../form/FormInputText";
import Logo from "../../assets/images/logos/todoImg0.png";
import { Checkbox } from "../../components";
import { FaArrowRightLong } from "react-icons/fa6";
import GlobalSnackBarMessage from "../../common/GlobalSnackBarMessage";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

type LoginInput = {
  username: string;
  password: string;
};

const defaultLoginValues = {
  username: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [remember, setRemember] = useState<boolean>(false);
  const userContext = useContext(UserContext) as UserContextType;
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;
  const [errorLoading, setErrorLoading] = useState<ErrorLoadingType>({
    isError: false,
    isLoading: false,
    errorMsg: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm<LoginInput>({ defaultValues: defaultLoginValues });
  const { handleSubmit, control, setError } = methods;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = (data: LoginInput) => {
    if (!data.username) {
      setError("username", { message: "Username is required" });
      return;
    }
    if (!data.password) {
      setError("password", { message: "Password is required" });
      return;
    }
    login(data);
  };

  const login = async (data: LoginInput) => {
    try {
      setErrorLoading({ isLoading: true, isError: false });
      const res = await axios.post(`${ENDPOINT}/login`, {
        username: data.username,
        password: data.password,
      });
      userContext.updateUser({
        token: res?.data?.access_token,
        user: res?.data?.user,
      });

      setErrorLoading({ isLoading: false, isError: false });
      navigate("/");
    } catch (err: any) {
      setErrorLoading({
        isLoading: false,
        isError: true,
        errorMsg: err?.response?.data?.error,
      });
    }
  };
  useEffect(() => {
    if (errorLoading.isError) {
      updateSnackBar({
        show: true,
        variant: "error",
        message:
          errorLoading.errorMsg ??
          "Something went wrong. Please check your credentials and try again.",
      });
    }
  }, [errorLoading.isError]);
  return (
    <AuthContainer
      children={
        <Stack height={"100%"} overflow={"auto"} px={{ xs: 3, sm: 6 }}>
          <GlobalSnackBarMessage />
          <Stack
            direction={"column"}
            justifyContent={"center"}
            sx={{
              position: "relative",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: { position: "absolute", top: 40, left: 0 },
              }}
            >
              <img src={Logo} alt="" width="190px" />
            </Box>
            <Box sx={{ width: "100%", mx: "auto", mt: 5 }}>
              <Box component="form" noValidate autoComplete="off">
                <Typography variant="h3" fontWeight={500} color="#4E535A">
                  Login to system
                </Typography>
                <Typography variant="body1" fontSize={"14px"} mt={1}>
                  This is a secure system. Please enter your login information
                  to get in.
                </Typography>
                <Box sx={{ mt: 8, mb: 3 }}>
                  <Stack spacing={3}>
                    <Box>
                      <FormInputText
                        variant="standard"
                        name="username"
                        control={control}
                        label="Username"
                        isRequired={true}
                        type="text"
                        size="medium"
                        color="secondary"
                      />
                    </Box>
                    <Box sx={{ pt: 2 }}>
                      <FormInputText
                        variant="standard"
                        name="password"
                        control={control}
                        label="Password"
                        isRequired={true}
                        type={showPassword ? "text" : "password"}
                        size="medium"
                        color="secondary"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={4}
                  >
                    <Checkbox
                      label="Remember Me"
                      color="secondary"
                      checked={remember}
                      onChange={() => setRemember(!remember)}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "36px",
                        width: "226px",
                        fontWeight: 700,
                        fontSize: 14,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        pt: 1.8,
                        pb: 1.5,
                        px: 4,
                        mt: { xs: 2, sm: 0 },
                      }}
                      color="secondary"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      disableElevation
                      disabled={errorLoading.isLoading}
                    >
                      {errorLoading.isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <>
                          {" "}
                          Login <FaArrowRightLong size={20} />
                        </>
                      )}
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Stack>
      }
    />
  );
};

export default Login;
