import { Grid, Typography } from "@mui/material";
import ProductTable from "./ProductTable";
import { useState } from "react";
import DashboardFilters from "../../../components/filters";
import DashboardSubFilters from "../../../components/filters/SubFilters";
import DashboardHeader from "../../../components/header";
import DashboardDonutChart from "../../../components/donut";
import { SimpleChart, StripeColorChart } from "../../../components/bar-charts";

const Potential = () => {
  const [state, setState] = useState(false);
  const handleSubFiltersDisplay = () => {
    setState(!state);
  };
  return (
    <>
      <Grid container spacing={5} sx={{ mb: 5 }} alignItems={"center"}>
        <Grid item xs={12} md={4}>
          <Typography
            color="#4E535A"
            fontWeight={500}
            fontSize={26}
            mb={{ xs: 2, md: 3 }}
          >
            First Sale YO
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <DashboardFilters handler={handleSubFiltersDisplay} />
        </Grid>
      </Grid>
      {state ? <DashboardSubFilters /> : ""}
      <DashboardHeader />
      <Grid container spacing={5} marginBottom={"2rem"}>
        <Grid item xs={12} md={6} width="100%">
          <DashboardDonutChart title="Import Metrices" />
        </Grid>
        <Grid item xs={12} md={6} width="100%">
          <DashboardDonutChart title="Product Metrices" />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          <SimpleChart />
          <SimpleChart />
        </Grid>
        <Grid item xs={12} md={4}>
          <StripeColorChart />
        </Grid>
      </Grid>
      <ProductTable />
    </>
  );
};

export default Potential;
