import { Box, Button, Card, Typography } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import ReactApexChart from "react-apexcharts";
import LeftArrowIcon from "../../../assets/images/leftArrow.svg";
import RightArrowIcon from "../../../assets/images/rightArrow.svg";
import { IKeyValue } from "../../../@types/GlobalMessageContext";

const DutySavings = () => {
  const state: any = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // This disables the toolbar entirely
      },
    },
    colors: ["#0B494B"], // Red for Series 1, Blue for Series 2
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 4,
        borderRadiusApplication: "end", // 'around', 'end'
        borderRadiusWhenStacked: "last", // 'all', 'last'
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        ["2018", "Q1"],
        ["2018", "Q2"],
        ["2018", "Q3"],
        ["2018", "Q4"],
        ["2019", "Q1"],
        ["2019", "Q2"],
        ["2019", "Q3"],
      ],
      type: "category",
      labels: {
        style: {
          fontSize: "14px",
          colors: "#8E9494",
          fontFamily: "Inter",
        },
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "transparent",
        width: "0",
        dashArray: 5,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val + "M";
        },
        style: {
          fontSize: "14px",
          colors: "#8E9494",
          fontFamily: "Inter",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val + " units";
        },
      },
    },
    grid: {
      borderColor: "#D8DAE5",
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  const series = [
    {
      name: "Quarterly Data",
      data: [50, 75, 30, 20, 100, 75, 85],
    },
  ];

  return (
    <Card sx={{ px: 3, pt: 2, pb: 1, height: "485px" }}>
      <Typography
        color="#000"
        fontSize={"1.2rem"}
        fontWeight={500}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 7,
        }}
      >
        Duty Savings Utilized
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          marginBottom: "1rem",
        }}
      >
        <Typography
          color="#000"
          fontFamily={"Plus Jakarta Sans"}
          fontSize={"2rem"}
          fontWeight={700}
        >
          $9,024,093
          <span
            style={{
              fontSize: "1.2rem",
              color: "#8E9494",
              fontFamily: "Plus Jakarta Sans",
            }}
          >
            .23
          </span>
        </Typography>
        <Button
          variant="text"
          sx={{
            borderRadius: "6px",
            border: "1px solid #E6E7E7 ",
            textTransform: "none",
            mt: 3,
            py: "10px",
            pl: "1.5rem",
            pr: "14px",
            gap: "7px",
          }}
        >
          View Utilization Details <MdKeyboardArrowRight size={20} />
        </Button>
      </Box>
      <Box position={"relative"}>
        <ReactApexChart
          options={state}
          series={series}
          type="bar"
          height={350}
        />{" "}
        <img
          style={{
            position: "absolute",
            cursor: "pointer",
            bottom: "2.2rem",
            left: "2.5rem",
          }}
          src={LeftArrowIcon}
          alt="LeftArrowIcon"
        />
        <img
          style={{
            position: "absolute",
            cursor: "pointer",
            bottom: "2.2rem",
            right: 0,
          }}
          src={RightArrowIcon}
          alt="RightArrowIcon"
        />
      </Box>
    </Card>
  );
};

export default DutySavings;
