import ChinaIcon from "../../assets/images/china.svg";
import BanglaIcon from "../../assets/images/bangla.svg";

export const dutySpendData = [
  {
    label: "MTB",
    value: "mtb",
  },
  {
    label: "301",
    value: "sec_301",
  },
  {
    label: "Exclusions",
    value: "exclusions",
  },
];
export const dateRangeData = [
  // {
  //     label:"Duty Sum",
  //     value:"dutySum"
  // },
  {
    label: "MFN",
    value: "mfn",
  },
  // {
  //     label:"MTB",
  //     value:"mtb"
  // },
  // {
  //     label:"301",
  //     value:"301"
  // },
  // {
  //     label:"Exclusive",
  //     value:"exclusive"
  // }
];
export const importerNo = [
  {
    label: "75-300195600",
    value: "75-300195600",
  },
];
export const classification = [
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
];
export const entryNo = [
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
  {
    label: "6,092,982.00",
    value: "6,092,982.00",
  },
];
export const tradeFlow = [
  {
    label: "AE-CN-US",
    value: "AE-CN-US",
  },
  // {
  //     label:"BD-AE-US",
  //     value:"BD-AE-US"
  // },
  // {
  //     label:"BD-BD-US",
  //     value:"BD-BD-US"
  // },
  // {
  //     label:"BD-CA-US",
  //     value:"BD-CA-US"
  // },
  // {
  //     label:"BD-CN-US",
  //     value:"BD-CN-US"
  // },
  // {
  //     label:"BD-DE-US",
  //     value:"BD-DE-US"
  // },
  // {
  //     label:"BD-JP-US",
  //     value:"BD-JP-US"
  // },
  // {
  //     label:"BD-LK-US",
  //     value:"BD-LK-US"
  // },
];
export const countryOfOrigin = [
  {
    label: "Bangladesh",
    value: "Bangladesh",
    icon: BanglaIcon,
  },
  // {
  //     label:"Bolivia",
  //     value:"Bolivia"
  // },
  // {
  //     label:"Burma",
  //     value:"Burma"
  // },
  // {
  //     label:"Cambodia",
  //     value:"Cambodia"
  // },
  // {
  //     label:"Canada",
  //     value:"Canada"
  // },
  // {
  //     label:"China",
  //     value:"China"
  // },
  // {
  //     label:"Colombia",
  //     value:"Colombia"
  // },
  // {
  //     label:"Egypt",
  //     value:"Egypt"
  // },
];
export const countryOfExpert = [
  // {
  //     label:"Bangladesh",
  //     value:"bangladesh"
  // },
  // {
  //     label:"Bolivia",
  //     value:"bolivia"
  // },
  // {
  //     label:"Burma",
  //     value:"burma"
  // },
  // {
  //     label:"Cambodia",
  //     value:"cambodia"
  // },
  // {
  //     label:"Canada",
  //     value:"canada"
  // },
  {
    label: "China",
    value: "China",
    icon: ChinaIcon,
  },
  // {
  //     label:"Colombia",
  //     value:"colombia"
  // },
  // {
  //     label:"Egypt",
  //     value:"egypt"
  // },
];
