import { Box, Card, Typography } from "@mui/material";
import InfoIcon from "../../../assets/images/info.svg";
import GraphIcon from "../../../assets/images/graph.svg";

const AllDuties = () => {
  return (
    <Card sx={{ px: 3, pt: 2, pb: 3.2,height:"408px" }}>
      <Typography
        color="#8E9494"
        fontSize={"1rem"}
        fontWeight={500}
        style={{ display: "flex", alignItems: "center", gap: 7 }}
      >
        Overall Duties Paid <img src={InfoIcon} alt="InfoIcon" />
      </Typography>
      <Typography
        color="#000"
        fontFamily={"Plus Jakarta Sans"}
        fontSize={"2rem"}
        fontWeight={700}
        mb={1}
      >
        $47.5M
      </Typography>
      <Box display="flex" gap={1.5} marginBottom={3}>
        <Typography
          color="#8E9494"
          fontSize={"13px"}
          fontWeight={500}
          sx={{
            background: "#E8F5E9",
            color: "#388E3C",
            padding: "4px 12px",
            borderRadius: "20px",
          }}
        >
          <img src={GraphIcon} alt="GraphIcon" /> 0.31%
        </Typography>
        <Typography color="#8E9494" fontSize={"1rem"}>
          Last Year
        </Typography>
      </Box>
      <div className="chart-container" style={{ marginBottom: "1rem" }}>
        <div
          style={{
            width: "100%",
            height: 50,
            display: "flex",
          }}
        >
          <div
            style={{
              width: "30%",
              height: "50%",
              background: "#0B494B",
              borderRadius: "4px 0px 0px 4px",
              padding: "4px 4px",
            }}
          ></div>
          <div
            style={{
              width: "45%",
              height: "50%",
              padding: "4px 4px",
              background: "#33DAE0",
            }}
          ></div>
          <div
            style={{
              width: "20%",
              height: "50%",
              padding: "4px 4px",
              background: "#1CB4BA",
            }}
          ></div>
          <div
            style={{
              width: "4%",
              height: "50%",
              padding: "4px 4px",

              background: "#E3E3E8",
              borderRadius: "0px 4px 4px 0px",
            }}
          ></div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "4px",
          }}
        >
          <div style={{ display: "flex", gap: 7, alignItems: "center" }}>
            <div
              style={{ width: "8px", height: "8px", background: "#0B494B" }}
            ></div>
            <Typography color={"#717184"} fontSize={13}>
              MFN
            </Typography>
          </div>
          <Typography fontWeight={550} color={"#000"}>
            $46.5M{" "}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "4px",
          }}
        >
          <div style={{ display: "flex", gap: 7, alignItems: "center" }}>
            <div
              style={{ width: "8px", height: "8px", background: "#1F6532" }}
            ></div>
            <Typography color={"#717184"} fontSize={13}>
              MTB
            </Typography>
          </div>
          <Typography fontWeight={550} color={"#000"}>
            0.0{" "}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "4px",
          }}
        >
          <div style={{ display: "flex", gap: 7, alignItems: "center" }}>
            <div
              style={{ width: "8px", height: "8px", background: "#C6FF29" }}
            ></div>
            <Typography color={"#717184"} fontSize={13}>
              301
            </Typography>
          </div>
          <Typography fontWeight={550} color={"#000"}>
            5.3 M
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "4px",
          }}
        >
          <div style={{ display: "flex", gap: 7, alignItems: "center" }}>
            <div
              style={{ width: "8px", height: "8px", background: "#E3E3E8" }}
            ></div>
            <Typography color={"#717184"} fontSize={13}>
              Others
            </Typography>
          </div>
          <Typography fontWeight={550} color={"#000"}>
            1.2M
          </Typography>
        </div>
      </div>

      <div
        style={{
          width: "97%",
          height: 75,
          background: "rgba(212, 242, 220, .8)",
          borderRadius: "4px",
          paddingLeft: "1rem ",
          paddingTop: "10px",
        }}
      >
        <Typography
          color="#8E9494"
          fontSize={14}
          sx={{ fontFamily: "Manrope" }}
        >
          Total Duty Saved
        </Typography>
        <Typography
          color="#34A853"
          fontSize={"1.5rem"}
          fontWeight={700}
          fontFamily={"Plus Jakarta Sans"}
        >
          $9.03M
        </Typography>
      </div>
    </Card>
  );
};

export default AllDuties;
