import { Box, Button, Card, Grid, Typography } from "@mui/material";
import InfoIcon from "../../../assets/images/info.svg";
import SubstractIcon from "../../../assets/images/Subtract.svg";
import ImportsIcon from "../../../assets/images/imports.svg";
import DutytIcon from "../../../assets/images/duty.svg";
import DistinctIcon from "../../../assets/images/distinct.svg";

const Metrics = () => {
  return (
    <Card sx={{ px: 3, pt: 2, pb: 4, overflow: "scroll", height: "402px" }}>
      <Typography
        color="#000"
        fontSize={"1.2rem"}
        fontWeight={500}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 7,
          marginBottom: "12px",
        }}
      >
        Import Metrics <img src={InfoIcon} alt="InfoIcon" />
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              width: "100%",
              height: "108px",
              background: "#EAEEFB",
              borderRadius: "6px",
              pt: "1rem",
              pl: "1rem",
            }}
          >
            <img
              src={SubstractIcon}
              style={{ width: 18, height: 18 }}
              alt="SubstractIcon"
            />
            <Typography color="#8E9494" fontSize={"1rem"}>
              Avg. Shipment Value
            </Typography>
            <Typography
              color="#000"
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"1.5rem"}
              fontWeight={700}
            >
              $53.8K{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              width: "100%",
              height: "108px",
              background: "#FFE5EC",
              borderRadius: "6px",
              pt: "1rem",
              pl: "1rem",
            }}
          >
            <img
              src={ImportsIcon}
              style={{ width: 18, height: 18 }}
              alt="ImportsIcon"
            />
            <Typography color="#8E9494" fontSize={"1rem"}>
              Distinct Imports{" "}
            </Typography>
            <Typography
              color="#000"
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"1.5rem"}
              fontWeight={700}
            >
              7220{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              width: "100%",
              height: "108px",
              background: "#F5EFFF",
              borderRadius: "6px",
              pt: "1rem",
              pl: "1rem",
            }}
          >
            <img
              src={DutytIcon}
              style={{ width: 18, height: 18 }}
              alt="DutytIcon"
            />
            <Typography color="#8E9494" fontSize={"1rem"}>
              Effective Duty Rate{" "}
            </Typography>
            <Typography
              color="#000"
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"1.5rem"}
              fontWeight={700}
            >
              13.46%
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              width: "100%",
              height: "108px",
              background: "#E8F8EC",
              borderRadius: "6px",
              pt: "1rem",
              pl: "1rem",
            }}
          >
            <img
              src={DistinctIcon}
              style={{ width: 18, height: 18 }}
              alt="DistinctIcon"
            />
            <Typography color="#8E9494" fontSize={"1rem"}>
              Distinct HTS{" "}
            </Typography>
            <Typography
              color="#000"
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"1.5rem"}
              fontWeight={700}
            >
              586
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Button
        variant="text"
        sx={{
          borderRadius: "6px",
          border: "1px solid #E6E7E7 ",
          textTransform: "none",
          width: "100%",
          mt: 3,
          py: "10px",
        }}
      >
        View Details
      </Button>
    </Card>
  );
};

export default Metrics;
