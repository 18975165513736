import { Grid } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import { UserContextType } from "../../../@types/UserContext";
import { ENDPOINT } from "../../../config/config";
import UserContext from "../../../context/user/UserContext";
import AgreementByCountriesTable from "./AgreementByCountriesTable";
import AgreementOpportunitiesTable from "./AgreementOpportunitiesTable";
import SavingPotential from "./SavingPotential";

import TrafficCodes from "../analytics/TariffCodes";
interface IProps {
  dates: any;
  dutySpendFilter: any;
}

const pageSize = 10;

const PreferentialOriginTab = ({ dates, dutySpendFilter }: IProps) => {
  const [savingPotential, setSavingPotential] = useState<any>([]);
  const [savingPotentialLoading, setSavingPotentialLoading] =
    useState<boolean>(false);
  const [programs, setPrograms] = useState<any>([]);
  const [programsLoading, setProgramsLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<any>([]);
  const [countriesLoading, setCountriesLoading] = useState<boolean>(false);
  const [tariffCodes, setTariffCodes] = useState<IKeyValue[]>([]);
  const [tariffCodesLoading, setTariffCodesLoading] = useState<boolean>(false);
  const userContext = useContext(UserContext) as UserContextType;
  const [tariffCodePage, setTariffCodePage] = useState(1);
  const [savingPotentialPage, setSavingPotentialPage] = useState(1);

  useEffect(() => {
    fetchTariffCodesData();
  }, [tariffCodePage, dates]);
  useEffect(() => {
    fetchSavingPotential();
  }, [savingPotentialPage, dates]);
  useEffect(() => {
    fetchPrograms();
    fetchCountries();
  }, [dates]);

  const fetchTariffCodesData = async () => {
    try {
      const startDate = dates?.startDate ?? "";
      const endDate = dates?.endDate ?? "";

      setTariffCodesLoading(true);

      const result = await axios.get(
        `${ENDPOINT}/dashboard-visibility/line-tariff-value-hts?dateFrom=${startDate}&dateTo=${endDate}&attribute=${dutySpendFilter}&pageNo=${tariffCodePage}&pageSize=${pageSize}&minThreshold=50000`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );

      if (!result?.data?.data?.length) {
        setTariffCodesLoading(false);
        return;
      }

      setTariffCodes(result?.data);
      setTariffCodesLoading(false);
    } catch (err) {
      setTariffCodesLoading(false);
    }
  };

  const fetchSavingPotential = async () => {
    try {
      const startDate = dates?.startDate ?? "";
      const endDate = dates?.endDate ?? "";

      setSavingPotentialLoading(true);

      const result = await axios.get(
        `${ENDPOINT}/dashboard-potential/tariff-codes?dateFrom=${startDate}&dateTo=${endDate}&pageNo=${savingPotentialPage}&pageSize=${pageSize}&minThreshold=50000`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );

      if (!result?.data?.data?.length) {
        setSavingPotentialLoading(false);
        return;
      }

      setSavingPotential(result?.data);
      setSavingPotentialLoading(false);
    } catch (err) {
      setSavingPotentialLoading(false);
    }
  };

  const fetchPrograms = async () => {
    try {
      const startDate = dates?.startDate ?? "";
      const endDate = dates?.endDate ?? "";

      setProgramsLoading(true);

      const result = await axios.get(
        `${ENDPOINT}/dashboard-potential/programs?dateFrom=${startDate}&dateTo=${endDate}&pageNo=1&pageSize=50&minThreshold=50000`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );

      if (!result?.data?.data?.length) {
        setProgramsLoading(false);
        return;
      }

      setPrograms(result?.data);
      setProgramsLoading(false);
    } catch (err) {
      setProgramsLoading(false);
    }
  };

  const fetchCountries = async () => {
    try {
      const startDate = dates?.startDate ?? "";
      const endDate = dates?.endDate ?? "";

      setCountriesLoading(true);

      const result = await axios.get(
        `${ENDPOINT}/dashboard-potential/countries?dateFrom=${startDate}&dateTo=${endDate}&pageNo=1&pageSize=50&minThreshold=50000`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );

      if (!result?.data?.data?.length) {
        setCountriesLoading(false);
        return;
      }

      setCountries(result?.data);
      setCountriesLoading(false);
    } catch (err) {
      setCountriesLoading(false);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item sm={6} xs={12}>
        <TrafficCodes
          data={tariffCodes}
          setPage={setTariffCodePage}
          loading={tariffCodesLoading}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <SavingPotential
          data={savingPotential?.data}
          setPage={setSavingPotentialPage}
          loading={savingPotentialLoading}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <AgreementOpportunitiesTable
          headingTxt="Agreement Opportunities"
          data={programs?.data}
          loading={programsLoading}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <AgreementByCountriesTable
          headingTxt="Agreement Opportunities By Country"
          data={countries?.data}
          loading={countriesLoading}
        />
      </Grid>
    </Grid>
  );
};

export default PreferentialOriginTab;
