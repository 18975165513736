import { Box, Card, Typography } from "@mui/material";
import GraphIcon from "../../../assets/images/graph.svg";
import ReactApexChart from "react-apexcharts";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import { abbreviateNumber } from "../../../utils/abbreviateNumber";

const AllImport = ({ data }: IKeyValue) => {
  const numbers = data?.data?.map(
    (el: { total_goods_value: number }) => el?.total_goods_value
  );

  const totalSum = numbers?.reduce(
    (partialSum: any, a: number) => partialSum + a,
    0
  );

  const state: any = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // This disables the toolbar entirely
      },
    },
    colors: ["#0B494B"], // Red for Series 1, Blue for Series 2
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 4,
        borderRadiusApplication: "end", // 'around', 'end'
        borderRadiusWhenStacked: "last", // 'all', 'last'
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories:
        data?.data || data?.data?.length
          ? data?.data?.map((el: { year: string }) => el?.year)
          : [2023, 2022, 2021],
      labels: {
        style: {
          fontSize: "14px",
          colors: "#8E9494",
          fontFamily: "Inter",
        },
      },
      offsetY: 10,
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "transparent",
        width: "0",
        dashArray: 5,
      },
    },
    yaxis: {
      // min: Math.min(numbers), // Ensure the minimum value is zero
      // max: Math.max(numbers), // Maximum should cover the highest data value
      tickAmount: 5, // Explicitly define tick amounts to include zero and maximum
      labels: {
        formatter: function (val: number) {
          return abbreviateNumber(val);
        },
        style: {
          fontSize: "14px",
          colors: "#8E9494",
          fontFamily: "Inter",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val + " units";
        },
      },
    },
  };

  const series = [
    {
      name: "Quarterly Data",
      data:
        data?.data || data?.data?.length
          ? data?.data?.map(
              (el: { total_goods_value: string }) => el?.total_goods_value
            )
          : [100, 75, 50],
    },
  ];

  return (
    <Card sx={{ px: 3, pt: 2, pb: 1, height: "375px" }}>
      {data?.data || data?.data?.length ? (
        <>
          <Typography color="#8E9494" fontSize={"1rem"} fontWeight={500}>
            Overall Import Value
          </Typography>
          <Typography
            color="#000"
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"2rem"}
            fontWeight={700}
          >
            ${abbreviateNumber(totalSum) ?? 0}
          </Typography>
          {/* <Box display="flex" gap={1.5} marginBottom={3}>
  <Typography
    color="#8E9494"
    fontSize={"13px"}
    fontWeight={500}
    sx={{
      background: "#E8F5E9",
      color: "#388E3C",
      padding: "4px 12px",
      borderRadius: "20px",
    }}
  >
    <img src={GraphIcon} alt="GraphIcon" /> 2.3%
  </Typography>
  <Typography color="#8E9494" fontSize={"1rem"}>
    vs Last Year
  </Typography>
</Box> */}
          <ReactApexChart
            options={state}
            series={series}
            type="bar"
            height={288}
          />
        </>
      ) : (
        <>
          <Typography color="#8E9494" fontSize={"1rem"} fontWeight={500}>
            Overall Import Value
          </Typography>
          <Typography
            sx={{ display: "flex", justifyContent: "center",alignItems:"center",height:"100%" }}
            color="#000"
            fontSize={"1rem"}
            fontWeight={500}
          >
            No data to display
          </Typography>
        </>
      )}
    </Card>
  );
};

export default AllImport;
