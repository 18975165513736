import React, { useContext, useState } from "react";
import {
  Button,
  Grid,
  LinearProgress,
  Typography,
  TextField,
  DialogTitle,
  Stack,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { UserContextType } from "../../@types/UserContext";
import UserContext from "../../context/user/UserContext";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import GlobalSnackBarMessage from "../../common/GlobalSnackBarMessage";
import CustomModal from "../../common/CustomDialog";
import { UserAddOrUpdateParams, UserTableHeaderProps } from "../../@types/User";
import { ErrorLoadingType } from "../../utils/Error";
import { ENDPOINT } from "../../config/config";
import axios from "axios";
import SelectRole from "./UserRoleSelect";
import { errorMonitor } from "events";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Modal } from "../../components";

const styles = () => ({
  headerContainer: {
    marginBottom: 1,
  },
  inputField: {
    margin: "12px 0 12px 0",
  },
  dialogHeader: { padding: "0 0 20px 0" },
});

const UserHeader: React.FC<UserTableHeaderProps> = ({ fetchUsers }) => {
  const classes = styles();
  const userContext = useContext(UserContext) as UserContextType;
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<UserAddOrUpdateParams>({
    username: "",
    password: "",
    confirmPassword: "",
    role: "user",
  });
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;
  const [errorLoading, setErrorLoading] = useState<ErrorLoadingType>({
    isError: false,
    isLoading: false,
    errorMsg: null,
  });
  const handleClose = () => setOpen(false);

  const isDisable = () =>
    !user.username || !user.password || !user.confirmPassword;

  const userChangeHandler = (event: any, label: string) => {
    const value = event.target.value;
    if (label === "username") setUser({ ...user, username: value });
    else if (label === "role") setUser({ ...user, role: value });
    else if (label === "confirmPassword")
      setUser({ ...user, confirmPassword: value });
    else setUser({ ...user, password: value });
  };

  const clearUser = () =>
    setUser({
      username: "",
      password: "",
      confirmPassword: "",
      role: "user",
    });
  const addUser = async () => {
    if (user.password === user.confirmPassword) {
      try {
        setErrorLoading({ isLoading: true, isError: false });
        const api = user.role === "admin" ? "admin" : "users";
        await axios.post(
          `${ENDPOINT}/${api}`,
          {
            username: user.username,
            password: user.password,
          },
          {
            headers: {
              authorization: `Bearer ${userContext.user.token}`,
            },
          }
        );
        setErrorLoading({ isLoading: false, isError: false });
        clearUser();
        setOpen(false);
        fetchUsers(0);
      } catch (err: any) {
        setErrorLoading({ isLoading: false, isError: true });
        clearUser();
        updateSnackBar({
          show: true,
          variant: "error",
          message: err?.response?.data?.error ?? "Error while adding user",
        });
      }
    } else {
      updateSnackBar({
        show: true,
        variant: "error",
        message: "Confirm Password Doesn't Match!",
      });
    }
  };
  return (
    <>
      <GlobalSnackBarMessage />
      <Stack
        direction={{ sm: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ mb: 3 }}
      >
        <Typography
          color="#4E535A"
          fontWeight={500}
          fontSize={26}
          mb={{ xs: 2, md: 0 }}
        >
          User Management
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={1}
        >
          <Button
            onClick={() => setOpen(true)}
            color="primary"
            variant="contained"
            size="medium"
            sx={{
              textTransform: "initial",
              borderRadius: "50px",
              p: 1.8,
              minWidth: { xs: "100%", sm: 150 },
              borderColor: "#0B494B",
            }}
            disableElevation
          >
            <AddCircleIcon sx={{ fontSize: 28, pr: 2 }} />
            <Typography fontSize={"16px"} fontWeight={500} color={"#FFF"}>
              Add User
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <Modal
        open={open}
        handleClose={handleClose}
        title="Add User"
        actions={
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <Button
              onClick={() => setOpen(false)}
              disableElevation
              variant="contained"
              color="error"
              sx={{
                background: "#FF4560",
                color: "#fff",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={addUser}
              disabled={isDisable() || errorLoading?.isLoading}
              disableElevation
              variant="contained"
              color="success"
              sx={{
                background: "#5EDA81",
                color: "#fff",
                borderRadius: 36,
                fontWeight: 700,
                fontSize: 15,
                textTransform: "initial",
                width: 120,
                py: 1,
              }}
            >
              Add
            </Button>
          </Stack>
        }
      >
        <>
          <Stack sx={{ mt: 1 }} spacing={4}>
            <SelectRole userChangeHandler={userChangeHandler} />
            <TextField
              required
              fullWidth
              value={user.username}
              label="Username"
              onChange={(e: any) => userChangeHandler(e, "username")}
              placeholder="Username"
              type={"text"}
              variant="standard"
              InputLabelProps={{
                shrink: true,
                sx: { color: "#ACACAC", fontWeight: 500, fontSize: 16 },
              }}
              sx={{
                pt: 1,
                "& label.Mui-focused": {
                  color: "#ACACAC",
                },
                '& input::placeholder': {
                  color: 'rgba(172, 172, 172)',
                },
              }}
            />
            <TextField
              required
              fullWidth
              value={user.password}
              label="Password"
              onChange={(e: any) => userChangeHandler(e, "password")}
              placeholder="Password"
              type={"text"}
              variant="standard"
              InputLabelProps={{
                shrink: true,
                sx: { color: "#ACACAC", fontWeight: 500, fontSize: 16 },
              }}
              sx={{
                pt: 1,
                "& label.Mui-focused": {
                  color: "#ACACAC",
                },
                '& input::placeholder': {
                  color: 'rgba(172, 172, 172)',
                },
              }}
            />
            <TextField
              required
              fullWidth
              value={user.confirmPassword}
              label="Confirm Password"
              onChange={(e: any) => userChangeHandler(e, "confirmPassword")}
              placeholder="Confirm Password"
              type={"text"}
              variant="standard"
              InputLabelProps={{
                shrink: true,
                sx: { color: "#ACACAC", fontWeight: 500, fontSize: 16 },
              }}
              sx={{
                pt: 1,
                "& label.Mui-focused": {
                  color: "#ACACAC",
                },
                '& input::placeholder': {
                  color: 'rgba(172, 172, 172)',
                },
              }}
            />
          </Stack>
        </>
      </Modal>
    </>
  );
};

export default UserHeader;
