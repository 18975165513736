import React, { useState } from "react";
import AppSelect from "../select";
import { dutySpendData } from "./data";
import AppDateRangePicker from "../date-range-picker";
import { Grid, Typography } from "@mui/material";
import { LuFilter } from "react-icons/lu";
import { FaFilter } from "react-icons/fa6";
import { useForm } from "react-hook-form";

interface IProductFilters {
  handler: () => void;
}

type FiltersInput = {
  dutySpend: string;
  dateRange: string;
};

const defaultFilterValues = {
  dutySpend: "",
  dateRange: "",
};

const DashboardFilters = ({ handler }: IProductFilters) => {
  const [state, setState] = useState(false);
  const methods = useForm<FiltersInput>({ defaultValues: defaultFilterValues });
  const { handleSubmit, control, setError, reset } = methods;

  const onSubmit = (data: FiltersInput) => {
    if (data?.dateRange && data?.dutySpend) {
      setState(!state);
      handler();
    }
  };

  const handleReset = () => {
    reset();
    setState(!state);
    handler();
  };

  return (
    <>
      <Grid container alignItems={"center"} spacing={5}>
        <Grid item xs={12} sm={6} md={4}>
          <AppSelect
            label="Duty Spend"
            placeholder="Duty Spend"
            data={dutySpendData}
            name="dutySpend"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppDateRangePicker
            name="dateRange"
            control={control}
            label="Select Date Range"
            placeholder="Date Range"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            sx={{
              fontSize: "1.3rem",
              fontWeight: 500,
              marginTop: "1.7rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            Filter{" "}
            {state ? (
              <FaFilter
                cursor={"pointer"}
                onClick={handleReset}
                color="#0B494B"
              />
            ) : (
              <LuFilter
                cursor={"pointer"}
                color="#0B494B"
                onClick={handleSubmit(onSubmit)}
              />
            )}{" "}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardFilters;
