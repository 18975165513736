import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IKeyValue } from "../../@types/GlobalMessageContext";

const ExpandableList = ({ item }: { item: IKeyValue }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (path: string) => {
    navigate(path);
  };
  const active = item?.href
    ? location.pathname === item?.href ||
      location.pathname.startsWith(item.startsWith)
    : false;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          cursor: "pointer",
          marginBottom: "7px",
        }}
        onClick={() => setOpen(!open)}
      >
        <Typography
          sx={{
            paddingLeft: "14.4px",
            color: active ? "#1CB4BA" : "#8E9494",
            marginBottom: "10px",
          }}
        >
          {item?.title}
        </Typography>
        {open ? (
          <ExpandLess
            sx={{ color: "#8E9494", width: "18px", height: "18px" }}
          />
        ) : (
          <ExpandMore
            sx={{ color: "#8E9494", width: "18px", height: "18px" }}
          />
        )}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          sx={{ width: "75%" }}
          component="div"
          disablePadding
          className="expandNavList"
        >
          {item?.subChilds?.map((child: IKeyValue, ind: number) => {
            const active = child?.href
              ? location.pathname === child?.href
              : false;
            return (
              <ListItemButton
                key={ind}
                onClick={() => handleClick(child?.href)}
                sx={{
                  padding: "0",
                  background: active ? "#08383a" : "",
                  opacity: active ? ".9" : "",
                  borderRadius: "4px",
                  // paddingTop: "5px",
                  // paddingBottom: "5px",
                  // marginTop: "6px",
                }}
              >
                <ListItemText
                  sx={{
                    "& span": {
                      color: active ? "#1CB4BA" : "#8E9494",
                    },
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                  primary={child?.title}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

interface IExpandNavItem {
  item: IKeyValue;
}
const ExpandNavItem = ({ item }: IExpandNavItem) => {
  const [open, setOpen] = React.useState(true);
  const [path, setPath] = React.useState("/");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.startsWith("/dashboard")) setOpen(true);
    else setOpen(false);
  }, [location.pathname]);

  const handleClick = (path: string) => {
    navigate(path);
  };

  const active = item?.href
    ? location.pathname === item?.href ||
      location.pathname.startsWith(item.startsWith)
    : false;

  const activeBorder1 =
    location.pathname.includes("/dashboard/analytics") ?? false;
  const activeBorder2 =
    location.pathname.includes("/dashboard/savings") ?? false;
  const activeBorder3 =
    location.pathname.includes("/dashboard/compliance") ?? false;

  const onClick = () => {
    setOpen(!open);
    navigate(item.href);
  };

  return (
    <>
      <ListItemButton onClick={onClick}>
        <img
          src={item?.icon}
          alt=""
          width={"20px"}
          style={{ paddingLeft: "10px" }}
        />
        <ListItemText
          sx={{ paddingLeft: "14.4px" }}
          primaryTypographyProps={{
            style: {
              color: active ? "#1CB4BA" : "#8E9494",
            },
          }}
          className="sidebar-expand-nav"
          primary={item?.title}
        />
        {open ? (
          <ExpandLess
            sx={{ color: "#8E9494", width: "18px", height: "18px" }}
          />
        ) : (
          <ExpandMore
            sx={{ color: "#8E9494", width: "18px", height: "18px" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box>
            <Divider
              orientation="vertical"
              sx={{
                borderColor: activeBorder1 ? "#1CB4BA" : "#2c3738",
                width: "2rem",
                height: "2rem",
              }}
            />
            <Divider
              orientation="vertical"
              sx={{
                borderColor: activeBorder2 ? "#1CB4BA" : "#2c3738",
                width: "2rem",
                height: "2rem",
              }}
            />
            <Divider
              orientation="vertical"
              sx={{
                borderColor: activeBorder3 ? "#1CB4BA" : "#2c3738",
                width: "2rem",
                height: "2rem",
              }}
            />
          </Box>
          <List
            component="div"
            sx={{ width: "75%" }}
            disablePadding
            className="expandNavList"
          >
            {item?.childs?.map((child: IKeyValue, ind: number) => {
              const active = child?.href
                ? location.pathname === child?.href
                : false;
              if (child?.isChildExpand) {
                return <ExpandableList item={child} key={ind} />;
              }
              return (
                <ListItemButton
                  onClick={() => handleClick(child?.href)}
                  key={ind}
                  sx={{
                    background: active ? "#08383a" : "",
                    opacity: active ? ".9" : "",
                    borderRadius: "4px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <ListItemText
                    sx={{
                      "& span": {
                        color: active ? "#1CB4BA" : "#8E9494",
                      },
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    primary={child?.title}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Box>
      </Collapse>
    </>
  );
};

export default ExpandNavItem;
