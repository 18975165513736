import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { styled as newStyle } from "@mui/material/styles";
import { Theme as MuiTheme } from "@mui/material/styles";

import {
  AppBar,
  Box,
  IconButton,
  InputBase,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import AccountPopover from "./account-popover";
import ExpandIcon from "../../assets/images/expand.png";
import NewSearchIcon from "../../assets/images/search.svg";
import { Logo } from "../../components";
import { Items } from "./items";

const Search = newStyle("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "6px",
  backgroundColor: "#f4f6f8",
  "&:hover": {
    backgroundColor: "#f4f6f8",
  },
  opacity: "1",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: 500,
}));

const SearchIconWrapper = newStyle("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = newStyle(InputBase)(({ theme }) => ({
  color: "#84818",
  fontSize: "14px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#84818",
    opacity: ".5",
    fontWeight: 500,
  },
}));

const NavbarRoot = styled(AppBar)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "none",
  background: "#FFF",
}));

interface INavbar {
  onSidebarOpen?: () => void;
  isPermanentSidebarOpen: boolean;
}

export const Navbar = (props: INavbar): JSX.Element => {
  const { onSidebarOpen, isPermanentSidebarOpen, ...rest } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: MuiTheme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  return (
    <>
      <NavbarRoot
        sx={{
          left: {
            lg: isPermanentSidebarOpen ? 240 : 100,
            transition: "0.3s",
          },
          width: {
            lg: `calc(100% - ${isPermanentSidebarOpen ? "240px" : "100px"})`,
          },
          height: {
            md: "96px",
            xs: "76px",
          },
          px: {
            md: 5,
            xs: 2,
          },
          border: "1px solid #F4F4F4",
          background: "#fff",
          boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.05)",
        }}
        {...rest}
      >
        <Toolbar
          disableGutters
          sx={{
            left: 0,
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
            }}
          >
            <img
              src={ExpandIcon}
              alt=""
              width="24px"
              style={{ transform: "rotate(180deg)" }}
            />
          </IconButton>
          {/* <Box
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
              ml: 1,
              mt: 0.5,
            }}
          >
            <Link component={RouterLink} to="/">
              <Logo width={"50px"} />
            </Link>
          </Box> */}
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Box>
            <Typography
              sx={{ color: "#000", fontSize: "1.4rem", fontWeight: 500 }}
            >
              {
                Items.find(
                  (el: any) =>
                    location.pathname === el?.href ||
                    location.pathname.includes(el?.startsWith)
                )?.title
              }
            </Typography>
          </Box>
          {lgUp ? (
            <Box>
              <Search>
                <SearchIconWrapper>
                  {/* <SearchIcon sx={{ color: "#84818A", opacity: ".5" }} /> */}
                  <img src={NewSearchIcon} alt="SearchIcon" />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Quick Search"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
          ) : (
            ""
          )}

          <AccountPopover />
        </Toolbar>
      </NavbarRoot>
    </>
  );
};

Navbar.defaultProps = {
  onSidebarOpen: undefined,
  isPermanentSidebarOpen: true,
};
Navbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  isPermanentSidebarOpen: PropTypes.bool,
};
