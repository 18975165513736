import { Box, Divider, Slider, styled, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import InfoIcon from "../../../assets/images/info.svg";
import GlobalCard from "../../../components/card";
import GlobalText from "../../../components/typography";
import { abbreviateNumber } from "../../../utils/abbreviateNumber";

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps & { active: boolean }) => (
  <Tab disableRipple {...props} />
))(({ theme, active }) => ({
  textTransform: "none",
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  background: active ? "#0B494B" : "#fff",
  borderRadius: "32px",
  border: "1px solid #E6E6E8",
  color: active ? "#fff !important" : "#000 !important",
}));

const Opportunities = ({
  subHeadingTxt,
  data,
  totalDuty,
  tabValue,
  handleTabChange,
}: {
  subHeadingTxt: string;
  data: {
    duty: number;
    manufacturer: string;
  }[];
  totalDuty: number;
  tabValue: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}) => {
  const [newDuty, setNewDuty] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [dataPoints, setDataPoints] = useState<number[]>([]);
  const manufacturers = data.map((val) => val.manufacturer);

  const handleChange = (_event: Event, newValue: number | number[]) => {
    setSliderValue(typeof newValue === "number" ? newValue : 0);
    const dutySaved = (totalDuty * Number(newValue)) / 100;
    setDataPoints(
      data.map(
        (val) => (Number(newValue) * parseFloat(val.duty.toFixed(2))) / 100
      )
    );
    setNewDuty(dutySaved);
  };

  useEffect(() => {
    setNewDuty(0);
    setSliderValue(0);
    data.map((val) => val.duty);
  }, [tabValue]);

  useEffect(() => {
    setDataPoints(data.map((val) => parseFloat(val.duty.toFixed(2))));
  }, [data]);

  const chartData: any = {
    series: [
      {
        data: dataPoints.filter((item) => item !== 0),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
        id: "gradient-bar",
        events: {
          mounted: (chartContext: any, config: any) => {
            const bars = config.globals.dom.baseEl?.querySelectorAll(
              ".apexcharts-bar-area"
            );
            bars?.forEach((bar: any, index: any) => {
              const path = bar.querySelector("path");
              if (path) {
                const gradientId =
                  index % 2 === 0 ? "#evenGradient" : "#oddGradient";
                path.setAttribute("fill", `url(${gradientId})`);
              }
            });
          },
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 6,
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "top",
            formatter: function (val: number) {
              if (!val) return val;
              return `$${val.toFixed(2)}`; // Add dollar sign
            },
            minHeight: 20, // Set a minimum height for data labels
          },
        },
      },
      colors: ["#0B494B", "#cddada"],
      legend: { show: false },
      grid: {
        borderColor: "#D8DAE5",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 125,
        position: "bottom",
        style: {
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Inter",
          colors: [100, 90, 80, 70, 60].map((_, index) => "#0B494B"),
        },
        formatter: function (val: number) {
          return `$${abbreviateNumber(val.toFixed(2))}`;
        },
      },
      xaxis: {
        categories: manufacturers,

        labels: {
          formatter: function (val: number) {
            if (!val) return val;
            return abbreviateNumber(val.toFixed(2));
          },
          style: {
            colors: ["#8E9494"],
            fontFamily: "Inter",
          },
          offsetY: 5,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderColor: "#fff",
            borderWidth: 1,
            opacity: 1,
          },
        ],
        xaxis: [
          {
            x: 0,
            strokeDashArray: 0,
            borderColor: "#fff",
            borderWidth: 1,
            opacity: 1,
          },
        ],
      },
    },
  };
  return (
    <GlobalCard style={{ pt: 2, px: 2, mb: 2, height: "98%" }}>
      <GlobalText
        name="Opportunities"
        color="#000"
        fontSize={"1.2rem"}
        fontWeight={500}
        isImg={true}
        imgSrc={InfoIcon}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          padding: "1.2rem",
          pb: 0.5,
          p: 0,
        }}
      />
      <GlobalText
        name={subHeadingTxt}
        color="#84818A"
        fontSize={"14px"}
        fontWeight={400}
      />

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        sx={{ mb: 4, ml: 1, mt: 2 }}
      >
        <StyledTab label="All MIDs" active={tabValue === 0} />
        <StyledTab label="Origin ≠ Export" active={tabValue === 1} />
        <StyledTab label="MID Origin ≠ Origin" active={tabValue === 2} />
      </Tabs>

      <Box
        display="flex"
        alignItems="center"
        // justifyContent="space-between"
        p={2}
        mt={2}
        border={1}
        borderColor="grey.300"
        borderRadius={2}
      >
        <Box
          display={"flex"}
          gap={5}
          alignItems="center"
          justifyContent="space-between"
          ml={2}
        >
          <Box>
            <GlobalText
              name={`$${abbreviateNumber(totalDuty)}`}
              color="#000"
              fontSize={"24px"}
              style={{
                fontFamily: "Plus Jakarta Sans",
              }}
              fontWeight={700}
            />
            <GlobalText
              name="Duty Paid"
              color="#84818A"
              fontSize={"11px"}
              fontWeight={500}
              style={{
                fontFamily: "Plus Jakarta Sans",
              }}
            />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ marginLeft: "16px", marginRight: "16px", height: "50px" }}
          />
          <Box sx={{ width: "100px" }}>
            <GlobalText
              name={`$${abbreviateNumber(newDuty.toFixed(2))}`}
              color="#000"
              fontSize={"24px"}
              style={{
                fontFamily: "Plus Jakarta Sans",
              }}
              fontWeight={700}
            />
            <GlobalText
              name="First Sale Saving"
              color="#84818A"
              fontSize={"11px"}
              fontWeight={500}
              style={{
                fontFamily: "Plus Jakarta Sans",
              }}
            />
          </Box>
          <div
            style={{ marginLeft: "5px", marginRight: "5px", height: "50px" }}
          />
        </Box>

        <Box width={"60%"} margin={"auto"} mt={"10px"}>
          <Slider
            aria-labelledby="input-slider"
            valueLabelDisplay="on"
            step={0.01}
            min={0}
            max={100}
            value={sliderValue}
            onChange={handleChange}
            valueLabelFormat={(value: number) => {
              return `${value}%`;
            }}
            sx={{
              mt: 1,
              "& .MuiSlider-valueLabel": {
                backgroundColor: "#003d33",
                borderRadius: "5px",
                fontWeight: 400,
                fontFamily: "Inter",
                fontSize: 11,
              },
              color: "#fff",
              height: 8,
              "& .MuiSlider-thumb": {
                height: 24,
                width: 24,
                // backgroundColor: '#fff',
                backgroundColor: "#004D44",
                border: "7px solid currentColor",
                boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.2)",
                "&:hover, &.Mui-focusVisible, &.Mui-active": {
                  boxShadow: "inherit",
                },
              },
              "& .MuiSlider-track": {
                height: 8,
                borderRadius: 4,
              },
              "& .MuiSlider-rail": {
                height: 8,
                borderRadius: 4,
              },
            }}
          />
        </Box>
      </Box>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={500}
      />
    </GlobalCard>
  );
};

export default Opportunities;
