import { Box, Dialog, Typography } from "@mui/material";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  children: JSX.Element;
  title: string;
  actions: JSX.Element;
}

const Modal = ({
  open,
  handleClose,
  children,
  title,
  actions,
}: IModalProps) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <Typography color="#4E535A" fontWeight={500} fontSize={28}>
        {title}
      </Typography>
      <Box sx={{ pb: 4, pt: 3 }}>{children}</Box>
      <Box>{actions}</Box>
    </Dialog>
  );
};

export default Modal;
