import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SelectRoleProps } from "../../@types/User";
import { User_Roles } from "../../context/user/UserContainer";
import { Typography } from "@mui/material";

const SelectRole: React.FC<SelectRoleProps> = ({ userChangeHandler }) => {
  const [role, setRole] = React.useState("user");

  const handleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
    userChangeHandler(event, "role");
  };

  const userRoles = [User_Roles.admin, User_Roles.user];

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Typography color={"#ACACAC"} fontWeight={500} fontSize={12} mb={1}>Role</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={role}
          label="role"
          onChange={handleChange}
          variant="standard"
          sx={{
            textTransform: "capitalize",
            borderRadius: "0px !important",
            color: "#4E535A",
            fontWeight: 400,
            ".MuiSvgIcon-root ": {
              fill: "#0B494B !important",
              fontSize: 40,
            },
          }}
        >
          {userRoles.map((role) => (
            <MenuItem key={role} value={role} sx={{textTransform: "capitalize"}}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectRole;
