import { ChangeEvent, useRef } from "react";
import "./index.css";
import { Button, Typography } from "@mui/material";

function FileUploader({
  label,
  id,
  selectedFile,
  handleFileChange,
  accept,
}: {
  label: string;
  id: string;
  accept: string;
  selectedFile: File | null;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <div className="custom-file-uploader">
        <Typography fontWeight={400} color={"#4E535A"} fontSize={18}>
          {label}
        </Typography>
        <input
          id={id}
          type="file"
          style={{ visibility: "hidden" }}
          onChange={handleFileChange}
          ref={fileInputRef}
          accept={accept}
        />
        <div className="upload-btn">
          <Button
            onClick={handleButtonClick}
            sx={{
              color: "#4E535A",
              background: "rgba(172, 172, 172, 0.15)",
              fontSize: 18,
              textTransform: "initial",
              fontWeight: 400,
              px: 5,
              mb: 0.5
            }}
          >
            Choose File
          </Button>
        </div>
        <div className="file-info">
          {selectedFile ? <p>{selectedFile.name}</p> : <p>No File Selected</p>}
        </div>
      </div>
    </>
  );
}

export default FileUploader;
