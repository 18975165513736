import { Box, Menu, MenuProps, useMediaQuery } from "@mui/material";
import { Theme as MuiTheme, styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ICommonFilters } from "../../../@types/global";
import AdvanceFilter from "./advance-filter";
import DateRangePickerFilter from "./date-picker-filter";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12,
    background: "#F7F8F8",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      // "&:active": {
      //   backgroundColor: alpha(
      //     theme.palette.primary.main,
      //     theme.palette.action.selectedOpacity
      //   ),
      // },
    },
  },
}));

type FiltersInput = {
  dutySpend: string;
  dateRange: string;
  importerNo: string;
  classification: string;
  entryNo: string;
  tradeFlow: string;
  countryOfOrigin: string;
  countryOfExpert: string;
};
const defaultFilterValues = {
  dutySpend: "",
  dateRange: "",
  importerNo: "",
  classification: "",
  entryNo: "",
  tradeFlow: "",
  countryOfOrigin: "",
  countryOfExpert: "",
};

const CommonFilters = ({
  onDutySpendChange,
  dutySpendFilter,
  handleDateFilter,
  handleFilterReset,
  handleFilterLabel,
}: ICommonFilters) => {
  const [openDateFilter, setOpenDateFilter] = useState<null | HTMLElement>(
    null
  );

  const lgUp = useMediaQuery((theme: MuiTheme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });
  const methods = useForm<FiltersInput>({ defaultValues: defaultFilterValues });
  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    if (dutySpendFilter !== "") {
      reset({ dutySpend: dutySpendFilter });
    }
  }, [dutySpendFilter]);

  return (
    <Box
      display="flex"
      gap={2}
      flexWrap={"wrap"}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      <Box {...(!lgUp && { width: "100%" })}>
        <DateRangePickerFilter
          StyledMenu={StyledMenu}
          handleFilterLabel={handleFilterLabel}
          handleDateFilter={handleDateFilter}
          openDateFilter={openDateFilter}
          setOpenDateFilter={setOpenDateFilter}
        />
      </Box>
      <Box {...(!lgUp && { width: "100%" })}>
        <AdvanceFilter
          StyledMenu={StyledMenu}
          handleFilterReset={handleFilterReset}
          onDutySpendChange={onDutySpendChange}
          setOpenDateFilter={setOpenDateFilter}
          handleSubmit={handleSubmit}
          control={control}
          reset={reset}
          defaultFilterValues={defaultFilterValues}
        />
      </Box>
    </Box>
  );
};

export default CommonFilters;
