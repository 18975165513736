import { InputAdornment, TextField } from "@mui/material";
import { IoSearch } from "react-icons/io5";

interface FormSearchInputProps {
  size?: "medium" | "small";
  type?: "text" | "email" | "password";
  placeholder?: string;
  onChange?: (e: any) => void;
}

export const FormSearchInputText = ({
  size = "medium",
  type = "text",
  placeholder = "Search",
  onChange,
}: FormSearchInputProps) => {
  return (
    <TextField
      size={size}
      placeholder={placeholder}
      type={type}
      InputProps={{
        sx: { color: "#4E535A" },
        startAdornment: (
          <InputAdornment position="start">
            <IoSearch color="#bfc0c2" size={20} />
          </InputAdornment>
        ),
      }}
      sx={{
        width: "25ch",
        borderRadius: "4px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "4px",
          "& fieldset": {
            border: "1px solid #CFCFCF",
          },
        },
      }}
      onChange={onChange}
    />
  );
};
