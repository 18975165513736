import { Box, Grid } from "@mui/material";
import AppSelect from "../select";
import {
  classification,
  countryOfExpert,
  countryOfOrigin,
  entryNo,
  importerNo,
  tradeFlow,
} from "./data";
import { useForm } from "react-hook-form";

const DashboardSubFilters = () => {
  type FiltersInput = {
    importerNo: string;
    classification: string;
    entryNo: string;
    tradeFlow: string;
    countryOfOrigin: string;
    countryOfExpert: string;
  };

  const defaultFilterValues = {
    importerNo: "",
    classification: "",
    entryNo: "",
    tradeFlow: "",
    countryOfOrigin: "",
    countryOfExpert: "",
  };

  const methods = useForm<FiltersInput>({ defaultValues: defaultFilterValues });
  const { handleSubmit, control, setError } = methods;

  return (
    <Box sx={{ background: "#F9F9F9", marginBottom: "2rem",padding:"1rem" }}>
      <Grid container alignItems={"center"} spacing={5}>
        <Grid item xs={12} sm={6} md={2}>
          <AppSelect
            control={control}
            name="importerNo"
            label="Select Importer No"
            placeholder="Importer No"
            data={importerNo}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppSelect
            control={control}
            name="classification"
            label="Select Classification"
            placeholder="Classification"
            data={classification}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppSelect
            control={control}
            name="entryNo"
            label="Select Entry No"
            placeholder="Entry No"
            data={entryNo}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppSelect
            control={control}
            name="tradeFlow"
            label="Select Trade Flow"
            placeholder="Trade Flow"
            data={tradeFlow}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppSelect
            control={control}
            name="countryOfOrigin"
            label="Select Country of Origin"
            placeholder="Country of Origin"
            data={countryOfOrigin}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppSelect
            control={control}
            name="countryOfExpert"
            label="Select Country of Export"
            placeholder="Country of Export"
            data={countryOfExpert}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardSubFilters;
