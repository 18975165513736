import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { ErrorLoadingType } from "../../utils/Error";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import axios from "axios";
import { ENDPOINT } from "../../config/config";
import { UserContextType } from "../../@types/UserContext";
import UserContext from "../../context/user/UserContext";
import { Status } from "../download/Download";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Question5 from "./Question5";
import DateHelpers from "../dashboard/DateHelpers";
import { Modal } from "../../components";
import I1 from "../../assets/images/i1.png";
import I2 from "../../assets/images/i2.png";
import I3 from "../../assets/images/i3.png";
import I4 from "../../assets/images/i4.png";
import I5 from "../../assets/images/i5.png";
import I6 from "../../assets/images/i6.png";
import I7 from "../../assets/images/i7.png";

const styles = () => ({
  container: {
    padding: 2,
    background: "#FFF",
  },
  title: {
    margin: "0px 0px 4px 0px",
  },
  header: {
    margin: "0px 0px 16px 0px",
  },
});

interface Question {
  key: string;
  value: string;
  options: {
    key: string;
    value: string;
    disabled: boolean;
    both?: boolean;
    icon?: JSX.Element;
    label?: string;
  }[];
}

const questionsData: Question[] = [
  {
    key: "q1",
    value: "What is the Purpose of the adjustment?",
    options: [
      {
        key: "reconciliation",
        value: "Reconciliation",
        disabled: false,
        label: "Reconciliation",
        icon: <img src={I1} width={100} alt="" />,
      },
      {
        key: "priordisclosure",
        value: "Prior Disclosure",
        disabled: true,
        label: "Prior Disclosure",
        icon: <img src={I2} width={75} alt="" />,
      },
      {
        key: "bothReconciliationPriorDisclosure",
        value: "Both Reconciliation & Prior Disclosure",
        disabled: true,
        both: true,
        label: "Reconciliation \n + \n Prior Disclosure",
      },
    ],
  },
  {
    key: "q2",
    value: "What is your adjustment type?",
    options: [
      {
        key: "tpa",
        value: "TPA",
        disabled: false,
        label: "TPA",
        icon: <img src={I1} width={100} alt="" />,
      },
      {
        key: "computedValueAdjustments",
        value: "Computed Value Adjustments",
        disabled: true,
        label: "Computed Value \n Adjustment",
        icon: <img src={I3} width={55} alt="" />,
      },
      {
        key: "firstSaleReconciliation",
        value: "First Sale Reconciliation",
        disabled: true,
        label: "First Sale \n Reconciliation",
        icon: <img src={I4} width={55} alt="" />,
      },
      {
        key: "assists",
        value: "Assists",
        disabled: true,
        label: "Assists",
        icon: <img src={I5} width={57} alt="" />,
      },
      {
        key: "estimatedFreight",
        value: "Estimated Freight",
        disabled: true,
        label: "Estimated \n Freight",
        icon: <img src={I6} width={63} alt="" />,
      },
      {
        key: "royalty",
        value: "Royalty",
        disabled: true,
        label: "Royalty",
        icon: <img src={I7} width={65} alt="" />,
      },
    ],
  },
  {
    key: "q3",
    value: "What is the period of the adjustment?",
    options: [],
  },
  {
    key: "q4",
    value:
      "How would you like to apply the adjustment to your import transactions?",
    options: [
      {
        key: "Yes",
        value: "Yes, All flagged transactions.",
        disabled: false,
        label: "All flagged \n transactions",
      },
      {
        key: "No",
        value: "Apply to a subset of the flagged import transactions.",
        disabled: true,
        label: "Select \n Transaction",
      },
    ],
  },
  {
    key: "q5",
    value: "What is the total adjustment figure?",
    options: [],
  },
];

export type AnswerType = {
  key: string;
  value: any;
  value2?: any;
  subValue?: string | File;
};

const Questionnaire: React.FC = () => {
  const classes = styles();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<AnswerType[]>([]);
  const userContext = useContext(UserContext) as UserContextType;
  const [errorLoading, setErrorLoading] = useState<ErrorLoadingType>({
    isError: false,
    isLoading: false,
    errorMsg: null,
  });
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;
  const [disable, setDisable] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [pollId, setPollId] = useState("");
  const [msg, setMsg] = useState("Please Wait...");
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSubmit = () => {
    startDownloading();
    setOpenDialog(!openDialog);
  };

  useEffect(() => {
    if (pollId) {
      const timerId = window.setInterval(getUrl, 3000);
      return () => {
        window.clearInterval(timerId);
      };
    }
  }, [pollId]);

  const updateDialogAndSnackBar = (msg?: string) => {
    setPollId("");
    setOpenDialog(false);
    updateSnackBar({
      show: true,
      variant: "error",
      message: msg ?? "Error in downloading the file.",
    });
  };

  const getUrl = async () => {
    try {
      const res = await axios.get(`${ENDPOINT}/data/url?fileId=${pollId}`, {
        headers: {
          Authorization: `Bearer ${userContext.user.token}`,
          "Content-Type": "form-data",
        },
      });
      const urlData = res.data;
      setMsg(urlData?.msg ?? "");
      if (urlData.status === Status[Status.Success]) {
        reset();
        window.open(urlData.url, "_blank");
      } else if (urlData.status === Status[Status.Failed]) {
        setMsg("Please Wait...");
        updateDialogAndSnackBar(urlData?.msg);
      }
    } catch (err) {
      setMsg("Please Wait...");
      updateDialogAndSnackBar();
    }
  };

  const reset = () => {
    setPollId("");
    setOpenDialog(false);
    setMsg("Please Wait...");
    setCurrentQuestionIndex(0);
    setAnswers([]);
  };

  const handleNext = () => {
    const idx = currentQuestionIndex + 1;
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    isDisable(idx);
  };
  const handlePrevious = () => {
    const idx = currentQuestionIndex - 1;
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    isDisable(idx);
  };

  const handleAnswer = (event: any) => {
    const questionKey = questionsData[currentQuestionIndex].key;
    const answer = event.target.value;
    const answersToUpdate = [...answers];
    if (answersToUpdate?.[currentQuestionIndex])
      answersToUpdate[currentQuestionIndex].value = answer;
    else answersToUpdate.push({ key: questionKey, value: answer });
    setAnswers(answersToUpdate);

    setDisable(false);
  };

  const updateAnswers = (answer: AnswerType) => {
    const answersToUpdate = [...answers];
    if (answersToUpdate?.[currentQuestionIndex]) {
      answersToUpdate[currentQuestionIndex].key = answer.key;
      answersToUpdate[currentQuestionIndex].value = answer.value;
      answersToUpdate[currentQuestionIndex].value2 = answer.value2;
      answersToUpdate[currentQuestionIndex].subValue = answer.subValue;
    } else answersToUpdate.push(answer);
    setAnswers(answersToUpdate);
    setDisable(false);
  };

  const startDownloading = async () => {
    try {
      setErrorLoading({ isLoading: true, isError: false });

      const formData = new FormData();
      formData.append("answers", JSON.stringify(answers));

      const res = await axios.post(`${ENDPOINT}/filter-download`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${userContext.user.token}`,
        },
      });
      setPollId(res?.data);

      setErrorLoading({ isLoading: false, isError: false });
    } catch (err: any) {
      setErrorLoading({ isLoading: false, isError: true });

      updateSnackBar({
        show: true,
        variant: "error",
        message: err?.response?.data?.error ?? "Something went wrong",
      });
    }
  };

  const isDisable = (idx: number) => setDisable(!answers?.[idx]?.value);

  const handleDateSelection = (dateRange: any) => {
    setSelectionRange(dateRange.selection);
    const questionKey = questionsData[currentQuestionIndex].key;
    const answersToUpdate = [...answers];
    const { value, value2 } = {
      value: DateHelpers.formatDate(dateRange.selection.startDate),
      value2: DateHelpers.formatDate(dateRange.selection.endDate),
    };
    if (answersToUpdate?.[currentQuestionIndex]) {
      answersToUpdate[currentQuestionIndex].value = value;
      answersToUpdate[currentQuestionIndex].value2 = value2;
    } else answersToUpdate.push({ key: questionKey, value, value2 });
    setAnswers(answersToUpdate);
    setDisable(false);
  };

  return (
    <Box sx={{ pb: 2, mt: { md: 2, lg: 0 } }}>
      <Stack
        direction={{ sm: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ mb: 3 }}
      >
        <Typography
          color="#4E535A"
          fontWeight={500}
          fontSize={26}
          mb={{ xs: 2, md: 0 }}
        >
          Value Adjustment
        </Typography>
      </Stack>
      <Box
        sx={{
          background: "#FFF",
          boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box
          sx={{
            background: "#0B494B",
            boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.05)",
            textAlign: "center",
            p: 2,
          }}
        >
          <Typography color="#fff" fontSize={26} fontWeight={500}>
            Tell us a bit about yourself
          </Typography>
        </Box>
        <Box sx={{ px: 5, py: 3 }}>
          <Stack
            direction={{ sm: "column", md: "row" }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {/* <Stack
              sx={{
                width: 220,
              }}
              direction={"row"}
              alignItems={"center"}
            >
              <Typography
                sx={{ pr: 1 }}
                color="#0B494B"
                fontWeight={500}
                fontSize={16}
              >
                {`(${currentQuestionIndex + 1} of ${questionsData?.length}`}
              </Typography>
              <Box sx={{ width: 140, p: 0.1, background: "#D9D9D9" }} />
            </Stack> */}
            <Box sx={{ flex: 1 }}>
              <Typography
                fontSize={22}
                color="#4E535A"
                fontWeight={500}
                textAlign={"center"}
              >
                {questionsData[currentQuestionIndex].value}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                width: 220,
              }}
            ></Box> */}
          </Stack>
          <Box sx={{ py: 3 }}>
            {currentQuestionIndex === 2 && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={2}
              >
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => handleDateSelection(item)}
                  moveRangeOnFirstSelection={false}
                  ranges={[selectionRange]}
                />
              </Stack>
            )}

            <Grid
              container
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "65%",
                  margin: "0px auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              {questionsData[currentQuestionIndex].options?.map((option) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  p={1}
                  textAlign={"center"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                >
                  <Stack
                    key={option.key}
                    sx={{
                      border:
                        answers[currentQuestionIndex]?.value === option.key
                          ? "1px solid #0B494B"
                          : "none",
                      width: "190px",
                      height: "190px",
                      background: "#fff",
                      boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.05)",
                      cursor: option.disabled ? "not-allowed" : "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      if (option.disabled) {
                        return null;
                      } else {
                        handleAnswer({ target: { value: option.key } });
                      }
                    }}
                    spacing={1}
                    m={1}
                  >
                    {option?.icon}
                    <Box>
                      {option?.both && (
                        <Typography
                          fontSize={30}
                          color="#4E535A"
                          fontWeight={500}
                          textAlign={"center"}
                        >
                          Both
                        </Typography>
                      )}
                      <Typography
                        fontSize={16}
                        color="#4E535A"
                        fontWeight={500}
                        textAlign={"center"}
                        sx={{ px: 3 }}
                      >
                        {option.label}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              ))}
            </Grid>

            {currentQuestionIndex === 4 && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={2}
              >
                <Question5 answers={answers} updateAnswers={updateAnswers} />
              </Stack>
            )}
          </Box>
          <Stack direction="row" spacing={2} justifyContent="center">
            {currentQuestionIndex !== 0 && (
              <Button
                onClick={handlePrevious}
                disableElevation
                variant="contained"
                color="error"
                sx={{
                  background: "#FF4560",
                  color: "#fff",
                  borderRadius: 36,
                  fontWeight: 700,
                  fontSize: 15,
                  textTransform: "initial",
                  width: 120,
                  py: 1,
                }}
              >
                Previous
              </Button>
            )}
            {currentQuestionIndex === questionsData.length - 1 ? (
              <Button
                onClick={handleSubmit}
                disableElevation
                variant="contained"
                color="success"
                sx={{
                  background: "#0B494B",
                  color: "#fff",
                  borderRadius: 36,
                  fontWeight: 700,
                  fontSize: 15,
                  textTransform: "initial",
                  py: 1,
                  px: 5,
                }}
                disabled={disable}
              >
                Download Reconciliation File
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                disableElevation
                variant="contained"
                color="success"
                sx={{
                  background: "#5EDA81",
                  color: "#fff",
                  borderRadius: 36,
                  fontWeight: 700,
                  fontSize: 15,
                  textTransform: "initial",
                  width: 120,
                  py: 1,
                }}
                disabled={disable}
              >
                Next
              </Button>
            )}
          </Stack>
        </Box>
      </Box>

      <Modal
        open={openDialog}
        handleClose={() => {}}
        title="Download File"
        actions={<></>}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>{msg}</Typography>
        </Stack>
      </Modal>
    </Box>
  );
};

export default Questionnaire;
