import {
  Box,
  Card,
  CircularProgress,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import ReactApexChart from "react-apexcharts";
import { abbreviateNumber } from "../../../utils/abbreviateNumber";
import { useCallback } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

interface ITariffCodes {
  data: IKeyValue;
  setPage: (value: number | any) => void;
  loading: boolean;
}

function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

const TrafficCodes = ({ data, setPage, loading }: ITariffCodes) => {
  const options: any = {
    series: [
      {
        // data: [15, 10, 7, 5, 2],
        name: "Tariff Duty",
        data: data?.data?.map((el: IKeyValue) => Math.round(el?.tariff_duty)),
      },
      {
        // data: [85, 60, 40, 30, 10],
        name: "Tariff Value",
        data: data?.data?.map((el: IKeyValue) => Math.round(el?.tariff_value)),
      },
    ],
    options: {
      colors: ["#1CB4BA", "#0B494B"],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          borderRadiusApplication: "end", // 'around', 'end'
          rangeBarGroupRows: true,
          endingShape: "rounded",
          barHeight: "60%",
          dataLabels: {
            total: {
              position: "middle",
              enabled: true,
              offsetX: 20,
              offsetY: 10,
              formatter: function (val: string) {
                if (!val) return val;
                return abbreviateNumber(val);
              },
              style: {
                fontSize: "13px",
                fontWeight: 500,
                color: "#0B494B",
                fontFamily: "Inter",
              },
            },
          },
        },
      },
      legend: { show: true, position: "top" },
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: string) {
          return abbreviateNumber(val);
        },
      },
      xaxis: {
        categories: data?.data?.map((el: IKeyValue) =>
          Math.round(el?.hts_number)
        ),

        labels: {
          formatter: function (val: string) {
            if (!val) return val;
            return abbreviateNumber(val);
          },
          style: {
            colors: ["#8E9494"],
            fontFamily: "Inter",
          },
          offsetY: 5,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val: string) {
            if (!val) return val;
            return abbreviateNumber(val);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        borderColor: "#D8DAE5",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  };

  // const handleScroll = useCallback(
  //   debounce((event: React.UIEvent<HTMLDivElement>) => {
  //     const target = event.target as HTMLDivElement;

  //     const isAtBottom =
  //       target.scrollHeight === target.scrollTop + target.clientHeight ||
  //       target.scrollHeight - ~~(target.scrollTop + target.clientHeight) === 1;

  //     const isAtTop = target.scrollTop === 0;

  //     if (isAtBottom) {
  //       setPage((prevPage: number) => prevPage + 1);
  //     } else if (isAtTop) {
  //       setPage((prevPage: number) => Math.max(prevPage - 1, 1));
  //     }
  //   }, 1000),
  //   []
  // );

  return (
    <Card
      component={"div"}
      // onScroll={handleScroll}
      sx={{ px: 3, pt: 4, pb: 4, overflow: "scroll !important", height: 400 }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : !data || !data?.data?.length || data?.data?.length < 5 ? (
        <>
          <Typography color="#000" fontSize={"1.2rem"} fontWeight={500}>
            Tariff Codes
          </Typography>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            color="#000"
            fontSize={"1rem"}
            fontWeight={500}
          >
            No data to display
          </Typography>
        </>
      ) : (
        <>
          <Typography color="#000" fontSize={"1.2rem"} fontWeight={500}>
            Tariff Codes
            <span>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  float: "right !important",
                }}
              >
                <Tooltip title="Next Page">
                  <IconButton>
                    <FaArrowDown
                      onClick={() =>
                        setPage((prevPage: number) => prevPage + 1)
                      }
                      size="20"
                      color="#0B494B"
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Prev Page">
                  <IconButton
                    onClick={() => {
                      setPage((prevPage: number) => Math.max(prevPage - 1, 1));
                    }}
                  >
                    <FaArrowUp size="20" color="#0B494B" />
                  </IconButton>
                </Tooltip>
              </Box>
            </span>
          </Typography>

          <ReactApexChart
            options={options.options}
            series={options.series}
            type="bar"
            height={350}
          />
        </>
      )}
    </Card>
  );
};

export default TrafficCodes;
