import React, { useState } from "react";
import { UserProvider } from "./UserContext";
import {
  getFromStorage,
  removeFromStorage,
  addToStorage,
} from "../../utils/LocalStorage";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

export const User_Roles = Object.freeze({
  user: "user",
  admin: "admin",
  superAdmin: "superAdmin",
});

const UserContainer = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(getFromStorage());
  const [dataProgress, setDataProgress] = useState(0);
  const [isShowDataProgress, setIsShowDataProgress] = useState(false);

  const updateDataProgress = (progress: number) => {
    setIsShowDataProgress(true)
    setDataProgress(progress);
  };

  async function updateUser(updatedUser: any) {
    if (updatedUser) {
      addToStorage(updatedUser);
      setUser(updatedUser);
    }
  }
  const isAdmin = (): boolean =>
    [User_Roles.admin, User_Roles.superAdmin].includes(user?.user?.role);

  const isSuperAdmin = (): boolean =>
    User_Roles.superAdmin === user?.user?.role;

  const isAuthenticated = (): boolean => {
    const user = getFromStorage();
    const token = user ? user.token : "";
    if (token) {
      const decodedToken: any = jwt_decode(token);
      return !(Date.now() >= decodedToken.exp * 1000);
    }
    return false;
  };

  const removeUser = (): void => {
    removeFromStorage();
    setUser(null);
  };

  const logout = async () => {
    removeUser();
    navigate("/login");
  };

  const getUser = () => {
    return user?.user?.username;
  };

  return (
    <UserProvider
      value={{
        user,
        updateUser,
        logout,
        getUser,
        isAuthenticated,
        removeUser,
        isAdmin,
        isSuperAdmin,
        updateDataProgress,
        dataProgress,
        isShowDataProgress,
        setIsShowDataProgress
      }}
    >
      {children}
    </UserProvider>
  );
};

export default UserContainer;
