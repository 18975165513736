import { IconButton } from "@mui/material"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import axios from "axios"
import { ENDPOINT } from "../../config/config"
import { useContext, useState } from "react"
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext"
import { UserContextType } from "../../@types/UserContext"
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext"
import UserContext from "../../context/user/UserContext"
import PDFIcon from '../../assets/images/pdf.png';

const DownloadFileIcon: React.FC<{
  filerCodeAndEntryNo: string
  userId: string
}> = ({ filerCodeAndEntryNo, userId }) => {
  const userContext = useContext(UserContext) as UserContextType
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType
  const [errorLoading, setErrorLoading] = useState<{
    isLoading: boolean
    isError: boolean
    errorMsg?: string | null
  }>({ isError: false, isLoading: false, errorMsg: null })

  const downloadFile = async () => {
    try {
      setErrorLoading({ isLoading: true, isError: false })
      let query: string = ``
      if (userId) query += `&userId=${userId}`
      else query = `&userId=${userContext.user.userId}`

      query += `&entryNo=${filerCodeAndEntryNo}`

      const resp = await axios.get(`${ENDPOINT}/download/cbp?${query}`, {
        headers: {
          authorization: `Bearer ${userContext.user.token}`
        }
      })

      const res = resp?.data
      window.open(res.url, "_blank")
    } catch (err: any) {
      console.log(err)
      setErrorLoading({
        isLoading: false,
        isError: true,
        errorMsg: err?.response?.data?.error
      })
    }
  }

  return (
    <IconButton color="primary" onClick={downloadFile}>
      <img src={PDFIcon} alt='' width={35} />
    </IconButton>
  )
}

export default DownloadFileIcon
