import { useContext, useEffect, useState } from "react";
import CustomCard from "../../common/CustomCard";
import CbpTable from "./CbpTable";
import { scrollTop } from "../../utils/scrollTopHelper";
import UserContext from "../../context/user/UserContext";
import { UserContextType } from "../../@types/UserContext";
import axios from "axios";
import { ENDPOINT } from "../../config/config";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import { Box } from "@mui/material";

const DATA_PER_PAGE: number = 100;

const PdfUpload = () => {
  const userContext = useContext(UserContext) as UserContextType;
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;
  const [data, setData] = useState<{
    count: number;
    data: any;
    pageNo: number;
  }>({
    count: 0,
    data: null,
    pageNo: 0,
  });
  const [errorLoading, setErrorLoading] = useState<{
    isLoading: boolean;
    isError: boolean;
    errorMsg?: string | null;
  }>({ isError: false, isLoading: false, errorMsg: null });
  const [page, setPage] = useState(0);

  useEffect(() => {
    scrollTop();
    getData({});
  }, []);

  useEffect(() => {
    if (data?.data) setErrorLoading({ isLoading: false, isError: false });
  }, [data]);

  const getData = async ({
    offset = 0,
    userId,
    searchKeyword,
  }: {
    offset?: number;
    userId?: string;
    searchKeyword?: string;
  }) => {
    try {
      setErrorLoading({ isLoading: true, isError: false });
      let query: string = `pageSize=${DATA_PER_PAGE}&pageNo=${offset + 1}`;
      if (userId) query += `&userId=${userId}`;
      if (searchKeyword) query += `&searchKeyword=${searchKeyword}`;

      const resp = await axios.get(`${ENDPOINT}/cbps?${query}`, {
        headers: {
          authorization: `Bearer ${userContext.user.token}`,
        },
      });
      setPage(offset);
      const res = resp?.data;

      setData({ data: res?.data, pageNo: res?.page_no, count: res?.count });
    } catch (err: any) {
      console.log(err);
      setErrorLoading({
        isLoading: false,
        isError: true,
        errorMsg: err?.response?.data?.error,
      });
    }
  };

  useEffect(() => {
    if (errorLoading.isError) {
      updateSnackBar({
        show: true,
        variant: "error",
        message: errorLoading.errorMsg ?? "Error in fetching data",
      });
    }
  }, [errorLoading.isError]);

  return (
    <Box sx={{ pb: 2, mt: { md: 2, lg: 0 } }}>
      {data ? (
        <CbpTable
          isAdmin={userContext.isAdmin()}
          isSuperAdmin={userContext.isSuperAdmin()}
          data={data?.data}
          page={page}
          totalData={data?.count}
          dataPerPage={DATA_PER_PAGE}
          fetchData={getData}
          isLoading={errorLoading.isLoading}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};
export default PdfUpload;
