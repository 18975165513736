import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Theme as MuiTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { Logo } from "../../components";
import { NavItem } from "./nav-item";
import { LogoutItem } from "./logout-item";
import { Items } from "./items";
import SidebarExpandIcon from "../../assets/images/icons/sidebar.svg";
import SettingIcon from "../../assets/images/setting.svg";
import HelpIcon from "../../assets/images/help.svg";
import UserContext from "../../context/user/UserContext";
import { UserContextType } from "../../@types/UserContext";
import {
  GlobalMessageContextType,
  IKeyValue,
} from "../../@types/GlobalMessageContext";
import ExpandNavItem from "./expand-button";
import { MdMoreHoriz } from "react-icons/md";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";

interface ISidebar {
  open: boolean;
  onClose?: () => void;
  isPermanentSidebarOpen: boolean;
  onTogglePermanentSidebar: () => void;
}

export interface ISidebarItem {
  href: string;
  icon?: any;
  activeIcon?: any;
  title: string;
  isAdminOnly?: boolean;
  collapsed?: boolean;
  isExpand?: boolean;
  childs?: IKeyValue[];
}

export const Sidebar = (props: ISidebar): JSX.Element => {
  const userContext = useContext(UserContext) as UserContextType;

  const { open, onClose, isPermanentSidebarOpen, onTogglePermanentSidebar } =
    props;
  const router = useLocation();
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;

  const lgUp = useMediaQuery((theme: MuiTheme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  useEffect(() => {
    if (open) {
      onClose?.();
    }
  }, [router?.pathname]);

  const handleLogout = async (): Promise<void> => {
    try {
      userContext.logout();
    } catch (error) {
      updateSnackBar({
        show: true,
        variant: "error",
        message: "Error !! unable to logout ....",
      });
    }
  };

  const content = (
    <>
      <Box
        className="logged-in-sidebar-wrapper"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <div>
          <Box
            sx={{
              pt: 3,
              pb: 2.5,
              px: 2,
              display: "flex",
              alignItems: lgUp ? "flex-end" : "center",
              gap: 1,
            }}
          >
            <Link component={RouterLink} to="/">
              <Logo width="50px" />
            </Link>
            <Typography
              sx={{
                color: "white",
                fontSize: lgUp ? "1.6rem" : "1.5rem",
                fontWeight: "bold",
              }}
            >
              Tradedive
            </Typography>

            {lgUp ? (
              <Box mb={1} position={"absolute"} right={-25} top={22}>
                <IconButton
                  onClick={() => {
                    onClose?.();
                    onTogglePermanentSidebar();
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={SidebarExpandIcon} alt="" width="34px" />
                  </Box>
                </IconButton>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </div>
        <Divider sx={{ borderColor: "#2c3738" }} />
        <Box sx={{ py: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Typography
              sx={{
                color: "#8E9494",
                paddingLeft: "16px",
                fontSize: lgUp ? "14px" : "12px",
              }}
            >
              HOME
            </Typography>
            <MdMoreHoriz style={{ color: "#8E9494", paddingRight: "16px" }} />
          </Box>
          {Items.map((item: ISidebarItem, index): JSX.Element => {
            if (item.isAdminOnly && !userContext.isAdmin()) {
              return <></>;
            }
            return item.isExpand ? (
              <ExpandNavItem item={item} />
            ) : (
              <NavItem
                key={item.title}
                icon={item.icon}
                activeIcon={item.activeIcon}
                href={item.href}
                title={item.title}
              />
            );
          })}
        </Box>
        <Divider sx={{ borderColor: "#2c3738" }} />

        <Box sx={{ px: 2, pt: 2, pb: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Typography
              sx={{
                color: "#8E9494",
                paddingLeft: "16px",
                fontSize: lgUp ? "14px" : "12px",
              }}
            >
              OTHERS
            </Typography>
            <MdMoreHoriz style={{ color: "#8E9494", paddingRight: "16px" }} />
          </Box>
          <Box onClick={handleLogout}>
            <NavItem
              icon={SettingIcon}
              activeIcon={SettingIcon}
              href={""}
              title={"Settings"}
            />
          </Box>
          <NavItem
            icon={HelpIcon}
            activeIcon={HelpIcon}
            href={""}
            title={"Help Center"}
          />
          {/* <LogoutItem isSidebar /> */}
        </Box>
      </Box>
    </>
  );

  const contentCollapsed = (
    <>
      <Box
        className="logged-in-sidebar-wrapper"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box
            sx={{
              pt: 5,
              pb: 2.5,
              px: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // borderBottom: "2px solid #F5F5F5",
              position: "relative",
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: 32, right: -24 }}
              onClick={() => onTogglePermanentSidebar()}
            >
              <img src={SidebarExpandIcon} alt="" width={34} />
            </IconButton>
            <Link component={RouterLink} to="/">
              <Logo width="50px" />
            </Link>
          </Box>
        </div>
        <Box sx={{ flexGrow: 1, py: 3 }}>
          {Items.map((item: ISidebarItem): JSX.Element => {
            if (item.isAdminOnly && !userContext.isAdmin()) {
              return <></>;
            }
            return (
              <NavItem
                key={item.title}
                icon={item.icon}
                activeIcon={item.activeIcon}
                href={item.href}
                title={item.title}
                collapsed={!isPermanentSidebarOpen}
              />
            );
          })}
        </Box>
        <Box sx={{ px: 2, pt: 2, pb: 4 }}>
          <LogoutItem isSidebar collapsed={!isPermanentSidebarOpen} />
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            color: "#FFFFFF",
            width: isPermanentSidebarOpen ? 248 : 100,
            boxShadow: "0px 4px 19px 0px rgba(0, 0, 0, 0.13)",
            borderRight: "none",
            transition: "0.3s",
            backgroundColor: "#052223",
            overflow: "unset",
          },
        }}
        variant={"permanent"}
      >
        {isPermanentSidebarOpen ? content : contentCollapsed}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          color: "#FFFFFF",
          width: 240,
          backgroundColor: "#052223",
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

Sidebar.defaultProps = {
  onClose: undefined,
  open: false,
  openPermanentSidebar: true,
  onTogglePermanentSidebar: undefined,
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  openPermanentSidebar: PropTypes.bool,
  onTogglePermanentSidebar: PropTypes.func,
};
