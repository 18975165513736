import { Grid, Typography } from "@mui/material";
import AnalyticsFilter from "./AnalyticsFilter";
import AllImport from "./AllImport";
import AllDuties from "./AllDuties";
import Metrics from "./Metrics";
import DutySavings from "./DutySavings";  
import Vendors from "./Vendors";
import TariffCodes from "./TariffCodes";
import EnteredValue from "./EnteredValue";

const MainDashboard = () => {
  return (
    <>
      <Grid container spacing={2} alignItems={"center"} mb={3}>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight={500} color={"#000"} fontSize={"1.6rem"}>
            Analytics
          </Typography>
          <Typography color={"#84818A"}>
            Last Updated Yesterday 2:32 PM
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AnalyticsFilter />
        </Grid>
      </Grid>
      <Grid container spacing={4} mb={3.5}>
        <Grid item xs={12} sm={4}>
          <AllImport />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AllDuties />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Metrics />
        </Grid>
      </Grid>
      <Grid container spacing={4} mb={3.5}>
        <Grid item xs={12} sm={6}>
          <DutySavings />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Vendors />
        </Grid>
      </Grid>
      <Grid container spacing={4} mb={2}>
        <Grid item xs={12}>
          <TariffCodes />
        </Grid>
        <Grid item xs={12}>
          <EnteredValue />
        </Grid>
      </Grid>
    </>
  );
};

export default MainDashboard;
