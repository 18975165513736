import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import DownloadIcon from "../../assets/images/csv.png";
import { ENDPOINT } from "../../config/config";
import { UserContextType } from "../../@types/UserContext";
import UserContext from "../../context/user/UserContext";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import { Modal } from "../../components";

const ALLOWED_EXTENSION = ["xls", "xlsx", "xlsb"];

export enum Status {
  Success,
  Failed,
  Pending,
}

const styles = () => ({
  button: {
    marginLeft: 1,
  },

  openDialog: {
    padding: "24px 24px 12px",
  },
  overFlowVisible: {
    overflow: "visible",
  },
});
const Download = ({disabled}: {disabled?: boolean}) => {
  const classes = styles();
  const userContext = useContext(UserContext) as UserContextType;
  const [openDialog, setOpenDialog] = useState(false);
  const [pollId, setPollId] = useState("");
  const [msg, setMsg] = useState("Please Wait...");

  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;

  const toggleDialog = () => {
    startDownloading();
    setOpenDialog(!openDialog);
  };

  useEffect(() => {
    if (pollId) {
      const timerId = window.setInterval(getUrl, 3000);
      return () => {
        window.clearInterval(timerId);
      };
    }
  }, [pollId]);

  const updateDialogAndSnackBar = () => {
    setPollId("");
    setOpenDialog(false);
    updateSnackBar({
      show: true,
      variant: "error",
      message: "Error in downloading the file.",
    });
  };

  const getUrl = async () => {
    try {
      const res = await axios.get(`${ENDPOINT}/data/url?fileId=${pollId}`, {
        headers: {
          Authorization: `Bearer ${userContext.user.token}`,
          "Content-Type": "form-data",
        },
      });
      const urlData = res.data;
      setMsg(urlData?.msg ?? "");
      if (urlData.status === Status[Status.Success]) {
        setPollId("");
        setOpenDialog(false);
        window.open(urlData.url, "_blank");
      } else if (urlData.status === Status[Status.Failed])
        updateDialogAndSnackBar();
    } catch (err) {
      updateDialogAndSnackBar();
    }
  };

  const startDownloading = async () => {
    try {
      const res = await axios.get(`${ENDPOINT}/${"data/download"}`, {
        headers: {
          Authorization: `Bearer ${userContext.user.token}`,
          "Content-Type": "form-data",
        },
      });
      setPollId(res?.data);
    } catch (err) {
      updateSnackBar({
        show: true,
        variant: "error",
        message: "Something went wrong, Try again.",
      });
    }
  };

  return (
    <>
      <Tooltip title="Download File" disableFocusListener>
        <Button
          onClick={toggleDialog}
          color="primary"
          variant="outlined"
          size="medium"
          disabled={disabled}
          sx={{
            textTransform: "initial",
            borderRadius: "50px",
            p: 1.8,
            minWidth: { xs: "100%", sm: 200 },
            borderColor: "#0B494B",
          }}
          startIcon={<img src={DownloadIcon} width={16} alt="" />}
        >
          <Typography fontSize={"16px"} fontWeight={500} color={"primary"}>
            Download CSV
          </Typography>
        </Button>
      </Tooltip>
      <Modal
        open={openDialog}
        handleClose={() => {}}
        title="Download File"
        actions={<></>}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>{msg}</Typography>
        </Stack>
      </Modal>
    </>
  );
};

export default Download;
